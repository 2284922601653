import { isNullLiteral } from "@babel/types";
import React from "react";
import { useHistory } from "react-router-dom";

const OpenPoRenderer = (props) => {
  const history = useHistory();
  if (!props.value) return isNullLiteral;
  if (Array.isArray(props.value) && !props.value.length) return null;
  let val = props.value;
  if (!Array.isArray(props.value)) {
    val = [props.value];
  }

  const linkClicked = () => {
    localStorage.setItem(
      "landingRedirect",
      JSON.stringify({
        step: 1,
      })
    );
    history.push("/home");
  };

  return (
    <a className="link" href="#" onClick={linkClicked}>
      {val.join(", ")}
    </a>
  );
};

export default OpenPoRenderer;
