import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SplineChart = (props) => {
  const { data } = props;
  const options = {
    chart: {
      backgroundColor: "transparent",
      type: "line",
      animation: Highcharts.svg, // don't animate in old IE
      marginRight: 10,
      height: 100,
      width: 300,
    },
    credits: {
      enabled: false,
    },
    legend: { enabled: false },
    title: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      visible: false,
    },
    yAxis: {
      title: "",
      visible: false,
    },
    tooltip: {
      backgroundColor: null,
      borderWidth: 0,
      style: {
        padding: 0,
      },
      useHTML: true,
      formatter: function () {
        return (
          "<b>" +
          this.series.name +
          "</b><br/>" +
          Highcharts.dateFormat("%b-%d-%Y", this.x) +
          "<br/><b>" +
          Highcharts.numberFormat(this.y, 0) +
          "</b>"
        );
      },
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        color: "#3FA01C",
        name: "",
        data: data || [2, 3, 1, 3, 4, 6, 2, 4],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
export default SplineChart;
