import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import usersData from "data/usersData.json";
import logo from "assets/images/logo.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "@material-ui/lab";
import TextInput from "../UI/TextInput";
import { getToken } from "../../auth";
import { get } from "../../common/api";

const apiCalls = 0;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  tncContainer: {
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.common.white,
    padding: "3rem",
    borderRadius: "0.3rem",
    maxWidth: "25rem",
    width: "60%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  tcnBtn: {
    borderRadius: "5rem",
    minWidth: "15rem",
    fontWeight: "600",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const LoginComponent = (props) => {
  const brand = localStorage.getItem("brand");
  const history = useHistory();
  const classes = useStyles();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = useState("");
  const [showProgress, setShowProgress] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(0);
  //const today = new Date();
  const july12 = new Date("2021-07-12");
  const refDate = july12; //today > july12 ? july12 : today;
  const [selectedDate, setSelectedDate] = React.useState(refDate);
  localStorage.setItem("referenceDate", selectedDate);

  const handleDateChange = (date) => {
    localStorage.setItem("referenceDate", date);
    setSelectedDate(date);
  };

  const setUserDetails = (user) => {
    localStorage.setItem("email", user.email);
    localStorage.setItem("brand", user.brand);
    localStorage.setItem("role", user.role);
    localStorage.setItem("group", user.group || "Nike");
  };

  const onGetOrderData = (fileData, user) => {
    setDataFetched(dataFetched + 1);
    localStorage.setItem("ordersData", JSON.stringify(fileData));
  };

  const signIn = () => {
    setShowProgress(true);
    getToken(email, password)
      .then(async (resp) => {
        const token = await resp.json();
        localStorage.setItem("token", token);
        const user = usersData.users.filter((user) => user.email === email)[0];
        setUserDetails(user);
        get(user.ordersData)
          .then((res) => res.json())
          .then((res) => onGetOrderData(res, user))
          .then(() => {
            history.push("/");
          })
          .catch((err) => {
            console.log("Error Loggin In", err);
            alert("Failed to load Orders.");
            setShowProgress(false);
          });
      })
      .catch((err) => {
        setError("Invalid Email or Password");
        setShowProgress(false);
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box className={classes.tncContainer} boxShadow={5}>
        <div className={classes.paper}>
          <img src={logo} alt="Logo" className="logo" />
          <br />
          <br />
          <TextInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextInput
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(evt) => setPassword(evt.target.value)}
          />
          {error && <Alert severity="error">{error}</Alert>}
          <br />
          <Grid container>
            <Grid item md={6}>
              <Box m={1}>
                <Typography variant="caption">Reference Date</Typography>
              </Box>
            </Grid>
            <Grid item md={6}>
              <DatePicker
                className="MuiInputBase-input"
                selected={selectedDate}
                onChange={handleDateChange}
              />
            </Grid>
          </Grid>
          <br />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={`${classes.tcnBtn} full-color-button`}
            onClick={() => signIn()}
          >
            Sign In
          </Button>
          <br />
          {showProgress && <CircularProgress />}
        </div>
      </Box>
    </Container>
  );
};

export default LoginComponent;
