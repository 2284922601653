import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  Col,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { borders } from "@material-ui/system";
import Routes from "common/Routes";
import SelectComponent from "../UI/SelectComponent/index";
import logo from "assets/images/logo.png";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { formatCurrency, filterOrdersUpToTime } from "common/utils";
import moment from "moment";
import BOMTable from "../BOM";
import { billOfMaterials } from "../BOM/BOMColumns";
import BOMOptions from "../BOM/BOMOptions";
import { fetchAllResourcesSilent } from "../../data/fetchResources";

const getOrderTotal = (orders) => {
  if (!orders) {
    return {};
  }
  return orders.reduce((total, current) => total + current.spent, 0);
};

const getOnTimeOrders = (orders) => {
  let ordersClone = JSON.parse(JSON.stringify(orders));
  ordersClone = ordersClone.filter(
    (order) =>
      moment(order.confirmedDate).diff(moment(order.time), "days") >
      order.soDelayPeriod
  );
  return ordersClone;
};

export const getUniqueSuppliers = (orders) => {
  if (!orders.length) return [];
  const ordersClone = JSON.parse(JSON.stringify(orders));
  const suppliers = [...new Set(ordersClone.map((order) => order.supplier))];
  return suppliers;
};

const getOrderCompletness = (orders) => {
  if (!orders.length) return 0;
  return Number.parseFloat(
    orders.reduce((total, current) => total + current.filled, 0) / orders.length
  ).toFixed(1);
};

const LandingPageComponent = (props) => {
  const history = useHistory();

  const brand = localStorage.getItem("brand");
  const token = localStorage.getItem("token");
  if (!localStorage.getItem("selectedTimeFilter")) {
    localStorage.setItem("selectedTimeFilter", "month");
  }
  const [timePeriod, setTimePeriod] = useState(
    localStorage.getItem("selectedTimeFilter")
  );
  const allOrders = JSON.parse(window.localStorage.getItem("ordersData"));
  const reloaded = JSON.parse(localStorage.getItem("reloaded"));
  const [orders, setOrders] = React.useState(
    filterOrdersUpToTime(allOrders, timePeriod)
  );
  const [bomTableVisible, setBOMTableVisible] = useState(false);
  const [refreshTime, setRefreshTime] = useState(new Date());
  const [selectedBOM, setSelectedBOM] = useState(null);
  const [bomData, setBOMData] = useState([]);

  React.useEffect(() => {
    fetchAllResourcesSilent().then(() => {
      setRefreshTime(new Date());
    });
  }, []);

  React.useEffect(() => {
    if (!reloaded) {
      localStorage.setItem("reloaded", true);
      window.location.reload(false);
    }
  });

  React.useEffect(() => {
    setSelectedBOM(billOfMaterials[0].id);
    setBOMData(billOfMaterials[0]);
  }, [billOfMaterials]);

  const setRedirect = (step) => {
    localStorage.setItem(
      "landingRedirect",
      JSON.stringify({
        step,
      })
    );
    history.push("/home");
  };

  if (!token) {
    history.push("/terms");
    return <div></div>;
  }
  const onTimeFilterChange = (filter) => {
    setTimePeriod(filter);

    localStorage.setItem("selectedTimeFilter", filter);
    setOrders(filterOrdersUpToTime(allOrders, filter));
  };

  const getAI = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return -0.3;
        case "year":
          return -0.1;
        case "quarter":
          return 0.1;
        case "month":
          return 0.3;
        case "week":
          return 0.2;
      }
    }
    switch (timePeriod) {
      case "all":
        return -0.3;
      case "year":
        return -0.1;
      case "quarter":
        return 0.1;
      case "month":
        return 0.3;
      case "week":
        return 0.2;
    }
  };

  const getFacilities = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return 83;
        case "year":
          return 94;
        case "quarter":
          return 102;
        case "month":
          return 110;
        case "week":
          return 112;
      }
    }
    switch (timePeriod) {
      case "all":
        return 83;
      case "year":
        return 94;
      case "quarter":
        return 102;
      case "month":
        return 110;
      case "week":
        return 112;
    }
  };

  const getSiteEvents = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return 119;
        case "year":
          return 70;
        case "quarter":
          return 16;
        case "month":
          return 5;
        case "week":
          return 2;
      }
    }
    switch (timePeriod) {
      case "all":
        return 119;
      case "year":
        return 70;
      case "quarter":
        return 16;
      case "month":
        return 5;
      case "week":
        return 2;
    }
  };

  const getProductEvents = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return 247;
        case "year":
          return 133;
        case "quarter":
          return 42;
        case "month":
          return 19;
        case "week":
          return 8;
      }
    }
    switch (timePeriod) {
      case "all":
        return 247;
      case "year":
        return 133;
      case "quarter":
        return 42;
      case "month":
        return 19;
      case "week":
        return 8;
    }
  };
  const getInAcceptedIncentives = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return "$10,659,479";
        case "year":
          return "$8,387,424";
        case "quarter":
          return "$4,150,295";
        case "month":
          return "$0";
        case "week":
          return "$0";
      }
    }
    switch (timePeriod) {
      case "all":
        return " $1,265,921";
      case "year":
        return "$1,264,405";
      case "quarter":
        return "$1,208,248";
      case "month":
        return " $712,295";
      case "week":
        return "$238,991";
    }
  };

  const getApplicationRate = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return "10.6%";
        case "year":
          return "9.4%";
        case "quarter":
          return "11.2%";
        case "month":
          return "0.0%";
        case "week":
          return "0.0%";
      }
    }
    switch (timePeriod) {
      case "all":
        return "32.9%";
      case "year":
        return "39.2%";
      case "quarter":
        return "40.5%";
      case "month":
        return " 33.7%";
      case "week":
        return "21.8%";
    }
  };

  const getAcceptedIncentives = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return "178";
        case "year":
          return "134";
        case "quarter":
          return "54";
        case "month":
          return "0";
        case "week":
          return "0";
      }
    }
    switch (timePeriod) {
      case "all":
        return "64";
      case "year":
        return "62";
      case "quarter":
        return "58";
      case "month":
        return "18";
      case "week":
        return "9";
    }
  };

  const getEngaggementRate = () => {
    if (brand === "Nike") {
      switch (timePeriod) {
        case "all":
          return "37.9%";
        case "year":
          return "33.2%";
        case "quarter":
          return "38.3%";
        case "month":
          return "0.0%";
        case "week":
          return "0.0%";
      }
    }
    switch (timePeriod) {
      case "all":
        return "36.0%";
      case "year":
        return "29.8%";
      case "quarter":
        return "29.1%";
      case "month":
        return "17.4%";
      case "week":
        return "33.3%";
    }
  };

  const setBOMTableVisiblity = (visible) => {
    setBOMTableVisible(visible);
  };

  const onBOMTableChange = (selectedBOM) => {
    setSelectedBOM(selectedBOM);
    const selectedTable = billOfMaterials.find(
      (item) => item.id === selectedBOM
    );
    setBOMData(selectedTable);
  };

  return (
    <React.Fragment>
      <Box component="header" className="fixed" p={"2rem 2rem 0 2rem"}>
        <Container maxWidth={"lg"}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item sm={8}>
              <img src={logo} alt="Logo" className="logo" />
            </Grid>
            <Grid item sm={2} justify="flex-end" container direction="row">
              <BOMOptions
                className="w-100"
                handleChange={(event) => onBOMTableChange(event.target.value)}
                label="Bill of Material"
                value={selectedBOM}
                displayAll={false}
              />
              <br />
              <div>
                <a
                  className="link"
                  onClick={(evt) => {
                    evt.preventDefault();
                    setBOMTableVisible(true);
                  }}
                >
                  View BOM
                </a>
              </div>
            </Grid>
            <Grid item sm={2} justify="flex-end" container direction="row">
              <SelectComponent
                options={[
                  { value: "all", label: "All" },
                  { value: "year", label: "Year" },
                  { value: "quarter", label: "Quarter" },
                  { value: "month", label: "Month" },
                  { value: "week", label: "Week" },
                ]}
                handleChange={(event) => onTimeFilterChange(event.target.value)}
                selectLabel="Time Period"
                value={timePeriod}
              />
              <div style={{ width: "100%", height: "1rem" }}></div>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container className="fullscreen pt-100" maxWidth={"lg"}>
        <Box className="main-block">
          <Grid container direction="row" justify="center" alignItems="center">
            {/* <Grid item>
              <img src={logo} alt="Logo" className="logo" />
            </Grid> */}
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item sm={12}>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                className="main-grid"
                className="ml-100"
              >
                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(0)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {orders
                          ? `${formatCurrency(getOrderTotal(orders))}`
                          : "..."}
                      </Box>
                      <span className="ticker-small-text">
                        {orders ? `In ${orders.length} orders` : "..."}
                      </span>
                    </Box>
                  </a>
                  {/* <span className="ticker-percentage">
                    10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                </Grid>
                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(1)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {getOnTimeOrders(orders).length}
                      </Box>
                      <span className="ticker-small-text">On-time orders</span>
                      <br />
                      <span className="ticker-small-text">
                        with {getOrderCompletness(orders)}% aggregate
                        completeness
                      </span>
                    </Box>
                  </a>
                  {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                </Grid>
                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(4)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {getUniqueSuppliers(orders).length}
                      </Box>
                      <span className="ticker-small-text">
                        Suppliers with PO
                      </span>
                    </Box>
                    {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                  </a>
                </Grid>

                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(4)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {getFacilities()}
                      </Box>
                      <span className="ticker-small-text">Facilities</span>
                      <br />
                      {/* <span className="ticker-small-text">
                      form {getApplicationRate()} Suppliers
                    </span> */}
                    </Box>
                    {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                  </a>
                </Grid>
                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(2)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {getSiteEvents()}
                      </Box>
                      <span className="ticker-small-text">Site Events</span>
                      <br />
                      {/* <span className="ticker-small-text">
                      with {getEngaggementRate()} Engagement Rate
                    </span> */}
                    </Box>
                    {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                  </a>
                  <br />
                </Grid>

                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <a className="link" onClick={() => setRedirect(2)}>
                    <Box component="div">
                      <Box component="div" className="ticker-main-text">
                        {getProductEvents()}
                      </Box>
                      <span className="ticker-small-text">Product Events</span>
                    </Box>
                    {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                  </a>
                </Grid>

                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <Box component="div">
                    <Box component="div" className="ticker-main-text">
                      {getInAcceptedIncentives()}
                    </Box>
                    <span className="ticker-small-text">
                      In accepted incentives
                    </span>
                    <br />
                    <span className="ticker-small-text">
                      with {getApplicationRate()} Application Rate
                    </span>
                  </Box>
                  {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                </Grid>
                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <Box component="div">
                    <Box component="div" className="ticker-main-text">
                      {getAcceptedIncentives()}
                    </Box>
                    <span className="ticker-small-text">
                      Accepted incentives
                    </span>
                    <br />
                    <span className="ticker-small-text">
                      with {getEngaggementRate()} Engagement Rate
                    </span>
                  </Box>
                  {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                  <br />
                </Grid>

                <Grid
                  item
                  sm={4}
                  className="main-grid-item"
                  container
                  direction="row"
                  justify="left"
                >
                  <Box component="div">
                    <Box component="div" className="ticker-main-text">
                      {getAI()}
                    </Box>
                    <span className="ticker-small-text">Alignment Index</span>
                  </Box>
                  {/* <span className="ticker-percentage">
                    20% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
                  </span> */}
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item className="mt50" pt={3}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    borderRadius="50%"
                    onClick={() => history.push("/home")}
                    className={"explore full-color-button"}
                  >
                    Explore
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <BOMTable
          show={bomTableVisible}
          onHide={() => setBOMTableVisiblity(false)}
          title={bomData.name}
          data={bomData.data}
        />
      </Container>
    </React.Fragment>
  );
};
export default LandingPageComponent;
