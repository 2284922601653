import { aggregatePieData } from "./utils";
import supplierCities from "data/supplierCities";

const getColor = (val) => {
  const color =
    String(val).toUpperCase() === "H"
      ? "red"
      : String(val).toUpperCase() === "M"
      ? "yellow"
      : "green";
  return color;
};

export const flattenSupplierData = (data) => {
  return data.map((row) => {
    return {
      o: 1,
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      location: row.location ? row.location.value : "",
      state: row.location,
      color: getColor(row.RISK),
    };
  });
};

export const generateSupplierMap = (suppliers, filter) => {
  const { filtered, setFiltered } = filter;
  let data = getUniqueSuppliers(suppliers).map((row) => {
    row.location = supplierCities[row.supplierName];
    return row;
  });

  const allData = flattenSupplierData(data);

  const result = aggregatePieData(allData, "location", "z", "o");

  return [
    {
      type: "mapbubble",
      name: "",
      data: result,
      showInLegend: false,
      cursor: "pointer",
      point: {
        events: {
          click: function (event) {
            let currentLocData = data.find(
              (item) =>
                item.location &&
                item.location.value &&
                item.location.value === event.point.location
            );
            let currentLoc =
              currentLocData &&
              currentLocData.location &&
              currentLocData.location.value;

            if (filtered) {
              setFiltered({ filter: false, data: [], location: "" });
            } else {
              let filteredData = data.filter(
                (item) =>
                  item.location &&
                  item.location.value &&
                  item.location.value === event.point.location
              );
              setFiltered({
                filter: true,
                data: filteredData,
                location: currentLoc,
              });
            }
          },
        },
      },
    },
  ];
};

export const getUniqueSuppliers = (allSuppliers) => {
  const uniqueSuppliers = [];
  if (!allSuppliers) return uniqueSuppliers;
  const addedSuppiers = {};
  for (let supplier of allSuppliers) {
    if (!addedSuppiers[supplier.supplierName]) {
      uniqueSuppliers.push(supplier);
      addedSuppiers[supplier.supplierName] = true;
    }
  }

  return uniqueSuppliers;
};
