import LandingPageComponent from "components/LandingPageComponent/index";
import MainPageComponent from "components/MainPageComponent/index";
import DataGenerator from "components/DataGenerator/index";
import LoginComponent from "components/LoginComponent/index";
import TermsComponent from "components/TermsComponent/index";
import GenerateProvonomics from "components/DataGenerator/provonomics";

const Routes = {
  LANDING: { path: "/", protected: false, component: LandingPageComponent },
  MAIN: { path: "/home", protected: false, component: MainPageComponent },
  DATA: { path: "/data", protected: false, component: DataGenerator },
  LOGIN: { path: "/login", protected: false, component: LoginComponent },
  TERMS: { path: "/terms", protected: false, component: TermsComponent },
  PROVONOMICS: {
    path: "/data/provonomics",
    component: GenerateProvonomics,
  },
};

export default Routes;
