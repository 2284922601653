import React from "react";
export const PieChart = (props) => {
  const { percentComplete } = props;
  const percentIncomplete = 100 - percentComplete;
  return (
    <div
      className="pie"
      style={{
        backgroundImage: `conic-gradient(#0097D3 0% ${percentComplete}%, #ECECEC 0% ${percentIncomplete}%)`,
      }}
    ></div>
  );
};
