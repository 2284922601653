import React, { useState } from "react";
import { DataGrid } from "@material-ui/data-grid";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { BOMColumns } from "./BOMColumns";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const BomTable = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const handleClose = () => {
    props.onHide();
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.show}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        disableSelectionOnClick={true}
      >
        <DialogTitle id="responsive-dialog-title">
          <div className={classes.header}>
            <Typography variant="h6">{props.title}</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <DataGrid
            rows={props.data || []}
            columns={BOMColumns}
            pageSize={100}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BomTable;
