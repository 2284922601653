import moment from "moment";
import { aggregateData, getFilteredData } from "dataTransformers/utils";
const referenceDate = localStorage.getItem("referenceDate");
export const activityLabelMapping = {
  views: "Views",
  scans: "Scans",
  queryText: "Queries: Text",
  queryVoice: "Queries: Voice",
  purchase: "Purchases",
};

export const activityUserLabelMapping = {
  views: "Viewers",
  scans: "Scanners",
  queryText: "Queries: Text",
  queryVoice: "Queries: Voice",
  purchase: "Purchasers",
};

export const filterDataByTime = (activities, timeRange) => {
  return getFilteredData(activities, timeRange);
};

export const filterActivities = (activities, value) => {
  if (value === "brand") {
    activities = activities.filter(
      (activity) => activity.brand !== "pcn" && activity.brand !== "category"
    );
  }
  if (value === "category") {
    activities = activities.filter((activity) => activity.brand !== "pcn");
  }
  return activities;
};

export const getTotalActivities = (activities, value) => {
  let activitiesClone = JSON.parse(JSON.stringify(activities));
  if (value.length === 3 || value.includes("pcn")) {
    return activitiesClone;
  }
  if (value.length === 2 && !value.includes("pcn")) {
    activitiesClone = activitiesClone.filter(
      (activity) => activity.brand !== "pcn"
    );
    return activitiesClone;
  }
  if (value.length === 1) {
    activitiesClone = activitiesClone.filter(
      (activity) => activity.brand !== "pcn" && activity.brand !== "category"
    );
    return activitiesClone;
  }
};

export const getTotaFilteredData = (activities, value, type) => {
  let activitiesClone = JSON.parse(JSON.stringify(activities));
  activitiesClone = getTotalActivities(activitiesClone, value);
  return activitiesClone.filter((activity) => activity.type === type).length;
};

export const getAggregatedAttributes = (activities) => {
  const allAttributes = [];
  activities.forEach((activity) => {
    const attrArr = activity.value.attributes.map((attr) => {
      attr.count = 1;
      return attr;
    });
    allAttributes.push(...attrArr);
  });
  const aggregatedAttributes = aggregateData(allAttributes, "name", "count");
  let aggregatedAttributesWithViewedandFavourated = [];
  aggregatedAttributes.forEach((row) => {
    row.viewedCount = allAttributes.filter(
      (attr) => attr.name === row.name && attr.viewed
    ).length;
    row.favouratedCount = allAttributes.filter(
      (attr) => attr.name === row.name && attr.favourated
    ).length;
    aggregatedAttributesWithViewedandFavourated.push(row);
  });
  return aggregatedAttributes;
};

export const getPinData = (activities) => {
  const activitiesClone = JSON.parse(JSON.stringify(activities));
  const planetActivities = activitiesClone.filter(
    (activity) => activity.value.value === "planet"
  );
  const peopleActivities = activitiesClone.filter(
    (activity) => activity.value.value === "people"
  );
  const privacyActivities = activitiesClone.filter(
    (activity) => activity.value.value === "privacy"
  );
  const pathActivities = activitiesClone.filter(
    (activity) => activity.value.value === "path"
  );
  const productActivities = activitiesClone.filter(
    (activity) => activity.value.value === "product"
  );
  const placeActivities = activitiesClone.filter(
    (activity) => activity.value.value === "place"
  );
  return {
    planet: {
      total: planetActivities.length,
      brandActivities: filterActivities(planetActivities, "brand").length,
      categoryActivities: filterActivities(planetActivities, "category").length,
      attributes: getAggregatedAttributes(
        filterActivities(planetActivities, "brand")
      ),
    },
    people: {
      total: peopleActivities.length,
      brandActivities: filterActivities(peopleActivities, "brand").length,
      categoryActivities: filterActivities(peopleActivities, "category").length,
      attributes: getAggregatedAttributes(
        filterActivities(peopleActivities, "brand")
      ),
    },
    privacy: {
      total: privacyActivities.length,
      brandActivities: filterActivities(privacyActivities, "brand").length,
      categoryActivities: filterActivities(privacyActivities, "category")
        .length,
      attributes: getAggregatedAttributes(
        filterActivities(privacyActivities, "brand")
      ),
    },
    path: {
      total: pathActivities.length,
      brandActivities: filterActivities(pathActivities, "brand").length,
      categoryActivities: filterActivities(pathActivities, "category").length,
      attributes: getAggregatedAttributes(
        filterActivities(pathActivities, "brand")
      ),
    },
    product: {
      total: productActivities.length,
      brandActivities: filterActivities(productActivities, "brand").length,
      categoryActivities: filterActivities(productActivities, "category")
        .length,
      attributes: getAggregatedAttributes(
        filterActivities(productActivities, "brand")
      ),
    },
    place: {
      total: placeActivities.length,
      brandActivities: filterActivities(placeActivities, "brand").length,
      categoryActivities: filterActivities(placeActivities, "category").length,
      attributes: getAggregatedAttributes(
        filterActivities(placeActivities, "brand")
      ),
    },
  };
};
