import React from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Container,
  TextField,
  Grid,
  Menu,
  MenuItem,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import logo from "assets/images/logo.png";

const Header = (props) => {
  const history = useHistory();
  const { brand, category, selectedMainFilters, handleMainFilterChange } =
    props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const logout = () => {
    localStorage.clear();
    history.push("/terms");
  };
  return (
    <Box component="header" pb={"1rem"}>
      <Container maxWidth={"lg"}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item style={{ width: "10rem", padding: "0" }}>
            <Box component="div" className="logo-wrap">
              <a className="link" onClick={() => history.push("/")}>
                <img src={logo} alt="Logo" className="logo" />
              </a>
            </Box>
          </Grid>
          <Grid item style={{ width: "calc(100% - 10rem)", padding: "0" }}>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Grid item>
                <form noValidate autoComplete="on" bgColor="#fff">
                  <TextField
                    id="search"
                    className="search"
                    size="small"
                    label="Search for supplier/address/item"
                    variant="outlined"
                  />
                </form>
              </Grid>

              <Grid item justify="flex-end" direction="row">
                <SearchIcon
                  style={{
                    fontSize: "1.5rem",
                    marginRight: "1rem",
                    color: "#666666",
                    marginTop: "0.5rem",
                  }}
                />
                <NotificationsNoneIcon
                  style={{
                    fontSize: "1.5rem",
                    marginRight: "1rem",
                    color: "#666666",
                    marginTop: "0.5rem",
                  }}
                />
                <AccountCircleOutlinedIcon
                  style={{
                    fontSize: "1.5rem",
                    marginRight: "1rem",
                    color: "#666666",
                    marginTop: "0.5rem",
                  }}
                  onClick={handleClick}
                />
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={() => logout()}>Logout</MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Header;
