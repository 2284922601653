import React, { useState } from "react";
import { Box, makeStyles, Typography, Link, Grid } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import ErrorIcon from "@material-ui/icons/Error";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import Swiper from "react-id-swiper";
import "swiper/css/swiper.css";
import CustomTable from "../UI/Table/table";
import { generateSplineData } from "dataTransformers/splineData";
import moment from "moment";
import { getStartDate } from "../../dataTransformers/utils";
const referenceDate = localStorage.getItem("referenceDate");

const drawerWidth = "100%";
const drawerMinWidth = 950;
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 112,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "1.5rem 0.5rem ",
    borderRadius: "0.5rem",
    border: "0.08rem solid transparent",
    transition: "width 0.5s",

    "& .link": {
      color: theme.palette.primary.blue,
      position: "absolute",
      bottom: "0rem",
      opacity: "0",
      fontSize: "0.75rem",
      transition: "all 0.5s",
    },
    "&:hover": {
      borderColor: theme.palette.primary.main,
      "& .link": {
        opacity: "1",
        bottom: "-1.5rem",
      },
    },
  },
  title: {
    fontSize: "0.9rem",
    color: theme.palette.secondary.dark,
    fontWeight: "600",
    textAlign: "center",
    paddingBottom: "0.3rem",
  },
  subtitle: {
    fontSize: "0.75rem",
    color: theme.palette.secondary.main,
    textAlign: "center",
  },
  note: {
    color: "#999999",
    fontSize: "0.5rem",
    position: "absolute",
    bottom: "0.8rem",
  },
  alertIcon: {
    color: theme.palette.primary.yellow,
    background: "#000",
    borderRadius: "50%",
    fontSize: "1.2rem",
    opacity: "0.9",
    position: "absolute",
    top: "0.3rem",
    right: "0.3rem",
  },
  swiper: {
    transition: "width 0.3s",
    "&.selected .MuiBox-root": {
      borderColor: theme.palette.primary.main,
    },
  },

  multiple: {
    "& .selected:first-child .MuiBox-root": {
      borderColor: theme.palette.secondary.dark,
    },
    "& .selected:nth-child(2) .MuiBox-root": {
      borderColor: theme.palette.primary.green,
    },
    "& .selected:nth-child(3) .MuiBox-root": {
      borderColor: theme.palette.primary.red,
    },
    "& .selected:nth-child(4) .MuiBox-root": {
      borderColor: theme.palette.primary.yellow,
    },
  },
  overrelay: {
    background: "rgba(0, 0, 0, 0.36)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
  },
  tableDrawer: {
    width: "0",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "0 0 1.5rem #ccc",
    position: "fixed",
    height: "100vh",
    overflow: "auto",
    right: "0",
    top: "0",
    zIndex: "9999",
    transition: "all 0.5s",

    "&.open": {
      width: drawerWidth,
    },
  },
  tableDrawerContainer: {
    width: drawerMinWidth,
    background: "#fff",
    marginLeft: "auto",
    height: "100%",
    padding: "2rem 3rem",
    position: "relative",
    "&.fullscreen": {
      width: "100%",
    },
  },
  closeTableDrawer: {
    position: "absolute",
    left: "1rem",
    top: "2.17rem",
  },
  tableDrawerTitle: {
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  tableWrapper: {
    // maxHeight: "calc(100vh - 8rem)",
    // overflow: "auto",
    marginTop: "2rem",
  },
}));

const CardSwiper = (props) => {
  const {
    data,
    slidesPerView,
    multiple,
    columns,
    expandedTableColumns,
    handleCardClick,
    sideBarHeader,
    timeFilter,
  } = props;
  const classes = useStyles();
  const [fullscreen, setfullscreen] = React.useState(false);
  const [swiper, setSwiper] = React.useState({
    open: false,
    rows: [],
    header: "",
  });
  const dataClone = JSON.parse(JSON.stringify(data));
  const [filter, setFilter] = useState(timeFilter);

  const handleDrawerToggle = (r, h) => {
    if (swiper.open) {
      setSwiper({ open: false, rows: r ? r : [], header: h ? h : "" });
      setfullscreen(false);
    } else {
      setSwiper({ open: true, rows: r ? r : [], header: h ? h : "" });
    }
  };

  const handleDrawerAspectRatio = () => {
    setfullscreen(!fullscreen);
  };

  const params = {
    slidesPerView: slidesPerView,
    rebuildOnUpdate: true,
    shouldSwiperUpdate: true,
    wrapperClass: "swiper-wrapper",
    spaceBetween: 0,
    freeMode: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  React.useEffect(() => {
    setFilter(timeFilter);
  }, [timeFilter]);
  const localStorageOrders = JSON.parse(localStorage.getItem("ordersData"));

  const handleCardClickLocal = (e, title) => {
    handleCardClick(title);
    let t = e.target;
    if (e.target.parentNode.classList.contains("swip")) {
      t = e.target.parentNode;
    }
    if (e.target.parentNode.parentNode.classList.contains("swip")) {
      t = e.target.parentNode.parentNode;
    }
    if (multiple) {
      t.classList.contains("selected")
        ? t.classList.remove("selected")
        : t.classList.add("selected");
    } else {
      Array.prototype.filter.call(t.parentNode.children, function (sibling) {
        if (sibling !== t) {
          sibling.classList.remove("selected");
        }
      });
      t.classList.contains("selected")
        ? t.classList.remove("selected")
        : t.classList.add("selected");
    }
  };
  return (
    <Box className={multiple ? classes.multiple : null}>
      <Swiper {...params}>
        {dataClone.map((dat, i) => (
          <Box
            p={"0.5rem"}
            className={"swip " + classes.swiper + (i === 0 ? " selected " : "")}
            onClick={(e) => handleCardClickLocal(e, dat.subtitle)}
            key={`${dat.title}-${dat.subtitle}`}
          >
            <Box
              className={classes.root}
              disable={dat.disable ? "true" : "false"}
              bgcolor="white"
              boxShadow={5}
            >
              {dat.alertIcon && <ErrorIcon className={classes.alertIcon} />}
              <Typography variant="h2" className={classes.title}>
                {dat.title}
              </Typography>
              {dat.subtitle && (
                <Typography className={classes.subtitle}>
                  {dat.subtitle}
                </Typography>
              )}
              {dat.note && (
                <Typography className={classes.note}>{dat.note}</Typography>
              )}
              {dat.rows && dat.rows.length > 0 && (
                <Link
                  href="#"
                  className="link"
                  onClick={() => handleDrawerToggle(dat.rows, dat.header)}
                >
                  More Details
                  <ArrowForwardIosIcon style={{ fontSize: "0.55rem" }} />
                </Link>
              )}
            </Box>
          </Box>
        ))}
      </Swiper>
      {swiper.open && (
        <Box
          className={
            swiper.open
              ? "open " + classes.tableDrawer
              : "close " + classes.tableDrawer
          }
        >
          <Box
            className={classes.overrelay}
            onClick={() => handleDrawerToggle()}
          ></Box>
          <Box
            className={
              fullscreen
                ? "fullscreen " + classes.tableDrawerContainer
                : classes.tableDrawerContainer
            }
          >
            <CloseIcon
              fontSize="small"
              className={classes.closeTableDrawer}
              onClick={() => handleDrawerToggle()}
            />
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h2" className={classes.tableDrawerTitle}>
                  Deep Dive / {sideBarHeader}
                </Typography>
              </Grid>
              <Grid item>
                <AspectRatioIcon
                  fontSize="small"
                  className={classes.aspectRatioIcon}
                  onClick={handleDrawerAspectRatio}
                />
              </Grid>
            </Grid>
            <Box className={classes.tableWrapper}>
              <CustomTable
                sDate={filter === "all" ? undefined : getStartDate(filter)}
                eDate={
                  filter === "all"
                    ? undefined
                    : moment(referenceDate).format("YYYY-MM-DD")
                }
                rows={swiper.rows}
                columns={columns ? columns : []}
                title={swiper.header}
                expandedTableColumns={expandedTableColumns}
                // showSpline
                splineData={generateSplineData(localStorageOrders)}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default CardSwiper;
