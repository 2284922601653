import React from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";
import { PieChart } from "components/MainPageComponent/Charts/PieChart";
import CircleChart from "components/MainPageComponent/Charts/CircleChart";
import Button from "@material-ui/core/Button";
import moment from "moment";

const updateTimes = [
  "1h",
  "2h",
  "3h",
  "4h",
  "5h",
  "6h",
  "8h",
  "12h",
  "24h",
  "2d",
  "3d",
  "5d",
  "1w",
  "2w",
  "3w",
  "1m",
];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#fff",
    padding: "1rem 0",
    borderBottom: "1px solid #E6E6E6",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:first-child": {
      paddingTop: "0",
    },
    "& .cardLeft": {
      width: "2rem",
    },
    "& .details": {
      width: "calc(100% - 12rem)",
    },
    "& .cardRight": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      whiteSpace: "normal",
      wordBreak: "break-all",
      "& .cardStats": {
        width: "12rem",
      },
    },
    "& .green": {
      color: theme.palette.primary.green,
    },
    "& .red": {
      color: "#dd0101",
    },
    "& .yellow": {
      color: theme.palette.primary.yellow,
    },
  },
  heading: {
    color: theme.palette.secondary.dark,
    paddingBottom: "0.5rem",
    fontSize: "0.875rem",
  },
  subheading: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
  },
  stChart: {
    width: "4rem",
    paddingTop: "0.5rem !important",
    textAlign: "center",
  },
  stdetails: {
    width: "calc(100% - 4rem)",
  },
  stHeading: {
    fontSize: "1.5rem",
    fontWeight: "700",
  },
  stStatus: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
  },
  stUpdated: {
    color: "#999999",
    fontSize: "0.75rem",
  },
}));

const OrderStatusCard = (props) => {
  const {
    icon,
    supplier,
    po,
    amount,
    orderUpdates,
    requestedDate,
    deliveryStatus,
    actualdate,
    deliveryDate,
    color,
    filled,
    setRealtimeDialog,
    totalRequirements,
    fulfilledRequirements,
  } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box align="center" className="cardLeft">
        {icon}
      </Box>
      <Box className="cardRight">
        <Box className="details">
          {supplier && <Box className={classes.heading}>{supplier}</Box>}
          <Box className={classes.subheading}>
            PO # : {po} <br />
            Amount : {amount} <br />
            {/* Order Updates : {orderUpdates} <br /> */}
            Actual Date : {moment(actualdate).format("Do MMMM YYYY")} <br />
            Requested Date : {moment(requestedDate).format("Do MMMM YYYY")}{" "}
            <br />
            Delivery Status :{" "}
            <a
              onClick={() =>
                deliveryStatus === "In-Transit" ? setRealtimeDialog(true) : {}
              }
            >
              <span className={color}>{deliveryStatus}</span>
            </a>
          </Box>
        </Box>
        <Box className="cardStats">
          <Grid container spacing={1}>
            <Grid item spacing={3} className={classes.stChart}>
              <PieChart percentComplete={filled} />
              <CircleChart
                total={totalRequirements > 9 ? totalRequirements : 9}
                filled={fulfilledRequirements}
              />
            </Grid>
            <Grid item spacing={3} className={classes.stdetails}>
              <Typography className={classes.stHeading}>{filled}%</Typography>
              <Typography className={classes.stStatus}>Fill Status</Typography>
              <Typography className={classes.stUpdated}>
                Last update :{" "}
                {updateTimes[Math.floor(Math.random() * updateTimes.length)]}{" "}
                ago
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default OrderStatusCard;
