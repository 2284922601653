import {
  aggregatePieData,
  flattenData,
  flattenOrderStatusData,
  getFilteredData,
} from "./utils";
import supplierCities from "data/supplierCities";
import moment from "moment";

export const generateOrderStatus = (orders, filters) => {
  const { timeFilter, setFiltered, filtered } = filters;
  let data = getFilteredData(orders, timeFilter);
  data = data.map((row) => {
    row.location = supplierCities[row.supplier];
    return row;
  });

  data = flattenOrderStatusData(data);

  const result = aggregatePieData(data, "location", "z", "o");

  return [
    {
      type: "mapbubble",
      name: "",
      data: result,
      showInLegend: false,
      cursor: "pointer",
      point: {
        events: {
          click: function (event) {
            let currentLoc = orders.find(
              (item) =>
                item.location &&
                item.location.value &&
                item.location.value === event.point.location
            ).location.value;

            if (filtered) {
              setFiltered({ filter: false, data: [], location: "" });
            } else {
              let filteredData = orders.filter(
                (item) =>
                  item.location &&
                  item.location.value &&
                  item.location.value === event.point.location
              );
              setFiltered({
                filter: true,
                data: filteredData,
                location: currentLoc,
              });
            }
          },
        },
      },
    },
  ];
};
