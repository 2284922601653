import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import Select from "react-select";
import options from "data/cities-us.json";
import Button from "@material-ui/core/Button";
import {
  randomizer,
  createDateArray,
  objectToProbabilityArray,
  createAgeArray,
  pickRandomValue,
  pickRandomDateBetween,
  pickRandomnNumber,
  pickRandomFloat,
  objectToValueProbabilityArray,
} from "./utils";
import csv from "csv";

const downloadJsonFile = () => {
  const element = document.createElement("a");
  const file = new Blob([localStorage.getItem("pools")], {
    type: "text/plain;charset=utf-8",
  });
  element.href = URL.createObjectURL(file);
  element.download = "provonomicsData.json";
  document.body.appendChild(element);
  element.click();
};

const GenerateProvonomicsData = (filters) => {
  const {
    locations,
    orderPercentage,
    IPStatus,
    AI,
    IPAI,
    siteRequirementTarget,
    siteRequirementActual,
    siteLevelValue,
    siteLevelAward,
    productRequirementTarget,
    productRequirementActual,
    productLevelValue,
    productLevelAward,
    poolLevelValue,
    poolLevelAward,
    totalPools,
    brand,
    events,
    productEvents,
    requirements,
    productRequirements,
    weeksData,
    supplier,
    SIValidity,
    SIStatus,
    productStatus,
  } = filters;

  let pools = [];

  for (let i = 0; i < totalPools; i++) {
    const details = [];
    Object.keys(requirements.requirements).map((value, index) => {
      const siteLevelActual = pickRandomnNumber(
        siteRequirementActual.min,
        siteRequirementActual.max
      );
      const siteLevelTarget = pickRandomnNumber(
        siteRequirementTarget.min,
        siteRequirementTarget.max
      );
      const siteLevelValueCurrent = pickRandomnNumber(
        siteLevelValue.min,
        siteLevelValue.max
      );

      const productLevelTarget = pickRandomnNumber(
        productRequirementTarget.min,
        productRequirementTarget.max
      );
      const productLevelActual = pickRandomnNumber(
        productRequirementActual.min,
        productRequirementActual.max
      );
      const productLevelValueCurrent = pickRandomnNumber(
        productLevelValue.min,
        productLevelValue.max
      );
      const SIValidityCurrent = randomizer(
        objectToValueProbabilityArray(SIValidity)
      ).value;
      const productStatusCurrent = randomizer(
        objectToValueProbabilityArray(productStatus)
      ).value;
      const SIStatusCurrent = randomizer(
        objectToValueProbabilityArray(SIStatus)
      ).value;

      details.push({
        siteRequirement: requirements.requirements[value],
        productRequirements: productRequirements.requirements[value],
        siteRequirementTarget: siteLevelTarget,
        siteEvent: randomizer(events.events),
        productEvent: randomizer(productEvents.events),
        siteRequirementActual: siteLevelActual,
        siteLevelValue: siteLevelValueCurrent,
        siteLevelAward:
          siteLevelActual >= siteLevelTarget &&
          SIValidityCurrent === "Valid" &&
          SIStatusCurrent === "Pass"
            ? siteLevelValueCurrent
            : 0,
        productRequirementTarget: productLevelTarget,
        productRequirementActual: productLevelActual,
        productLevelValue: productLevelValueCurrent,
        productLevelAward:
          productLevelActual >= productLevelValueCurrent &&
          productStatusCurrent === "Pass"
            ? productLevelValueCurrent
            : 0,
        poolLevelValue: pickRandomnNumber(
          poolLevelValue.min,
          poolLevelValue.max
        ),
        poolLevelAward: pickRandomnNumber(
          poolLevelAward.min,
          poolLevelAward.max
        ),
      });
    });
    pools.push({
      supplier,
      time: randomizer(createDateArray(weeksData)).date,
      location: randomizer(locations),
      poNumber: Number.parseInt(Math.random() * 10000),
      alignmentIndex: pickRandomFloat(AI.min, AI.max),
      IPAlignmentIndex: pickRandomFloat(IPAI.min, IPAI.max),
      IPStatus: pickRandomValue(IPStatus),
      details,
    });
  }
  const oldPools = JSON.parse(localStorage.getItem("pools")) || [];
  oldPools.push(...pools);
  alert("Data Generated, Please export data");
  localStorage.setItem("pools", JSON.stringify(oldPools));
};

const GenerateProvonomics = (props) => {
  const [locations, setLocations] = useState([]);
  const [orderPercentage, setOrderPercentage] = useState({
    confirmed: 0,
    submitted: 0,
    unconfirmed: 0,
    warning: 0,
  });
  const [IPStatus, setIPStatus] = useState({
    Accepted: 0,
    Rejected: 0,
    CLOSED: 0,
  });
  const [SIStatus, setSIStatus] = useState({
    Pass: 0,
    Fail: 0,
  });

  const [SIValidity, setSIValidity] = useState({
    Valid: 0,
    Warning: 0,
    Expired: 0,
  });

  const [productStatus, setProductStatus] = useState({
    Pass: 0,
    Fail: 0,
  });

  const [AI, setAI] = useState({
    min: 0,
    max: 0,
  });
  const [IPAI, setIPAI] = useState({
    min: 0,
    max: 0,
  });

  const [siteRequirementTarget, setSiteRequirementTarget] = useState({
    min: 0,
    max: 0,
  });
  const [siteRequirementActual, setSiteRequirementActual] = useState({
    min: 0,
    max: 0,
  });

  const [siteLevelValue, setSiteLevelValue] = useState({
    min: 0,
    max: 0,
  });
  const [siteLevelAward, setSiteLevelAward] = useState({
    min: 0,
    max: 0,
  });

  const [productRequirementTarget, setProductRequirementTarget] = useState({
    min: 0,
    max: 0,
  });
  const [productRequirementActual, setProductRequirementActual] = useState({
    min: 0,
    max: 0,
  });

  const [productLevelValue, setProductLevelValue] = useState({
    min: 0,
    max: 0,
  });
  const [productLevelAward, setProductLevelAward] = useState({
    min: 0,
    max: 0,
  });

  const [poolLevelValue, setPoolLevelValue] = useState({
    min: 0,
    max: 0,
  });
  const [poolLevelAward, setPoolLevelAward] = useState({
    min: 0,
    max: 0,
  });

  const [supplier, setSupplier] = useState("");
  const [totalPools, setTotalPools] = useState(0);
  const [brand, setBrand] = useState("");
  const [weeks, setWeeks] = useState(0);
  const [weeksData, setWeeksData] = useState({});
  const [events, setEvents] = useState({
    total: 0,
    events: {},
  });

  const [productEvents, setProductEvents] = useState({
    total: 0,
    events: {},
  });

  const [requirements, setRequirements] = useState({
    total: 0,
    requirements: {},
  });
  const [productRequirements, setProductRequirements] = useState({
    total: 0,
    requirements: {},
  });

  const handleRequirementsChange = (name, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(requirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].name = name)
      : (requirementsClone.requirements[index] = { name });
    setRequirements(requirementsClone);
  };

  const handleRequirementsPinChange = (pin, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(requirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].pin = pin)
      : (requirementsClone.requirements[index] = { pin });
    setRequirements(requirementsClone);
  };

  const handleProductRequirementsChange = (name, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(productRequirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].name = name)
      : (requirementsClone.requirements[index] = { name });
    setProductRequirements(requirementsClone);
  };

  const handleEventsChange = (name, index) => {
    const eventsClone = JSON.parse(JSON.stringify(events));
    eventsClone.events[index]
      ? (eventsClone.events[index].name = name)
      : (eventsClone.events[index] = { name });
    setEvents(eventsClone);
  };

  const handleEventsProbabilityChange = (probability, index) => {
    const eventsClone = JSON.parse(JSON.stringify(events));
    eventsClone.events[index]
      ? (eventsClone.events[index].probability = probability)
      : (eventsClone.events[index] = { probability });
    setEvents(eventsClone);
  };

  const handleEventsTypeChange = (type, index) => {
    const eventsClone = JSON.parse(JSON.stringify(events));
    eventsClone.events[index]
      ? (eventsClone.events[index].type = type)
      : (eventsClone.events[index] = { type });
    setEvents(eventsClone);
  };

  const handleProductEventsChange = (name, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].name = name)
      : (eventsClone.events[index] = { name });
    setProductEvents(eventsClone);
  };

  const handleProductEventsProbabilityChange = (probability, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].probability = probability)
      : (eventsClone.events[index] = { probability });
    setProductEvents(eventsClone);
  };

  const handleProductEventsTypeChange = (type, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].type = type)
      : (eventsClone.events[index] = { type });
    setProductEvents(eventsClone);
  };

  const handleIPStatusChange = (status, probability) => {
    const IPStautsClone = JSON.parse(JSON.stringify(IPStatus));
    IPStautsClone[status] = probability;
    setIPStatus(IPStautsClone);
  };

  const handleSIStatusChange = (status, probability) => {
    const SIStautsClone = JSON.parse(JSON.stringify(SIStatus));
    SIStautsClone[status] = probability;
    setSIStatus(SIStautsClone);
  };

  const handleProductStatusChange = (status, probability) => {
    const productStautsClone = JSON.parse(JSON.stringify(productStatus));
    productStautsClone[status] = probability;
    setProductStatus(productStautsClone);
  };

  const handleSIValidityChange = (status, probability) => {
    const SIValidityClone = JSON.parse(JSON.stringify(SIValidity));
    SIValidityClone[status] = probability;
    setSIValidity(SIValidityClone);
  };

  const handleAIChange = (range, probability) => {
    const AIClone = JSON.parse(JSON.stringify(AI));
    AIClone[range] = Number.parseFloat(probability);
    setAI(AIClone);
  };

  const handleIPAIChange = (range, probability) => {
    const IPAIClone = JSON.parse(JSON.stringify(IPAI));
    IPAIClone[range] = Number.parseFloat(probability);
    setIPAI(IPAIClone);
  };

  const handleSiteRequirementTargetChange = (range, probability) => {
    const siteRequirementTargetClone = JSON.parse(
      JSON.stringify(siteRequirementTarget)
    );
    siteRequirementTargetClone[range] = Number.parseInt(probability);
    setSiteRequirementTarget(siteRequirementTargetClone);
  };

  const handleSiteRequirementActualChange = (range, probability) => {
    const siteRequirementActualClone = JSON.parse(
      JSON.stringify(siteRequirementActual)
    );
    siteRequirementActualClone[range] = Number.parseInt(probability);
    setSiteRequirementActual(siteRequirementActualClone);
  };

  const handleSiteLevelValueChange = (range, probability) => {
    const siteLevelValueClone = JSON.parse(JSON.stringify(siteLevelValue));
    siteLevelValueClone[range] = Number.parseInt(probability);
    setSiteLevelValue(siteLevelValueClone);
  };
  const handleSiteLevelAwardChange = (range, probability) => {
    const siteLevelAwardClone = JSON.parse(JSON.stringify(siteLevelAward));
    siteLevelAwardClone[range] = Number.parseInt(probability);
    setSiteLevelValue(siteLevelAwardClone);
  };

  const handleProductLevelValueChange = (range, probability) => {
    const productLevelValueClone = JSON.parse(
      JSON.stringify(productLevelValue)
    );
    productLevelValueClone[range] = Number.parseInt(probability);
    setProductRequirementActual(productLevelValueClone);
  };
  const handleProductLevelAwardChange = (range, probability) => {
    const productLevelAwardClone = JSON.parse(
      JSON.stringify(productLevelAward)
    );
    productLevelAwardClone[range] = Number.parseInt(probability);
    setProductRequirementActual(productLevelAwardClone);
  };

  const handleProductRequirementTargetChange = (range, probability) => {
    const productRequirementTargetClone = JSON.parse(
      JSON.stringify(productRequirementTarget)
    );
    productRequirementTargetClone[range] = Number.parseInt(probability);
    setSiteRequirementActual(productRequirementTargetClone);
  };

  const handleProductRequirementActualChange = (range, probability) => {
    const productRequirementActualClone = JSON.parse(
      JSON.stringify(productRequirementActual)
    );
    productRequirementActualClone[range] = Number.parseInt(probability);
    setSiteRequirementActual(productRequirementActualClone);
  };

  const handlePoolLevelValueChange = (range, probability) => {
    const poolLevelValueClone = JSON.parse(JSON.stringify(poolLevelValue));
    poolLevelValueClone[range] = Number.parseInt(probability);
    setPoolLevelValue(poolLevelValueClone);
  };
  const handlePoolLevelAwardChange = (range, probability) => {
    const poolLevelAwardClone = JSON.parse(JSON.stringify(poolLevelAward));
    poolLevelAwardClone[range] = Number.parseInt(probability);
    setPoolLevelAward(poolLevelAwardClone);
  };

  const handleWeekInputChange = (week, value) => {
    const weeksDataClone = JSON.parse(JSON.stringify(weeksData));
    weeksDataClone[week] = Number.parseInt(value) / 100;
    setWeeksData(weeksDataClone);
  };

  const handleLocationInputChange = (location, value) => {
    let locationsNew = JSON.parse(JSON.stringify(locations));
    locationsNew = locationsNew.map((x) => {
      if (x.value === location.value) {
        x.probability = Number.parseInt(value) / 100;
      }
      return x;
    });
    setLocations(locationsNew);
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Meta</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Brand Name"
              onChange={(event) => {
                setBrand(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Supplier Name"
              onChange={(event) => {
                setSupplier(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Total Pools"
              onChange={(event) => {
                setTotalPools(Number.parseInt(event.target.value));
              }}
            />
          </Grid>
        </Grid>
        <hr />
        <br />
        {/* <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Suppliers</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Number of Suppliers"
              onChange={(event) => {
                setSupplier(Number.parseInt(event.target.value));
              }}
            />
          </Grid>
        </Grid>
        {supplier > 0 &&
          [...Array(supplier).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Supplier${attribute + 1} Name`}
                      onChange={(event) => {
                        setSupplier(Number.parseInt(event.target.value));
                      }}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Supplier${attribute + 1} Probability`}
                      onChange={(event) => {
                        setSupplier(Number.parseInt(event.target.value));
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            );
          })} */}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Requirement</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Requirements"
              onChange={(event) => {
                setRequirements((prevState) => ({
                  ...prevState,
                  total: event.target ? Number.parseInt(event.target.value) : 0,
                }));
              }}
            />
          </Grid>
        </Grid>
        {requirements.total > 0 &&
          [...Array(requirements.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Name`}
                      onChange={(event) =>
                        handleRequirementsChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Pin`}
                      onChange={(event) =>
                        handleRequirementsPinChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Requirement</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Requirements"
              onChange={(event) => {
                setProductRequirements((prevState) => ({
                  ...prevState,
                  total: event.target ? Number.parseInt(event.target.value) : 0,
                }));
              }}
            />
          </Grid>
        </Grid>
        {productRequirements.total > 0 &&
          [...Array(productRequirements.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Name`}
                      onChange={(event) =>
                        handleProductRequirementsChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                  {/* <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Probability`}
                      // onChange={(event) => {
                      //   setProductRequirements(
                      //     Number.parseInt(event.target.value)
                      //   );
                      // }}
                    />
                  </Grid> */}
                </Grid>
              </>
            );
          })}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Events</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Events"
              onChange={(event) => {
                setEvents((prevState) => ({
                  ...prevState,
                  total: Number.parseInt(event.target.value),
                }));
              }}
            />
          </Grid>
        </Grid>
        {events.total > 0 &&
          [...Array(events.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Name`}
                      onChange={(event) =>
                        handleEventsChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Type`}
                      onChange={(event) =>
                        handleEventsTypeChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Probability`}
                      onChange={(event) =>
                        handleEventsProbabilityChange(
                          Number.parseInt(event.target.value) / 100,
                          attribute
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Events</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Events"
              onChange={(event) => {
                setProductEvents((prevState) => ({
                  ...prevState,
                  total: Number.parseInt(event.target.value),
                }));
              }}
            />
          </Grid>
        </Grid>
        {productEvents.total > 0 &&
          [...Array(productEvents.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Name`}
                      onChange={(event) =>
                        handleProductEventsChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Type`}
                      onChange={(event) =>
                        handleProductEventsTypeChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Probability`}
                      onChange={(event) =>
                        handleProductEventsProbabilityChange(
                          Number.parseInt(event.target.value) / 100,
                          attribute
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Incentive Pool Status</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Accepted/Active %"
              onChange={(event) =>
                handleIPStatusChange(
                  "Accepted",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Rejected %"
              onChange={(event) =>
                handleIPStatusChange(
                  "Rejected",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Accepted/Closed %"
              onChange={(event) =>
                handleIPStatusChange(
                  "CLOSED",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Inspection Status</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Pass %"
              onChange={(event) =>
                handleSIStatusChange(
                  "Pass",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Failed %"
              onChange={(event) =>
                handleSIStatusChange(
                  "Failed",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Inspection Validity</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Valid %"
              onChange={(event) =>
                handleSIValidityChange(
                  "Valid",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Warning %"
              onChange={(event) =>
                handleSIValidityChange(
                  "Warning",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Expired %"
              onChange={(event) =>
                handleSIValidityChange(
                  "Expired",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Test Status</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Pass %"
              onChange={(event) =>
                handleProductStatusChange(
                  "Pass",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Failed %"
              onChange={(event) =>
                handleProductStatusChange(
                  "Failed",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Supplier Alignment Index</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) => handleAIChange("min", event.target.value)}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) => handleAIChange("max", event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">IP Alignment Index</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) => handleIPAIChange("min", event.target.value)}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) => handleIPAIChange("max", event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Requirement Target</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleSiteRequirementTargetChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleSiteRequirementTargetChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Requirement Actual</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleSiteRequirementActualChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleSiteRequirementActualChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Level Value</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleSiteLevelValueChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleSiteLevelValueChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Level Award</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleSiteLevelAwardChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleSiteLevelAwardChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">
              Product Requirement Target
            </Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductRequirementTargetChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductRequirementTargetChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">
              Product Requirement Actual
            </Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductRequirementActualChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductRequirementActualChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Level Value</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductLevelValueChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductLevelValueChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Level Award</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductLevelAwardChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductLevelAwardChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Pool Level Value</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handlePoolLevelValueChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handlePoolLevelValueChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Pool Level Award</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handlePoolLevelAwardChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handlePoolLevelAwardChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Supplier Locations</Typography>
          </Grid>
          <Grid item md={10}>
            <Select
              className="mt-20"
              options={options}
              isMulti
              onChange={setLocations}
            />
          </Grid>
        </Grid>
        <hr />
        <Grid container spacing={1}>
          {locations &&
            locations.map((location) => {
              return (
                <Grid item md={2}>
                  <TextField
                    label={`${location.value}%`}
                    onChange={(event) =>
                      handleLocationInputChange(location, event.target.value)
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Order Time</Typography>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={10}>
            <TextField
              label="Number of Weeks"
              onChange={(event) =>
                setWeeks(Number.parseInt(event.target.value))
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {Number.isInteger(weeks) &&
            weeks !== 0 &&
            [...Array(weeks).keys()].map((week) => {
              return (
                <Grid item md={2}>
                  <TextField
                    label={`Week${week}%`}
                    onChange={(event) =>
                      handleWeekInputChange(week, event.target.value)
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                GenerateProvonomicsData({
                  locations,
                  orderPercentage,
                  IPStatus,
                  AI,
                  IPAI,
                  siteRequirementTarget,
                  siteRequirementActual,
                  siteLevelValue,
                  siteLevelAward,
                  productRequirementTarget,
                  productRequirementActual,
                  productLevelValue,
                  productLevelAward,
                  poolLevelValue,
                  poolLevelAward,
                  totalPools,
                  brand,
                  events,
                  productEvents,
                  requirements,
                  productRequirements,
                  weeksData,
                  supplier,
                  SIValidity,
                  SIStatus,
                  productStatus,
                })
              }
            >
              Generate
            </Button>
          </Grid>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              onClick={downloadJsonFile}
              color="primary"
            >
              Export Data
            </Button>
          </Grid>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                var msg = window.confirm(
                  "This will clear all data from your system. Do you want to proceed"
                );
                if (msg) {
                  localStorage.setItem("pools", JSON.stringify([]));
                }
              }}
              color="primary"
            >
              Clear Data
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default GenerateProvonomics;
