import moment from "moment";
const referenceDate = localStorage.getItem("referenceDate");
export const formatCurrency = (number) => {
  if (number === null) return "";
  const formatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
  return formatted.slice(0, -3);
};

export const formatDate = (date) => {
  if (moment(date).isValid()) return moment(date).format("DD MMMM YYYY")
  return ""
}

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const filterOrdersUpToTime = (orders, timeRange) => {
  let minDate = moment(referenceDate);

  if (timeRange === "all") {
    return orders;
  }
  if (timeRange === "month") {
    minDate = moment(referenceDate).subtract(1, "months");
    return (orders || []).filter(
      (order) => order.time && moment(order.time).isAfter(minDate)
    );
  }
  if (timeRange === "year") {
    minDate = moment(referenceDate).subtract(1, "years");
    return orders.filter(
      (order) => order.time && moment(order.time).isAfter(minDate)
    );
  }
  if (timeRange === "quarter") {
    minDate = moment(referenceDate).subtract(3, "months");
    return orders.filter(
      (order) => order.time && moment(order.time).isAfter(minDate)
    );
  }
  if (timeRange === "week") {
    minDate = moment(referenceDate).subtract(7, "days");
    return orders.filter(
      (order) => order.time && moment(order.time).isAfter(minDate)
    );
  }
};

export const capitaliseFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
