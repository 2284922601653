import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Card,
  Grid,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";
import CardSwiper from "../../CardsCarousel/cardsCarousel";
import SplineChart from "components/MainPageComponent/Charts/SplineChart";
import { generateSplineData } from "dataTransformers/splineData";
import moment from "moment";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import AverageView from "../../UI/AverageView";
import { formatCurrency, numberWithCommas } from "common/utils";
import BOMOptions from "../../BOM/BOMOptions";

const referenceDate = localStorage.getItem("referenceDate");

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
  averageViewHeading: {
    fontSize: "1.2rem",
    color: "#222222",
    fontWeight: "800",
  },
  averageViewDescription: {
    fontSize: ".8rem",
    color: "#222222",
    fontWeight: "300",
    marginLeft: theme.spacing(3),
  },
  circle: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(1),
      height: theme.spacing(1),
    },
  },
}));

const columnNames = [
  {
    Header: "Ack.",
    accessor: "ack",
  },
  {
    Header: "Ack Status",
    accessor: "ackStatus",
    setFlag: true,
  },
  {
    Header: "Order #",
    accessor: "po",
  },
  {
    Header: "Supplier Name",
    accessor: "supplier",
  },
  {
    Header: "Item",
    accessor: "product",
  },
  {
    Header: "PO Created Date",
    accessor: "createdDate",
    className: "highlight",
  },
  {
    Header: "SO Confirmed Date",
    accessor: "confirmedDate",
    className: "highlight",
  },
  {
    Header: "PO Requested Date",
    accessor: "requestedDate",
    className: "highlight",
  },
  {
    Header: "SO Promise Date",
    accessor: "promiseDate",
    className: "highlight",
  },
  {
    Header: "PO Total Qty",
    accessor: "poTotalQty",
  },
  {
    Header: "SO Total Qty",
    accessor: "soTotalQty",
  },
  {
    Header: "PO Total $",
    accessor: "poTotal",
  },
  ,
  {
    Header: "SO Total $",
    accessor: "soTotal",
  },
];

const formatDate = (date) => {
  return moment(date).format("Do MMMM YYYY");
};

const getOrder = (item) => {
  const ackDays =
    item.orderStatus === "unconfirmed" || item.orderStatus === "unconfirmedLag"
      ? parseInt(moment(referenceDate).diff(moment(item.time), "days"))
      : parseInt(moment(item.soConfirmedDate).diff(moment(item.time), "days"));
  const ack = `${ackDays} d`;

  const show = true;
  // parseInt(
  //   (new Date(item.soConfirmedDate).getTime() -
  //     new Date(item.time).getTime()) /
  //     (1000 * 3600 * 24)
  // ) > parseInt(item.soDelayPeriod);

  const dateWarning = item.soPromiseDate != item.poRequestedDate;

  const soWarning = item.poQty !== item.soQty;
  // if (ackDays > 200 || ackDays < 0) {
  //   return {};
  // }
  return {
    sortId: moment(item.time).format("YYYY-MM-DD"),
    ack,
    product: item.product,
    ackStatus: item.orderStatus,
    po: item.orderNumber,
    status: item.orderStatus,
    supplier: item.supplier,
    createdDate: formatDate(item.time),
    confirmedDate:
      item.orderStatus === "unconfirmed" ||
      item.orderStatus === "unconfirmedLag"
        ? "---"
        : formatDate(item.soConfirmedDate),
    requestedDate: formatDate(item.poRequestedDate),
    promiseDate:
      item.orderStatus === "unconfirmed" ||
      item.orderStatus === "unconfirmedLag"
        ? "---"
        : formatDate(item.soPromiseDate),
    poTotalQty: `${numberWithCommas(item.poQty) || 0} ${item.units}`,
    soTotalQty:
      item.orderStatus === "unconfirmed" ||
      item.orderStatus === "unconfirmedLag"
        ? "---"
        : `${numberWithCommas(item.soQty) || 0} ${item.units}`,
    poTotal: `${formatCurrency(
      parseInt(item.poQty) * parseFloat(item.unitPrice) || 0
    )}`,
    soTotal:
      item.orderStatus === "unconfirmed" ||
      item.orderStatus === "unconfirmedLag"
        ? "---"
        : `${formatCurrency(
            parseInt(item.soQty) * parseFloat(item.unitPrice) || 0
          )}`,
    show,
    dateWarning,
    soWarning,
  };
};

const getOrderDetailsFromLocalStorage = async () => {
  return JSON.parse(window.localStorage.getItem("ordersData"));
};

const getOrderDetails = (orders, timeFilter) => {
  if (!orders) {
    return {};
  }

  const refDate = moment(referenceDate);
  const quaterBefore = refDate.clone().subtract(3, "months").unix();
  const monthBefore = refDate.clone().subtract(1, "months").unix();
  const oneWeekBefore = refDate.clone().subtract(1, "weeks").unix();
  const yearBefore = refDate.clone().subtract(1, "years").unix();

  let submittedOrderCount = 0;
  let submittedOrderTotal = 0;

  let confirmedOrderCount = 0;
  let confirmedOrderTotal = 0;

  let unConfirmedOrderCount = 0;
  let unConfirmedOrderTotal = 0;

  let warningOrderCount = 0;
  let warningOrderTotal = 0;
  let submittedArray = [];
  let confirmedArray = [];
  let unconfirmedArray = [];
  let warningArray = [];
  const getItemTimeMoment = (item) => {
    return item.time && moment(item.time).unix();
  };
  switch (timeFilter) {
    case "month":
      orders = orders.filter(
        (item) =>
          getItemTimeMoment(item) >= monthBefore &&
          getItemTimeMoment(item) <= refDate
      );
      break;
    case "year":
      orders = orders.filter(
        (item) =>
          getItemTimeMoment(item) >= yearBefore &&
          getItemTimeMoment(item) <= refDate
      );
      break;
    case "week":
      orders = orders.filter(
        (item) =>
          getItemTimeMoment(item) >= oneWeekBefore &&
          getItemTimeMoment(item) <= refDate
      );
      break;
    case "quarter":
      orders = orders.filter(
        (item) =>
          getItemTimeMoment(item) >= quaterBefore &&
          getItemTimeMoment(item) <= refDate
      );
      break;
  }
  orders.forEach((item) => {
    submittedOrderTotal += item.spent;
    if (!getOrder(item).product) {
      return;
    }
    submittedOrderCount++;
    submittedArray.push(getOrder(item));
    switch (item.orderStatus) {
      case "confirmed":
        confirmedOrderCount++;
        confirmedOrderTotal += item.spent;
        confirmedArray.push(getOrder(item));
        break;
      case "confirmedLag":
        confirmedOrderCount++;
        confirmedOrderTotal += item.spent;
        confirmedArray.push(getOrder(item));
        warningOrderCount++;
        warningOrderTotal += item.spent;
        warningArray.push(getOrder(item));
        break;
      case "unconfirmedLag":
        unConfirmedOrderCount++;
        unConfirmedOrderTotal += item.spent;
        unconfirmedArray.push(getOrder(item));
        warningOrderCount++;
        warningOrderTotal += item.spent;
        warningArray.push(getOrder(item));
        break;
      case "unconfirmed":
        unConfirmedOrderCount++;
        unConfirmedOrderTotal += item.spent;
        unconfirmedArray.push(getOrder(item));
        break;
    }
  });

  return {
    orders,
    submittedOrderCount,
    submittedOrderTotal,
    confirmedOrderCount,
    confirmedOrderTotal,
    unConfirmedOrderCount,
    unConfirmedOrderTotal,
    warningOrderCount,
    warningOrderTotal,
    submittedArray,
    confirmedArray,
    unconfirmedArray,
    warningArray,
  };
};

const OrderActivity = (props) => {
  const { drawerOpen, timeFilter, orders, handleCardClick, location } = props;
  const classes = useStyles();
  const [slidesPerView, setslidesPerView] = React.useState(() =>
    drawerOpen ? 4 : 0
  );
  const loadingPlaceholder = "...";
  const [data, setData] = useState(props.data);

  React.useEffect(() => {
    if (orders) {
      // getOrderDetailsFromLocalStorage().then((items) => {
      setData(getOrderDetails(orders, timeFilter));
      // });
    }
  }, [orders, timeFilter]);

  if (drawerOpen) {
    setTimeout(() => {
      setslidesPerView(4);
    }, 800);
  } else {
    setTimeout(() => {
      setslidesPerView(1);
    }, 800);
  }

  return (
    <Box className={classes.stepper}>
      <Grid
        container
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <Grid item style={{ width: "50%" }}>
          <Typography variant="h4" className={classes.sectionHeading}>
            Showing data for {location}
          </Typography>
        </Grid>
        <Grid item style={{ width: "50%", textAlign: "right" }}>
          <BOMOptions
            label="Select BOM"
            value={props.selectedBOM}
            handleChange={props.BOMChnage}
          />
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row" alignItems="center">
        {data && (
          <AverageView
            title={data.orders.length}
            description="Total Orders"
            color="#7FA0EA"
          />
        )}
        {/* <SplineChart data={generateSplineData(orders, false, timeFilter)} />
        <span className="ticker-percentage">
          10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
        </span> */}
      </Box>
      <CardSwiper
        disabled={false}
        sideBarHeader={"Order Activity"}
        handleCardClick={(title) => handleCardClick(title, "orders")}
        columns={columnNames}
        timeFilter={timeFilter}
        data={[
          {
            header: `${
              data && data.submittedOrderCount ? data.submittedOrderCount : 0
            } Submitted`,
            title: `${
              data && data.submittedOrderCount >= 0
                ? data.submittedOrderCount
                : loadingPlaceholder
            }`,
            subtitle: "Submitted",
            disable: !(data && data.submittedOrderTotal > 0),
            alertIcon: false,
            rows: data && data.submittedArray ? data.submittedArray : [],
          },
          {
            header: `${
              data && data.confirmedOrderCount ? data.confirmedOrderCount : 0
            } Confirmed`,
            title: `${
              data && data.confirmedOrderCount >= 0
                ? data.confirmedOrderCount
                : loadingPlaceholder
            }`,
            subtitle: "Confirmed",
            disable: !(data && data.confirmedOrderCount > 0),
            alertIcon: false,
            rows: data && data.confirmedArray ? data.confirmedArray : [],
          },
          {
            header: `${
              data && data.unConfirmedOrderCount
                ? data.unConfirmedOrderCount
                : 0
            } Unconfirmed`,
            title: `${
              data && data.unConfirmedOrderCount >= 0
                ? data.unConfirmedOrderCount
                : loadingPlaceholder
            }`,
            subtitle: "Unconfirmed",
            disable: !(data && data.unConfirmedOrderCount > 0),
            alertIcon: false,
            rows: data && data.unconfirmedArray ? data.unconfirmedArray : [],
          },
          {
            header: `${
              data && data.warningOrderCount ? data.warningOrderCount : 0
            } Lag Warning`,
            title: `${
              data && data.warningOrderCount >= 0
                ? data.warningOrderCount
                : loadingPlaceholder
            }`,
            subtitle: "Lag Warning",
            disable: !(data && data.warningOrderCount > 0),
            alertIcon: true,
            rows: data && data.warningArray ? data.warningArray : [],
          },
        ]}
        slidesPerView={slidesPerView}
      />
      <br />
      <Divider className="divider" />
      <br />
      <Typography variant="h4" className={classes.sectionHeading}>
        Showing data for {location}
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {data && (
          <AverageView
            title={`${formatCurrency(data.submittedOrderTotal)}`}
            description="Total Spend"
            color="#DD6717"
          />
        )}
        {/* <SplineChart data={generateSplineData(orders, true, timeFilter)} />
        <span className="ticker-percentage">
          10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
        </span> */}
      </Box>
      <CardSwiper
        disable={true}
        columns={columnNames}
        timeFilter={timeFilter}
        sideBarHeader={"Order Activity"}
        handleCardClick={(title) => handleCardClick(title, "spend")}
        data={[
          {
            header: `${
              data && data.submittedOrderTotal
                ? formatCurrency(data.submittedOrderTotal)
                : 0
            } Submitted`,
            title: `${
              data && data.submittedOrderTotal >= 0
                ? formatCurrency(data.submittedOrderTotal)
                : loadingPlaceholder
            }`,
            subtitle: "Submitted",
            disable: !(data && data.submittedOrderTotal > 0),
            alertIcon: false,
            rows: data && data.submittedArray ? data.submittedArray : [],
          },
          {
            header: `${
              data && data.confirmedOrderTotal
                ? formatCurrency(data.confirmedOrderTotal)
                : 0
            } Confirmed`,
            title: `${
              data && data.confirmedOrderTotal >= 0
                ? formatCurrency(data.confirmedOrderTotal)
                : loadingPlaceholder
            }`,
            subtitle: "Confirmed",
            disable: !(data && data.confirmedOrderTotal > 0),
            alertIcon: false,
            rows: data && data.confirmedArray ? data.confirmedArray : [],
          },
          {
            header: `${
              data && data.unConfirmedOrderTotal
                ? formatCurrency(data.unConfirmedOrderTotal)
                : 0
            } Unconfirmed`,
            title: `${
              data && data.unConfirmedOrderTotal >= 0
                ? formatCurrency(data.unConfirmedOrderTotal)
                : loadingPlaceholder
            }`,
            subtitle: "Unconfirmed",
            disable: !(data && data.unConfirmedOrderTotal > 0),
            alertIcon: false,
            rows: data && data.unconfirmedArray ? data.unconfirmedArray : [],
          },
          {
            header: `${
              data && data.warningOrderTotal
                ? formatCurrency(data.warningOrderTotal)
                : 0
            } Lag Warning`,
            title: `${
              data && data.warningOrderTotal >= 0
                ? formatCurrency(data.warningOrderTotal)
                : loadingPlaceholder
            }`,
            subtitle: "Lag Warning",
            note: "(Next 30 days)",
            disable: !(data && data.warningOrderTotal > 0),
            alertIcon: true,
            rows: data && data.warningArray ? data.warningArray : [],
          },
        ]}
        slidesPerView={slidesPerView}
      />
    </Box>
  );
};

export default OrderActivity;
