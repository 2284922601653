import React from "react";
import { Box, makeStyles, Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "14rem",
    width: "100%",
    background: theme.palette.common.white,
    borderRadius: "0.5rem",
    padding: "0.2rem 0.5rem",
    "& svg": {
      marginTop: "0.5rem",
      fill: theme.palette.secondary.light,
    },
  },
  heading: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  total: {
    color: theme.palette.secondary.dark,
  },
  passed: {
    color: theme.palette.primary.green,
  },
  failed: {
    color: "#dd1717",
  },
  warningText: {
    fontSize: "0.675rem",
    color: theme.palette.primary.red,
  },
  title: {
    color: theme.palette.secondary.main,
    fontSize: "0.75rem",
  },
}));

const InspectionCard = (props) => {
  const {
    title,
    total,
    passed,
    failed,
    warningText,
    icon,
    onClick = () => {},
    showClear,
  } = props;
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      onClick={onClick}
      border={showClear && 2}
      borderRadius={4}
      style={{ cursor: "pointer" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={3} container justify="center">
          {icon}
        </Grid>
        <Grid item xs={9}>
          <Box className={classes.heading}>
            <span className={classes.total}>{total}</span>
            {passed && <span className={classes.passed}> / {passed}</span>}
            {failed && <span className={classes.failed}> / {failed}</span>}
          </Box>
          <Typography className={classes.title}> {title}</Typography>
          {warningText && (
            <Typography className={classes.warningText}>
              {" "}
              {warningText}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default InspectionCard;
