import React from "react";
import clsx from "clsx";
import { makeStyles, useTheme, Box } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import StepperComponent from "../../StepperComponent/stepper";
import SiteInspection from "../SiteInspection/siteInspection";
import OrderActivity from "../OrderActivity/orderActivity";
import OrderStatus from "../OrderStatus/orderStatus";
import ProvenomicsPool from "../ProvenomicsPool/provenomicsPool";
import { drawerToggle } from "../../../assets/svgIconList";
import SelectComponent from "../../UI/SelectComponent";
import Suppliers from "../Supplier/Suppliers";
import { GAUS_1_MANAGERS, MAX_1_MANAGERS } from "../../BOM/BOMColumns";

const drawerWidth = 600;
const drawerMinWidth = 250;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "0 0 1.5rem #ccc",
    order: "1",
  },

  drawerOpen: {
    width: drawerWidth,
    transition: "width 0.5s",
    padding: "2rem 0.5rem 2rem 2rem",
    overflow: "visible",
    [theme.breakpoints.down("lg")]: {
      "& ~ .mainContainer": {
        "& .search": {
          opacity: "0",
          width: "0",
        },
      },
    },
  },

  drawerClose: {
    transition: "width 0.5s",
    overflowX: "hidden",
    width: drawerMinWidth,
    padding: "2rem 0.5rem 2rem 2rem",
    overflowX: "visible",
    overflow: "visible",

    "& .grid-item": {
      maxWidth: "100%",
      flexBasis: "100%",
    },
    "& .swiper-slide": {
      padding: "1rem 1.5rem",
    },
    '& [aria-label="Next slide"]': {
      right: 0,
    },
    '& [aria-label="Previous slide"]': {
      left: 0,
    },
    "& .select-wraper": {
      display: "none",
    },
  },

  drawerToggle: {
    position: "absolute",
    zIndex: "9999",
    position: "absolute",
    left: "0",
    top: "6rem",
    transform: " translateX(-50%)",
  },

  drawerContainer: {
    height: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: "1.5rem",
  },

  tabStyle: {
    display: "inline-flex",
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTabs-root": {
      display: "inline-block",
    },
    "& .MuiTabs-flexContainer": {
      border: "1px solid #EBEBEB",
      borderRadius: "0.3rem",
    },
    "& .MuiTab-root": {
      marginRight: "0 !important",
      padding: "0.2rem 1rem !important",
      fontWeight: "400",
      "&.Mui-selected": {
        background: "#1C8267",
        boxShadow: "0px 0px 6px #00000029",
        borderRadius: "4px",
        fontWeight: "500",
        color: "#FFFFFF",
      },
    },
  },
  tabPanelStyle: {
    padding: "1rem 0",
  },
  selectManager: {
    "& .custom-select": {
      width: "100%",
    },
  },
}));

export const timeFilterData = [
  { value: "all", label: "All" },
  { value: "year", label: "Year" },
  { value: "quarter", label: "Quarter" },
  { value: "month", label: "Month" },
  { value: "week", label: "Week" },
];

const Sidebar = (props) => {
  const classes = useStyles();
  const {
    handleStepperChange,
    activeStep,
    orders,
    handleCardClick,
    location,
    BOMChnage,
    selectedBOM,
    triggerUpdate,
    handleManagerChange,
    setMainTimeFilter,
    selectedManager,
    setSelectedManager,
    selectedInspection,
    setSelectedInspection,
    unfilteredOrders
  } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState("1");
  const [timeFilter, setTimeFilter] = React.useState(
    localStorage.getItem("selectedTimeFilter")
  );
  // const [selectedManager, setSelectedManager] = React.useState("All");
  const role = localStorage.getItem("role");

  const handleDrawerToggle = () => {
    if (open) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const timeFilterData = [
    { value: "all", label: "All" },
    { value: "year", label: "Year" },
    { value: "quarter", label: "Quarter" },
    { value: "month", label: "Month" },
    { value: "week", label: "Week" },
  ];

  const PeriodSelector = ({ handleChange }) => {
    return (
      <SelectComponent
        options={timeFilterData}
        handleChange={(event) => handleChange(event)}
        selectLabel="Time Period"
        value={timeFilter}
      />
    );
  };

  const CXOSelector = ({ handleChange }) => {
    // const managers = JSON.parse(localStorage.getItem("managers"));
    const managers =
      selectedBOM === 123
        ? [...GAUS_1_MANAGERS].sort()
        : selectedBOM === 124
        ? [...MAX_1_MANAGERS].sort()
        : [...GAUS_1_MANAGERS, ...MAX_1_MANAGERS].sort();
    managers.unshift("All");
    return (
      <SelectComponent
        options={managers.map((manager) => {
          return { value: manager, label: manager };
        })}
        handleChange={(event) => {
          setSelectedManager(event.target.value);
          handleManagerChange(event.target.value);
        }}
        selectLabel="Select Manager"
        value={selectedManager}
      />
    );
  };

  const handleButtonGroup = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      elevation={1}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <span className={classes.drawerToggle} onClick={handleDrawerToggle}>
        {drawerToggle}
      </span>
      <Box className={classes.drawerContainer}>
        <Box>
          <TabContext value={value}>
            <Box display="flex" alignItems="flex-end" flexDirection="row">
              <Box mr={"1rem"}>
                <TabList
                  onChange={handleButtonGroup}
                  className={classes.tabStyle}
                >
                  <Tab label={"Buyer"} value="1" />
                  <Tab disabled label={"Seller"} value="2" />
                </TabList>
              </Box>
              <Box
                flexGrow={1}
                display="flex"
                alignItems="flex-end"
                flexDirection="row"
                visibility={activeStep === 4 ? "hidden" : "visible"}
                className="select-wraper"
              >
                <Box mr="1rem" flexGrow={1} className={classes.selectManager}>
                  {role === "CXO" && activeStep !== 3 && (
                    <CXOSelector handleChange={() => {}} />
                  )}
                </Box>
                <PeriodSelector
                  handleChange={(v) => {
                    localStorage.setItem("selectedTimeFilter", v.target.value);
                    setTimeFilter(v.target.value);
                    setMainTimeFilter(v.target.value);
                  }}
                />
              </Box>
            </Box>

            <TabPanel className={classes.tabPanelStyle} value="1">
              <StepperComponent
                onChange={handleStepperChange}
                activeStep={activeStep || 0}
                data={[
                  {
                    label: "Order Activity",
                    componenet: (
                      <OrderActivity
                        drawerOpen={open}
                        timeFilter={timeFilter}
                        data={undefined}
                        orders={orders}
                        selectedBOM={selectedBOM}
                        BOMChnage={BOMChnage}
                        location={location}
                        handleCardClick={(card, section) =>
                          handleCardClick(card, section, 0)
                        }
                      />
                    ),
                  },
                  {
                    label: "Order Status",
                    componenet: (
                      <OrderStatus
                        drawerOpen={open}
                        timeFilter
                        orders={orders}
                        BOMChnage={BOMChnage}
                        selectedBOM={selectedBOM}
                        location={location}
                        timeFilter={timeFilter}
                        handleCardClick={(card, section) =>
                          handleCardClick(card, section, 1)
                        }
                      />
                    ),
                  },
                  {
                    label: <span>Provenomics&trade; Pool</span>,
                    componenet: (
                      <ProvenomicsPool
                        drawerOpen={open}
                        orders={orders}
                        selectedBOM={selectedBOM}
                        BOMChnage={BOMChnage}
                        timeFilter={timeFilter}
                        location={location}
                        handleCardClick={(card, section) =>
                          handleCardClick(card, section, 2)
                        }
                        data={orders}
                      />
                    ),
                  },
                  {
                    label: "Site Inspection",
                    componenet: (
                      <SiteInspection
                        drawerOpen={open}
                        timeFilter={timeFilter}
                        selectedBOM={selectedBOM}
                        BOMChnage={BOMChnage}
                        triggerUpdate={triggerUpdate}
                        location={location}
                        data={orders}
                        handleCardClick={(card, section) =>
                          handleCardClick(card, section, 3)
                        }
                        selectedInspection={selectedInspection}
                        setSelectedInspection={setSelectedInspection}
                        unfilteredData={unfilteredOrders}
                      />
                    ),
                  },
                  {
                    label: "Suppliers",
                    componenet: (
                      <Suppliers
                        drawerOpen={open}
                        timeFilter={timeFilter}
                        selectedBOM={selectedBOM}
                        location={location}
                        BOMChnage={BOMChnage}
                        data={orders}
                        handleCardClick={(card, section) =>
                          handleCardClick(card, section, 4)
                        }
                      />
                    ),
                  },
                ]}
              />
            </TabPanel>
            <TabPanel className={classes.tabPanelStyle} value="2"></TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
