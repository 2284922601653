import React from "react";
import { TextField, withStyles, FormControl, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: props.fullWidth ? "100%" : 200,
  })
}));

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#526161",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#526161",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#526161",
      },
      "&:hover fieldset": {
        borderColor: "#526161",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#526161",
      },
    },
  },
})(TextField);

const TextInput = (props) => {
  const classes = useStyles(props);
  return (
    <FormControl className={classes.formControl}>
      <CssTextField
        {...props}
        size="small"
      />
    </FormControl>
  );
};

export default TextInput;
