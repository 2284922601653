import React from "react";
import {
  Box,
  Button,
  Paper,
  MobileStepper,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  stepperwrap: {
    width: "100%",
    flexGrow: 1,
    position: "relative",
  },
  stepper: {
    fontSize: "0",
    "& >div": {
      backgroundColor: theme.palette.common.white,
      border: "1px solid " + theme.palette.primary.border,
      borderLeft: "none",
      borderRight: "none",
      padding: "0.5rem 0",
    },
    "& Button": {
      minWidth: "0",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: 50,
    width: "calc(100% - 10rem)",
    color: theme.palette.secondary.dark,
    fontWeight: "500",
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    top: "0.1rem",
    left: "50%",
    transform: "translateX(-50%)",
    fontSize: "0.875rem",
  },
  stepperContainer: {
    paddingTop: "1.5rem",
  },
}));
const StepperComponent = (props) => {
  const { data, onChange, activeStep } = props;
  const classes = useStyles();
  const theme = useTheme();

  const maxSteps = data.length;

  const handleNext = () => {
    onChange(activeStep + 1);
  };

  const handleBack = () => {
    onChange(activeStep - 1);
  };

  return (
    <div className={classes.stepperwrap}>
      <Box className={classes.stepper}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
            </Button>
          }
        />
      </Box>
      <Paper square elevation={0} className={classes.header}>
        {data[activeStep].label}
      </Paper>
      <Box className={classes.stepperContainer}>
        {data[activeStep].componenet}
      </Box>
    </div>
  );
};

export default StepperComponent;
