import React, { useState, useEffect } from "react";
import { makeStyles, Box, Grid, Typography, Link } from "@material-ui/core";
import moment from "moment";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import "swiper/css/swiper.css";
import CustomTable from "../../UI/Table/table";
import OrderStatusCard from "../../OrderStatusCard/orderStatusCard";
import { lockIcon } from "../../../assets/svgIconList";
import { formatCurrency, formatDate } from "common/utils";
import CustomizedDialog from "../../UI/Dialog";
import ImgProcess from "../../../assets/images/inprocess-hyperlink-msg.png";
import ImgRealtimeProcess from "../../../assets/images/inprocess-realtime-msg.png";
import { filterDataByTime } from "components/MainPageComponent/helpers";
import { getStartDate } from "../../../dataTransformers/utils";
import BOMOptions from "../../BOM/BOMOptions";

const referenceDate = localStorage.getItem("referenceDate");

const drawerWidth = "100%";
const drawerMinWidth = 950;

const orderColorMap = {
  "On Time": "green",
  Delayed: "red",
  "In Transit": "yellow",
};

const getOrderStatus = (poRequestedDate, soPromiseDate, fillStatus) => {
  if (moment(referenceDate).diff(soPromiseDate, "days") > 30) {
    return "DELIVERED";
  }
  if (
    (moment(referenceDate).diff(soPromiseDate, "days") > 0 ||
      fillStatus > 100) &&
    fillStatus > 0
  ) {
    return <Link>In-Transit</Link>;
  }
  if (soPromiseDate.diff(poRequestedDate, "days") > 0) {
    return "Delayed";
  }
  if (poRequestedDate.diff(soPromiseDate, "days") === 0) {
    return "On Time";
  }
};

const useStyles = makeStyles((theme) => ({
  OrderStatusClose: {
    "& .cardRight": {
      flexDirection: "column",
      alignItems: "center",
      "& .details": {
        width: "100%",
      },
    },
    "& .MuiGrid-container": {
      flexDirection: "column",
      alignItems: "center",
    },
    '& [class*="stdetails"]': {
      width: "100%",
      textAlign: "center",
    },
    '& [class*="stHeading"]': {
      fontSize: "1rem",
    },
  },

  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
  tabSectionHeading: {
    background: "#F1F3F4",
    color: theme.palette.secondary.dark,
    fontSize: "0.875rem",
    padding: "0.5rem",
  },
  tabPanelStyle: {
    padding: "1rem 0",
    background: "white",
  },
  innertabStyle: {
    "& .MuiTab-root": {
      fontSize: "0.875rem",
    },
  },
  cardStats: {
    padding: "1rem 0 0",
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  stChart: {
    width: "4rem",
    paddingTop: "0.5rem !important",
    textAlign: "center",
  },
  stdetails: {
    width: "calc(100% - 4rem)",
  },
  stHeading: {
    fontSize: "1.5rem",
    fontWeight: "700",
  },
  stStatus: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
  },
  stUpdated: {
    color: "#999999",
    fontSize: "0.75rem",
  },
  btn: {
    fontSize: "0.75rem",
    fontWeight: "normal",
    color: theme.palette.secondary.dark,
  },

  overrelay: {
    background: "rgba(0, 0, 0, 0.36)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
  },
  tableDrawer: {
    width: "0",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "0 0 1.5rem #ccc",
    position: "fixed",
    height: "100vh",
    overflow: "auto",
    right: "0",
    top: "0",
    zIndex: "9999",
    transition: "all 0.5s",

    "&.open": {
      width: drawerWidth,
    },
  },
  tableDrawerContainer: {
    width: drawerMinWidth,
    background: "#fff",
    marginLeft: "auto",
    height: "100%",
    padding: "2rem 3rem",
    position: "relative",
    "&.fullscreen": {
      width: "100%",
    },
  },
  closeTableDrawer: {
    position: "absolute",
    left: "1rem",
    top: "2.17rem",
  },
  tableDrawerTitle: {
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  tableWrapper: {
    // maxHeight: "calc(100vh - 8rem)",
    // overflow: "auto",
    marginTop: "1rem",
  },
}));
const columns = [
  {
    Header: () => null,
    id: "expander",
    Cell: ({ row }) => (
      <span
        {...row.getToggleRowExpandedProps()}
        style={{ display: "flex", cursor: "pointer" }}
      >
        {row.isExpanded ? (
          <RemoveCircleOutlineIcon
            fontSize="small"
            style={{ color: "#4896E9" }}
          />
        ) : (
          <AddCircleOutlineIcon fontSize="small" style={{ color: "#4896E9" }} />
        )}
      </span>
    ),
  },
  {
    Header: "PO #",
    accessor: "orderNumber",
  },
  {
    Header: "Supplier Name",
    accessor: "supplier",
  },
  {
    Header: "Item #",
    accessor: "itemNo",
  },
  {
    Header: "Item Name",
    accessor: "item",
  },
  {
    Header: "SO QTY",
    accessor: "soQty",
  },
  {
    Header: "QTY Complete",
    accessor: "qtyComplete",
  },
  {
    Header: "Percent Complete",
    accessor: "filled",
  },
  {
    Header: "$ Ordered",
    accessor: "ordered",
    Cell: (props) => formatCurrency(props.value),
  },
  {
    Header: "$ Complete",
    accessor: "complete",
    Cell: (props) => formatCurrency(props.value),
  },
  {
    Header: "Need By Date",
    accessor: "needByDate",
    Cell: (props) => formatDate(props.value),
  },
  {
    Header: "Warning Window",
    accessor: "warningWindow",
  },
  {
    Header: "Delivery Status ",
    accessor: "deliveryStatus",
    // Cell: (props) => {
    //   const order = props.data[props.row.index];
    //   const color = orderColorMap[order.deliveryStatus];
    //   return order.deliveryStatus;
    // },
  },
];

const expandedTableColumns = [
  {
    Header: "Milestone Name",
    accessor: "name",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Evidence Submitted",
    accessor: "evidenceSubmitted",
    Cell: (row) => {
      let val = row.cell.value;
      if (!val) return null;
      if (!Array.isArray(val)) {
        val = [val];
      }
      const evidencecs = (val || []).map((evi, index) => {
        let fileName = evi.name || "file";
        const extnArr = evi.url && evi.url.split(".");
        fileName = `${fileName}.${extnArr[extnArr.length - 1]}`;
        return (
          <>
            <a
              target="_blank"
              className="link"
              download={fileName}
              href={evi.url}
            >
              {evi.name}
              {index < val.length - 1 ? "," : ""}
            </a>{" "}
          </>
        );
      });
      return evidencecs;
    },
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Date/Time",
    accessor: "date",
    Cell: (props) => formatDate(props.value),
  },
  {
    Header: "Quantity Complete",
    accessor: "qtyComplete",
  },
  {
    Header: "%Complete",
    accessor: "percentComplete",
  },
  {
    Header: "$Complete",
    accessor: "amountComplete",
  },
];

const cardsPerPage = 25;

const OrderStatus = (props) => {
  const { drawerOpen, orders, timeFilter, location } = props;
  const ordersCopy = JSON.parse(JSON.stringify(orders));
  const ordersWithDetails = ordersCopy.map((row) => {
    row.details = row.milestones;
    row.sortId = moment(row.time).format("YYYY-MM-DD");
    return row;
  });
  let ordersClone =
    timeFilter !== "all"
      ? JSON.parse(JSON.stringify(filterDataByTime(orders, timeFilter)))
      : JSON.parse(JSON.stringify(orders));
  const classes = useStyles();
  const [open, setDrawerStatus] = React.useState(() =>
    drawerOpen ? true : false
  );
  const [fullscreen, setfullscreen] = React.useState(false);
  const [swiper, setSwiper] = React.useState({
    open: false,
    rows: [],
    header: "",
  });
  const [dialog, setDialog] = useState(false);
  const [realtimeDialog, setRealtimeDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [timeFilter]);

  if (drawerOpen) {
    setTimeout(() => {
      setDrawerStatus(true);
    }, 100);
  } else {
    setTimeout(() => {
      setDrawerStatus(false);
    }, 100);
  }

  const handleDrawerToggle = (r, h) => {
    if (swiper.open) {
      setSwiper({ open: false, rows: r ? r : [], header: h ? h : "" });
      setfullscreen(false);
    } else {
      setSwiper({ open: true, rows: r ? r : [], header: h ? h : "" });
    }
  };

  const handleDrawerAspectRatio = () => {
    setfullscreen(!fullscreen);
  };

  let filledOrders = ordersClone.map((order) => order.filled);

  return (
    <Box className={open ? "" : classes.OrderStatusClose}>
      <CustomizedDialog
        img={ImgProcess}
        open={realtimeDialog}
        callback={setRealtimeDialog}
      />
      <CustomizedDialog
        img={ImgRealtimeProcess}
        open={dialog}
        callback={setDialog}
      />
      <Box className={classes.topSection}>
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Grid item style={{ width: "50%" }}>
            <Typography variant="h4" className={classes.sectionHeading}>
              Showing data for {location}
            </Typography>
          </Grid>
          <Grid item style={{ width: "50%", textAlign: "right" }}>
            <BOMOptions
              label="Select BOM"
              value={props.selectedBOM}
              handleChange={props.BOMChnage}
            />
          </Grid>
        </Grid>
      </Box>
      <Box component="div" className="tab-chart-container">
        <Link
          href="#"
          className="link"
          onClick={() => handleDrawerToggle(orders, "Total Orders")}
        >
          More Details
          <ArrowForwardIosIcon style={{ fontSize: "0.55rem" }} />
        </Link>
        {ordersClone
          .slice(0, cardsPerPage * currentPage)
          .map((order, index) => {
            return (
              <OrderStatusCard
                key={`status-card-${index}`}
                icon={lockIcon}
                supplier={order.supplier}
                filled={order.filled}
                po={order.orderNumber}
                requestedDate={order.poRequestedDate}
                deliveryStatus={order.deliveryStatus}
                totalRequirements={order.milestones.length}
                fulfilledRequirements={
                  order.milestones.filter((row) => row.status === "Done").length
                }
                setRealtimeDialog={setRealtimeDialog}
                amount={formatCurrency(order.poQty * order.unitPrice)}
                orderUpdates={"Order Updates"}
                actualdate={order.time}
                color={orderColorMap[order.deliveryStatus]}
                deliveryDate={getOrderStatus(
                  moment(order.time),
                  moment(order.poRequestedDate),
                  moment(order.soPromiseDate),
                  order.soDelayPeriod,
                  order.filled
                )}
              />
            );
          })}
        {cardsPerPage * currentPage < ordersClone.length && (
          <Link
            href="#"
            className="link"
            onClick={() => setCurrentPage(currentPage + 1)}
          >
            Load more
          </Link>
        )}
      </Box>
      {swiper.open && (
        <Box
          className={
            swiper.open
              ? "open " + classes.tableDrawer
              : "close " + classes.tableDrawer
          }
        >
          <Box
            className={classes.overrelay}
            onClick={() => handleDrawerToggle()}
          ></Box>
          <Box
            className={
              fullscreen
                ? "fullscreen " + classes.tableDrawerContainer
                : classes.tableDrawerContainer
            }
          >
            <CloseIcon
              fontSize="small"
              className={classes.closeTableDrawer}
              onClick={() => handleDrawerToggle()}
            />
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h2" className={classes.tableDrawerTitle}>
                  Deep Dive / Order Status
                </Typography>
              </Grid>
              <Grid item>
                <AspectRatioIcon
                  fontSize="small"
                  className={classes.aspectRatioIcon}
                  onClick={handleDrawerAspectRatio}
                />
              </Grid>
            </Grid>
            <Box className={classes.tableWrapper}>
              <CustomTable
                rows={ordersWithDetails}
                columns={columns ? columns : []}
                title={swiper.header}
                expandedTableColumns={expandedTableColumns}
                sDate={
                  timeFilter === "all" ? undefined : getStartDate(timeFilter)
                }
                eDate={
                  timeFilter === "all"
                    ? undefined
                    : moment(referenceDate).format("YYYY-MM-DD")
                }
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default OrderStatus;
