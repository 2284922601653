import React from "react";
import GridCellExpand from "./GridCellExpand";

const isNumber = (val) => {
  return !isNaN(Number(val));
};

export default function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={
        params.value
          ? Array.isArray(params.value)
            ? params.value.join(", ")
            : params.value.toString()
          : isNumber(params.value)
          ? String(params.value)
          : ""
      }
      width={params.colDef.width}
    />
  );
}
