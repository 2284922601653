import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Grid,
  Typography,
  Divider,
  Button,
} from "@material-ui/core";
import CardSwiper from "../../CardsCarousel/cardsCarousel";
import InspectionCard from "../../InspectionCard/inspectionCard";
import moment from "moment";
import CachedIcon from "@material-ui/icons/Cached";
import {
  healthIcon,
  personCardIcon,
  emissionsIcon,
  safetyIcon,
  surveyIcon,
  waterIcon,
  payrollIcon,
} from "../../../assets/svgIconList";
import AverageView from "../../UI/AverageView";
import BOMOptions from "../../BOM/BOMOptions";
import {
  filterByBom,
  formatCurrentInspection,
  isPending,
} from "../../../dataTransformers/utils";
import { fetchInspections } from "../../../data/fetchResources";

const referenceDate = localStorage.getItem("referenceDate");

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
}));

const formatDate = (date) => {
  let format = date.trim() ? moment(date).format("Do MMMM YYYY") : "";

  return format;
};

const getOrderDetailsFromLocalStorage = async () => {
  return JSON.parse(window.localStorage.getItem("siteInspection"));
};

const getOrder = (item) => {
  //item = formatCurrentInspection(item);
  let certified = item[15] ? "Yes" : "No";
  // let approved = certified === "Yes" || Math.random() > 0.2 ? "Yes" : "No";
  // let documents = Math.random() > 0.2 ? "Yes" : "No";
  const sortId = moment(item[8], "MM/DD/YY").isValid()
    ? moment(item[8], "MM/DD/YY").format("YYYY-MM-DD")
    : item[8];

  return {
    sortId: sortId,
    one: item[0],
    two: item[1],
    three: item[2],
    four: item[4],
    five: item[5],
    six: item[6],
    seven: formatDate(item[7]),
    eight: formatDate(item[8]),
    nine: item[9],
    ten: item[10],
    eleven: item[11],
    twelve: formatDate(item[12]),
    thirteen: item[13],
    fourteen: formatDate(item[14]),
    fifteen: certified,
    // sixteen: approved,
    // seventeen: documents,
  };
};

const getIcon = (type) => {
  switch (type) {
    case "Health Inspections":
      return healthIcon;
    case "Labor/Benefit Review":
      return personCardIcon;
    case "Safety Inspections":
      return safetyIcon;
    case "Emissions Test":
      return emissionsIcon;
    case "Management Attitude Survey":
      return surveyIcon;
    case "Water Treatment Test":
      return waterIcon;
    case "Equal Pay":
      return payrollIcon;
    case "Employee Diversity Audit":
      return personCardIcon;
    case "Security Audit":
      return safetyIcon;
    case "Age Appropriate Labor":
      return personCardIcon;
    default:
      return surveyIcon;
  }
};
const getOrderDetails = (allOrders, timeFilter) => {
  let orders = allOrders.map(formatCurrentInspection);
  if (!orders) {
    return {};
  }

  const refDate = moment(referenceDate).unix();
  const quaterBefore = moment(referenceDate).subtract(3, "months").unix();
  const monthBefore = moment(referenceDate).subtract(1, "months").unix();
  const oneWeekBefore = moment(referenceDate).subtract(1, "weeks").unix();
  const yearBefore = moment(referenceDate).subtract(1, "years").unix();
  //const header = orders.shift();

  let types = {
    "QMS - ISO9001:2015": { passed: 0, failed: 0, pending: 0 },
    "QMS - AS9100": { passed: 0, failed: 0, pending: 0 },
    "DDTC Registration": { passed: 0, failed: 0, pending: 0 },
    "ITAR Compliant": { passed: 0, failed: 0, pending: 0 },
    "SAM.gov Registration": { passed: 0, failed: 0, pending: 0 },
    "CMMC Certified": { passed: 0, failed: 0, pending: 0 },
    "NIST 800-171 Compliant": { passed: 0, failed: 0, pending: 0 },
    "NADCAP Accreditation": { passed: 0, failed: 0, pending: 0 },
    "Form 889": { passed: 0, failed: 0, pending: 0 },
    // "Company Profile": { passed: 0, failed: 0, pending: 0 },
    // "Cyber-Security Assessment": { passed: 0, failed: 0, pending: 0 },
    // QMS: { passed: 0, failed: 0, pending: 0 },
    // "Risk Assessment": { passed: 0, failed: 0, pending: 0 },
  };

  let totalCount = 0;
  let passedCount = 0;
  let failedCount = 0;
  let pendingCount = 0;

  let totalArray = [];
  let passedArray = [];
  let failedArray = [];
  let pendingArray = [];

  const getUnixItemDate = (date) => {
    return moment(date).unix();
  };

  switch (timeFilter) {
    case "month":
      orders = orders.filter(
        (item) =>
          getUnixItemDate(item[8]) >= monthBefore &&
          getUnixItemDate(item[8]) <= refDate
      );
      break;
    case "year":
      orders = orders.filter(
        (item) =>
          getUnixItemDate(item[8]) >= yearBefore &&
          getUnixItemDate(item[8]) <= refDate
      );
      break;
    case "week":
      orders = orders.filter(
        (item) =>
          getUnixItemDate(item[8]) >= oneWeekBefore &&
          getUnixItemDate(item[8]) <= refDate
      );
      break;
    case "quarter":
      orders = orders.filter(
        (item) =>
          getUnixItemDate(item[8]) >= quaterBefore &&
          getUnixItemDate(item[8]) <= refDate
      );
      break;
  }

  orders.forEach((item) => {
    switch (item[10]) {
      case "pass":
        if (types[`${item[1]}`]) {
          types[`${item[1]}`].passed++;
        }
        passedCount++;
        passedArray.push(getOrder(item));
        break;
      case "fail":
        if (types[`${item[1]}`]) {
          types[`${item[1]}`].failed++;
        }
        failedCount++;
        failedArray.push(getOrder(item));
        break;
      default:
        break;
    }

    if (isPending(item)) {
      if (types[`${item[1]}`]) {
        types[`${item[1]}`].pending++;
      }
      pendingCount++;
      pendingArray.push(getOrder(item));
    }

    totalCount++;
    totalArray.push(getOrder(item));
  });

  const result = {
    // header,
    totalCount,
    passedCount,
    failedCount,
    pendingCount,
    totalArray,
    passedArray,
    failedArray,
    pendingArray,
    types,
  };

  return result;
};

const columnNames = [
  {
    Header: "Supplier",
    accessor: "one",
  },
  {
    Header: "Inspection/Audit Type",
    accessor: "two",
    setFlag: true,
  },
  {
    Header: "Associated Claims/Requirement",
    accessor: "three",
  },
  {
    Header: "Evidence",
    accessor: "four",
    Cell: (row) => {
      let val = row.cell.value;
      if (!val) return null;
      if (!Array.isArray(val)) {
        val = [val];
      }
      const evidencecs = (val || []).map((evi, index) => {
        let fileName = evi.name || "file";
        const extnArr = evi.url && evi.url.split(".");
        fileName = `${fileName}.${extnArr[extnArr.length - 1]}`;
        return (
          <>
            <a
              target="_blank"
              className="link"
              download={fileName}
              href={evi.url}
            >
              {evi.name}
              {index < val.length - 1 ? "," : ""}
            </a>{" "}
          </>
        );
      });
      return evidencecs;
    },
  },
  {
    Header: "Evidence Level",
    accessor: "five",
    className: "highlight",
  },
  {
    Header: "Scope",
    accessor: "six",
    className: "highlight",
  },
  {
    Header: "Date Initiated",
    accessor: "seven",
    className: "highlight",
  },
  {
    Header: "Date Completed",
    accessor: "eight",
    className: "highlight",
  },
  {
    Header: "Brand Score(result)",
    accessor: "nine",
  },
  // {
  //   Header: "FDA/FSIS Approved",
  //   accessor: "fifteen",
  // },
  // {
  //   Header: "Documents",
  //   accessor: "seventeen",
  // },
  {
    Header: "Status",
    accessor: "ten",
  },
  {
    Header: "Certified",
    accessor: "fifteen",
  },
  {
    Header: "Coverage Days",
    accessor: "eleven",
  },
  {
    Header: "Valid Until",
    accessor: "twelve",
  },
  {
    Header: "Warning Window(days)",
    accessor: "thirteen",
  },
  {
    Header: "Expiration Warning",
    accessor: "fourteen",
  },
];

const getTypeCard = (title, item, selectedFilter, setSelectedFilter) => {
  const setInspectionFilter = () => {
    if (selectedFilter === title) {
      setSelectedFilter("");
    } else {
      setSelectedFilter(title);
    }
  };
  return (
    <Grid item xs={6} className="grid-item" key={title}>
      <InspectionCard
        title={title}
        total={`${item.passed + item.failed + item.pending}`}
        passed={`${item.passed}`}
        failed={`${item.failed}`}
        warningText={
          item.pending > 0 ? `! ${item.pending} pending expirations` : ""
        }
        icon={getIcon(title)}
        onClick={setInspectionFilter}
        showClear={title === selectedFilter}
      />
    </Grid>
  );
};

const SiteInspection = (props) => {
  const {
    drawerOpen,
    timeFilter,
    handleCardClick,
    selectedBOM,
    data: savedInspections,
    triggerUpdate,
    location,
    unfilteredData
  } = props;
  // const localInspections = JSON.parse(localStorage.getItem("inspections"));
  // const [inspections, setInspections] = React.useState(savedInspections);
  const [data, setData] = useState({});
  const loadingPlaceholder = "...";
  const classes = useStyles();
  const [slidesPerView, setslidesPerView] = React.useState(() =>
    drawerOpen ? 4 : 0
  );
  const [inspectionCardsData, setInspectionCardsData] = useState({});

  const applyBOMFilter = (inspections) => {
    return filterByBom(inspections, selectedBOM);
  };

  const formatInspectionsData = () => {
    const allInspections = applyBOMFilter(savedInspections);
    const unfilteredInspections = applyBOMFilter(unfilteredData)
    setInspectionCardsData(getOrderDetails(unfilteredInspections, timeFilter));
    setData(getOrderDetails(allInspections, timeFilter));
    // const inspectionsList = inspectionsData || [];
    // if (timeFilter) {
    //   let allOrders = (savedInspections || []).concat(inspectionsList || []);
    //   allOrders = applyBOMFilter(allOrders);
    //   setData(getOrderDetails(allOrders, timeFilter));
    // }
  };
  
  React.useEffect(() => {
    props.setSelectedInspection("");
  }, [timeFilter, selectedBOM]);

  React.useEffect(() => {
    formatInspectionsData();
  }, [timeFilter, selectedBOM, savedInspections]);

  if (drawerOpen) {
    setTimeout(() => {
      setslidesPerView(4);
    }, 800);
  } else {
    setTimeout(() => {
      setslidesPerView(1);
    }, 800);
  }

  let grids = [];

  inspectionCardsData &&
    inspectionCardsData.types &&
    Object.keys(inspectionCardsData.types).forEach((item) => {
      if (
        inspectionCardsData.types[item] &&
        inspectionCardsData.types[item].passed +
          inspectionCardsData.types[item].failed +
          inspectionCardsData.types[item].pending >
          0
      ) {
        grids.push(
          getTypeCard(
            item,
            inspectionCardsData.types[item],
            props.selectedInspection,
            props.setSelectedInspection
          )
        );
      }
    });

  return (
    <Box className={classes.stepper}>
      <Grid
        container
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <Grid item style={{ width: "50%" }}>
          <Typography variant="h4" className={classes.sectionHeading}>
            Showing data for {location}
            <Button
              onClick={() => {
                fetchInspections(true).then(() => {
                  triggerUpdate();
                });
              }}
            >
              <CachedIcon />
            </Button>
          </Typography>
        </Grid>
        <Grid item style={{ width: "50%", textAlign: "right" }}>
          <BOMOptions
            label="Select BOM"
            value={props.selectedBOM}
            handleChange={props.BOMChnage}
          />
        </Grid>
      </Grid>
      <Typography variant="h4" className={classes.sectionHeading}></Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        {data && (
          <AverageView
            title={data.totalCount ? data.totalCount : 0}
            description="Total Inspections"
            color="#7FA0EA"
          />
        )}
        {/* <SplineChart />
        <span className="ticker-percentage">
          10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
        </span> */}
      </Box>
      <CardSwiper
        disable={true}
        columns={columnNames}
        timeFilter={timeFilter}
        handleCardClick={handleCardClick}
        sideBarHeader={"Site Inspection"}
        data={[
          {
            header: `${
              data && data.totalCount ? data.totalCount : 0
            } Total Certifications`,
            title: `${
              data && data.totalCount >= 0
                ? data.totalCount
                : loadingPlaceholder
            }`,
            subtitle: "Total Certifications",
            disable: !(data && data.totalCount > 0),
            alertIcon: false,
            rows: data && data.totalArray ? data.totalArray : [],
          },
          {
            header: `${
              data && data.passedCount ? data.passedCount : 0
            } Active/Passed`,
            title: `${
              data && data.passedCount >= 0
                ? data.passedCount
                : loadingPlaceholder
            }`,
            subtitle: "Active/Passed",
            disable: !(data && data.passedCount > 0),
            alertIcon: false,
            rows: data && data.passedArray ? data.passedArray : [],
          },
          {
            header: `${
              data && data.failedCount ? data.failedCount : 0
            } Failed/Expired`,
            title: `${
              data && data.failedCount >= 0
                ? data.failedCount
                : loadingPlaceholder
            }`,
            subtitle: "Failed/Expired",
            disable: !(data && data.failedCount > 0),
            alertIcon: false,
            rows: data && data.failedArray ? data.failedArray : [],
          },
          {
            header: `${
              data && data.pendingCount ? data.pendingCount : 0
            } Pending Expiration`,
            title: `${
              data && data.pendingCount >= 0
                ? data.pendingCount
                : loadingPlaceholder
            }`,
            subtitle: "Pending Expiration",
            disable: !(data && data.pendingCount > 0),
            alertIcon: true,
            rows: [],
          },
          // {
          //   header: `${
          //     data && data.totalCount ? data.totalCount : 0
          //   } Site Events`,
          //   title: `${
          //     data && data.totalCount >= 0
          //       ? Number.parseInt(data.totalCount * 0.8)
          //       : loadingPlaceholder
          //   }`,
          //   subtitle: "Site Events",
          //   disable: !(data && data.totalCount > 0),
          //   alertIcon: false,
          //   rows: [],
          // },
          // {
          //   header: `${
          //     data && data.totalCount ? data.totalCount : 0
          //   } Product Events`,
          //   title: `${
          //     data && data.totalCount >= 0
          //       ? Number.parseInt(data.totalCount * 0.9)
          //       : loadingPlaceholder
          //   }`,
          //   subtitle: "Product Events",
          //   disable: !(data && data.totalCount > 0),
          //   alertIcon: false,
          //   rows: [],
          // },
        ]}
        slidesPerView={slidesPerView}
      />
      <br />
      <Divider className="divider" />
      <br />
      {/* <Grid container spacing={2}>
        <Grid item md={4}>
          Evidence Level
        </Grid>
        <Grid item md={8}>
          <Slider
            defaultValue={3}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            marks
            min={1}
            max={7}
          />
        </Grid>
      </Grid> */}
      <br />
      <Box p={"1rem 0"}>
        <Grid container spacing={2}>
          {grids}
        </Grid>
      </Box>
    </Box>
  );
};

export default SiteInspection;
