import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, Box, makeStyles } from "@material-ui/core";
import Sidebar from "./Sidebar/sidebar";
import Header from "../Header/header";
import { generateOrderActivity } from "dataTransformers/orderActivity";
import { generateProvonomicsMapData } from "dataTransformers/provonomicsMap";
import { generateOrderStatus } from "dataTransformers/orderStatus";
import { generateSiteInspection } from "dataTransformers/siteInspection";
import managers from "data/managers";
import BubbleMap from "./Charts/BubbleMap";
import { filterByBom, filterByInspection, filterByManager } from "../../dataTransformers/utils";
import PieMap from "./Charts/PieMap";
import { generateSupplierMap } from "dataTransformers/supplierListMap";
import { fetchAllResourcesSilent } from "../../data/fetchResources";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    position: "relative",
    height: " 100vh",
    order: "0",
  },

  main: {
    width: "100%",
    overflow: "auto",
    position: "absolute",
    top: "0",
    left: "0",
    height: "100vh",
    padding: "1rem",
  },
  chart: {
    marginLeft: "50px",
    marginTop: "150px",
  },
}));

const MainPageComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const [selectedCard, setSelectedCard] = React.useState("Submitted");
  const [selectedPoolCard, setSelectedPoolCard] = React.useState("Total POs");
  const [selectedinspectionCard, setSelectedinspectionCard] = React.useState(
    "Total Certifications"
  );
  const [selectedSection, setSelectedSection] = React.useState("orders");
  const [selectedStep, setSelectedStep] = React.useState(0);
  const [selectedManager, setSelectedManager] = React.useState("All");
  const [selectedBOM, setSelectedBOM] = React.useState("All");
  const [mapRefreshing, setMapRefreshing] = useState(false);
  const [lastRefresh, setLastRefresh] = useState(new Date());
  const [selectedInspection, setSelectedInspection] = useState("");

  useEffect(() => {
    const redirect = JSON.parse(localStorage.getItem("landingRedirect"));
    if (redirect && redirect.step) {
      setSelectedStep(redirect.step);
    }
    localStorage.removeItem("landingRedirect");
  }, []);

  useEffect(() => {
    fetchAllResourcesSilent().then(() => {
      setLastRefresh(new Date());
    });
  }, []);

  const BOMChnage = ({ target: { value } }) => {
    setSelectedBOM(value);
    setSelectedManager("All");
  };

  const [orders, setOrders] = React.useState(
    JSON.parse(localStorage.getItem("ordersData"))
  );

  const [timeFilter, setTimeFilter] = React.useState(
    localStorage.getItem("selectedTimeFilter")
  );
  const [filtered, setFiltered] = useState({
    filter: false,
    data: [],
    location: "",
  });

  const applyFilter = (data) => {
    setMapRefreshing(true);
    setTimeout(() => {
      setFiltered(data);
      setMapRefreshing(false);
    }, 100);
  };

  const triggerUpdate = () => {
    clearFiltered();
    setLastRefresh(new Date());
  };

  const timeFilterChange = (val) => {
    clearFiltered();
    setTimeFilter(val);
  };

  const handleSelectedManager = (manager) => {
    setSelectedManager(manager);
    // let ordersClone = JSON.parse(localStorage.getItem("ordersData"));
    // if (manager !== "All") {
    //   ordersClone = ordersClone.filter((order) =>
    //     managers[manager]?.includes(order.supplier)
    //   );
    // }
    // setOrders(ordersClone);
  };

  const applyBOMFilter = (masterOrder) => {
    return filterByBom(masterOrder, selectedBOM);
  };

  const applyManagerFilter = (masterOrder) => {
    if (selectedStep === 4 || selectedStep === 3) {
      return masterOrder;
    }
    return filterByManager(masterOrder, selectedManager);
  };

  const applyInspectionFilter = (masterOrder) => {
    if (selectedStep !== 3) {
      return masterOrder;
    }
    const result = filterByInspection(masterOrder, selectedInspection);
    return result;
  };

  const [filteredPools, setFilteredPools] = useState({
    filter: false,
    data: [],
    location: "",
  });

  const handleCardClick = (card, section, step) => {
    switch (selectedStep) {
      case 0:
        setSelectedCard(card);
        setSelectedSection(section);
        break;
      case 1:
        setSelectedCard(card);
        setSelectedSection(section);
        break;
      case 2:
        setSelectedPoolCard(card);
        setSelectedSection(section);
        break;
      case 3:
        setSelectedinspectionCard(card);
        break;
    }
  };

  const clearFiltered = () => {
    setFiltered({
      filter: false,
      data: [],
      location: "",
    });
  };

  const handleStepperChange = (step) => {
    clearFiltered();
    setSelectedStep(step);
  };
  if (!token) {
    history.push("/terms");
    return "";
  }

  const getData = () => {
    if (selectedStep === 3 && !filtered.filter) {
      return [
        ...JSON.parse(localStorage.getItem("siteInspection"), "[]"),
        ...JSON.parse(localStorage.getItem("inspections") || "[]"),
      ];
    }
    if (selectedStep === 4 && !filtered.filter) {
      return JSON.parse(localStorage.getItem("supplierList") || "[]");
    }
    return filtered.filter ? filtered.data : orders;
  };

  const getLocation = () => {
    return filtered.filter ? filtered.location : "all Regions";
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        <Sidebar
          handleCardClick={handleCardClick}
          handleStepperChange={handleStepperChange}
          activeStep={selectedStep}
          handleManagerChange={handleSelectedManager}
          orders={applyInspectionFilter(
            applyManagerFilter(applyBOMFilter(getData()))
          )}
          BOMChnage={BOMChnage}
          triggerUpdate={triggerUpdate}
          lastRefresh={lastRefresh}
          selectedBOM={selectedBOM}
          location={getLocation()}
          cxo={true}
          setMainTimeFilter={timeFilterChange}
          selectedManager={selectedManager}
          setSelectedManager={setSelectedManager}
          selectedInspection={selectedInspection}
          setSelectedInspection={setSelectedInspection}
          unfilteredOrders={applyManagerFilter(applyBOMFilter(getData()))}
        />
        <Container
          className={"mainContainer " + classes.content}
          maxWidth={"lg"}
        >
          <Box className={classes.main}>
            <Header />
          </Box>

          <Box className={classes.chart}>
            {selectedStep === 0 && (
              <BubbleMap
                data={generateOrderActivity(
                  applyManagerFilter(
                    applyBOMFilter(filtered.filter ? filtered.data : orders)
                  ),
                  {
                    orderStatus: selectedCard,
                    chartType: selectedSection,
                    setFiltered: applyFilter,
                    location: filtered.location,
                    timeFilter: localStorage.getItem("selectedTimeFilter"),
                    filtered: filtered.filter,
                  }
                )}
                config={{ showWarning: selectedCard === "Lag Warning" }}
              />
            )}
            {selectedStep === 1 && (
              <PieMap
                data={
                  mapRefreshing
                    ? []
                    : generateOrderStatus(
                        applyManagerFilter(
                          applyBOMFilter(
                            filtered.filter ? filtered.data : orders
                          )
                        ),
                        {
                          timeFilter:
                            localStorage.getItem("selectedTimeFilter"),
                          setFiltered: applyFilter,
                          filtered: filtered.filter,
                        }
                      )
                }
                config={{}}
              />
            )}
            {selectedStep === 2 && (
              <BubbleMap
                data={
                  mapRefreshing
                    ? []
                    : generateProvonomicsMapData(
                        applyManagerFilter(
                          applyBOMFilter(
                            filtered.filter ? filtered.data : orders
                          )
                        ),
                        {
                          orderStatus: selectedPoolCard,
                          timeFilter:
                            localStorage.getItem("selectedTimeFilter"),
                          chartType: selectedSection,
                          setFilteredPools: applyFilter,
                          location: filtered.location,
                          filtered: filtered.filter,
                        }
                      )
                }
                config={{ showSupplier: true }}
              />
            )}
            {selectedStep === 3 && (
              <PieMap
                data={
                  mapRefreshing
                    ? []
                    : generateSiteInspection(
                        applyInspectionFilter(
                          applyBOMFilter(
                            filtered.filter ? filtered.data : getData()
                          )
                        ),
                        {
                          setFiltered: applyFilter,
                          location: filtered.location,
                          status: selectedinspectionCard,
                          timeFilter:
                            localStorage.getItem("selectedTimeFilter"),
                          filtered: filtered.filter,
                        },
                        selectedBOM
                      )
                }
                config={{
                  inspection: true,
                  // showWarning: selectedinspectionCard === "Pending Expiration",
                }}
              />
            )}
            {selectedStep === 4 && (
              <PieMap
                data={
                  mapRefreshing
                    ? []
                    : generateSupplierMap(getData(), {
                        setFiltered: applyFilter,
                        location: filtered.location,
                        filtered: filtered.filter,
                      })
                }
                config={{ supplier: true }}
              />
            )}
          </Box>
        </Container>
      </Box>
    </React.Fragment>
  );
};
export default MainPageComponent;
