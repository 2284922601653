import React, { useState } from "react";
import {
  useTable,
  useGroupBy,
  useFilters,
  useSortBy,
  useExpanded,
  ReactTable,
  usePagination,
} from "react-table";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles, Box, Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import SplineChart from "components/MainPageComponent/Charts/SplineChart";

const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    maxHeight: "calc(100vh - 11rem)",
    overflow: "auto",
  },
  tablestyle: {
    maxWidth: "100%",
    overflow: "auto",
    "& table": {
      width: "100%",
      borderSpacing: "0 5px",
    },
    "& .info": {
      color: "#dd1717",
      display: "flex",
      alignItems: "center",
      "& svg": {
        fontSize: "1rem",
        marginRight: "0.5rem",
      },
    },
    "& th": {
      fontSize: "0.75rem",
      color: theme.palette.secondary.light,
      padding: "0.5rem ",
      fontWeight: "500",
      textAlign: "left",
      background: "#F1F3F4",
      "&.highlight": {
        background: "hsla(264, 79%, 92%, 0.43)",
        position: "relative",
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          background: "#F1F3F4",
          zIndex: "-1",
        },
      },
    },
    "& td": {
      fontSize: "0.75rem",
      color: theme.palette.secondary.main,
      padding: "0.75rem 0.5rem",
      background: "#FAFAFA",
      "&.highlight": {
        background: "transparent",
        position: "relative",
        zIndex: "1",
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          background: "hsla(264, 79%, 92%, 0.43)",
          zIndex: "-1",
        },
        "&:before": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          left: "0",
          top: "0",
          background: "#FAFAFA",
          zIndex: "-2",
        },
      },
    },
    "& .selected td": {
      background: "#FFE8E8",
      "&.highlight:before": {
        background: "#FFE8E8",
      },
    },
    "& .subTable": {
      padding: "0",
      "& table": {
        width: "100%",
        borderSpacing: "0",
      },
      "& th": {
        background: "#F1F3F4",
        "&.highlight": {
          background: "#F1F3F4",
          "&:after": {
            background: "#F1F3F4",
          },
        },
      },
      "& td": {
        background: "#FAFAFA",
        alignItems: "center",
        "&.highlight": {
          background: "inherit",
          "&:after": {
            background: "inherit",
          },
        },
      },
    },
  },
}));

function Table({ columns, data, renderRowSubComponent }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
    state: { expanded },
  } = useTable(
    {
      columns,
      data,
    },
    useExpanded
  );

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <>
                <th {...column.getHeaderProps()} className={column.className}>
                  {column.render("Header")}
                </th>
              </>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          let showinfoIcon = false;
          let t;
          row.cells.map((cell) => {
            if (cell.column.Header == "Ack Status") {
              if (
                cell.value === "unconfirmed" ||
                cell.value === "unconfirmedLag"
              ) {
                t = true;
              } else {
                t = false;
              }
            } else if (cell.column.Header == "Updated SO QTY") {
              if (cell.value !== "---") {
                t = true;
              } else {
                t = false;
              }
            }
          });
          return (
            <>
              <tr className={t ? "selected" : ""}>
                {row.cells.map((cell) => {
                  if (cell.column.Header == "Ack." && t == true) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">
                          <InfoOutlinedIcon fontSize="small" />{" "}
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  }

                  if (
                    cell.column.Header == "SO Confirmed Date" &&
                    row.original.show == true
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">
                          <InfoOutlinedIcon fontSize="small" />{" "}
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  }

                  if (
                    cell.column.Header == "SO Promise Date" &&
                    row.original.dateWarning == true
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">
                          <InfoOutlinedIcon fontSize="small" />{" "}
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  }

                  if (
                    cell.column.Header == "SO Total Qty" &&
                    row.original.soWarning == true
                  ) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">
                          <InfoOutlinedIcon fontSize="small" />{" "}
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  }

                  if (cell.column.Header == "Updated SO QTY" && t == true) {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">{cell.render("Cell")}</span>
                      </td>
                    );
                  }
                  if (cell.column.Header == "Status" && cell.value === "fail") {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className={cell.column.className}
                      >
                        <span className="info">
                          <InfoOutlinedIcon fontSize="small" />
                          {cell.render("Cell")}
                        </span>
                      </td>
                    );
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      className={cell.column.className}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
              {row.isExpanded ? (
                <tr>
                  <td colSpan={visibleColumns.length} className="subTable">
                    {}
                    {renderRowSubComponent({ row })}
                  </td>
                </tr>
              ) : null}
            </>
          );
        })}
      </tbody>
    </table>
  );
}

const Picker = ({ date, classes, label, onDateChange }) => {
  const [currentDate, setCurrentDate] = useState(date);

  React.useEffect(() => {
    setCurrentDate(date);
  }, [date]);

  return (
    <TextField
      id="date"
      label={label}
      type="date"
      value={currentDate}
      formatDate={(date) => moment(new Date()).format("MM-DD-YYYY")}
      className="datepicker"
      onChange={(e) => onDateChange(e)}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const getData = (rows, startDate, endDate) => {
  let rowsClone = JSON.parse(JSON.stringify(rows));

  if (startDate) {
    const start = moment(startDate, "YYYY-MM-DD");
    rowsClone = rowsClone.filter(
      (item) => moment(item.sortId, "YYYY-MM-DD") >= start
    );
  }
  if (endDate) {
    const end = moment(endDate, "YYYY-MM-DD");
    rowsClone = rowsClone.filter(
      (item) => moment(item.sortId, "YYYY-MM-DD") <= end
    );
  }
  return rowsClone.slice(0, 500);
};
const CustomTable = ({
  rows,
  columns,
  title,
  startCalendarDate,
  endCalendarDate,
  expandedTableColumns,
  showSpline,
  splineData,
  showPicker = true,
  sDate,
  eDate,
}) => {
  const classes = useStyles();
  // const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
  // const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);

  React.useEffect(() => {
    setStartDate(sDate);
  }, [sDate]);

  React.useEffect(() => {
    setEndDate(eDate);
  }, [eDate]);

  const CustomSubTable = (props) => {
    const { row } = props;
    return (
      <Box>
        <Table columns={expandedTableColumns} data={row.original.details} />
      </Box>
    );
  };

  return (
    <Box className={classes.tablestyle} display="flex" flexDirection="column">
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h5" className={classes.subtitle}>
            {title}
          </Typography>
          {showSpline && (
            <Box width={300}>
              <SplineChart data={splineData} />
            </Box>
          )}
        </Box>
        {showPicker && (
          <Box>
            <Picker
              date={startDate ? startDate : "mm/dd/yyyy"}
              classes
              label="From"
              onDateChange={(e) => setStartDate(e.target.value)}
            />
            <Picker
              date={endDate ? endDate : "mm/dd/yyyy"}
              classes
              label="To"
              onDateChange={(e) => setEndDate(e.target.value)}
            />
          </Box>
        )}
      </Box>
      {rows && columns && (
        <Box className={classes.tableWrapper}>
          <Table
            className={classes.tablestyle}
            columns={columns ? columns : columns1}
            data={getData(rows, startDate, endDate)}
            renderRowSubComponent={CustomSubTable}
          />
        </Box>
      )}
    </Box>
  );
};

export default CustomTable;

const columns = [
  {
    Header: "Supplier",
    accessor: "supplier",
  },
  {
    Header: "Type of Inspecction",
    accessor: "inspectionType",
  },
  {
    Header: "Score",
    accessor: "score",
  },
  {
    Header: "Passed/Fail",
    accessor: "status",
    className: "highlight",
  },
  {
    Header: "Date Completed",
    accessor: "completedDate",
    className: "highlight",
  },
  {
    Header: "Valid Until",
    accessor: "validUntil",
  },
  {
    Header: "Internal/External",
    accessor: "internal",
  },
  {
    Header: "Expiration Date",
    accessor: "expirationDate",
  },
];

const data = [
  {
    supplier: "AtoZ Wines",
    inspectionType: "Health",
    score: "75",
    status: "Passed",
    completedDate: "15 May 2020",
    validUntil: "15 May 2020",
    internal: "Internal",
    expirationDate: "15 May 2020",
  },
  {
    supplier: "Jacob Creek",
    inspectionType: "Safety",
    score: "75",
    status: "Failed",
    completedDate: "15 May 2020",
    validUntil: "15 May 2020",
    internal: "Internal",
    expirationDate: "15 May 2020",
  },
  {
    supplier: "AtoZ Wines",
    inspectionType: "Health",
    score: "75",
    status: "Passed",
    completedDate: "15 May 2020",
    validUntil: "15 May 2020",
    internal: "Internal",
    expirationDate: "15 May 2020",
  },
  {
    supplier: "Jacob Creek",
    inspectionType: "Safety",
    score: "75",
    status: "Failed",
    completedDate: "15 May 2020",
    validUntil: "15 May 2020",
    internal: "Internal",
    expirationDate: "15 May 2020",
  },
];

const columns1 = [
  {
    Header: () => null,
    id: "expander",
    Cell: ({ row }) => (
      <span
        {...row.getToggleRowExpandedProps()}
        style={{ display: "flex", cursor: "pointer" }}
      >
        {row.isExpanded ? (
          <RemoveCircleOutlineIcon
            fontSize="small"
            style={{ color: "#4896E9" }}
          />
        ) : (
          <AddCircleOutlineIcon fontSize="small" style={{ color: "#4896E9" }} />
        )}
      </span>
    ),
  },
  {
    Header: "Ack.",
    accessor: "ack",
  },
  {
    Header: "Ack Status",
    accessor: "ackStatus",
    setFlag: true,
  },
  {
    Header: "PO",
    accessor: "po",
  },
  {
    Header: "Supplier",
    accessor: "status",
  },
  {
    Header: "PO Created Date",
    accessor: "createdDate",
    className: "highlight",
  },
  {
    Header: "SO Confirmed Date",
    accessor: "confirmedDate",
    className: "highlight",
  },
  {
    Header: "PO Requested Date",
    accessor: "requestedDate",
    className: "highlight",
  },
  {
    Header: "SO Promise Date",
    accessor: "promiseDate",
    className: "highlight",
  },
  {
    Header: "PO Total Qty",
    accessor: "poTotalQty",
  },
  {
    Header: "SO Total Qty",
    accessor: "soTotalQty",
  },
  {
    Header: "PO Total $",
    accessor: "poTotal",
  },
  ,
  {
    Header: "SO Total $",
    accessor: "soTotal",
  },
];

const data1 = [
  {
    ack: "2d",
    ackStatus: "Confirmed",
    po: "1234",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "18 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "2500 lbs",
    poTotal: "$980",
    soTotal: "$980",
  },
  {
    ack: "1w",
    ackStatus: "Unconfirmed",
    po: "2345",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "18 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "2500 lbs",
    poTotal: "$20,000",
    soTotal: "$20,000",
    heightlight: true,
  },
  {
    ack: "12d",
    ackStatus: "Unconfirmed",
    po: "3456",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "15 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "1500 lbs",
    poTotal: "$980",
    soTotal: "$980",
    heightlight: true,
  },
  {
    ack: "2d",
    ackStatus: "Confirmed",
    po: "1234",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "18 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "2500 lbs",
    poTotal: "$980",
    soTotal: "$980",
  },
  {
    ack: "1w",
    ackStatus: "Confirmed",
    po: "2345",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "18 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "2500 lbs",
    poTotal: "$20,000",
    soTotal: "$20,000",
    heightlight: true,
  },
  {
    ack: "12d",
    ackStatus: "Unconfirmed",
    po: "3456",
    status: "AtoZ Wines",
    createdDate: "15 May 2020",
    confirmedDate: "15 May 2020",
    requestedDate: "9 Jun 2020",
    promiseDate: "9 Jun 2020",
    poTotalQty: "2500 lbs",
    soTotalQty: "1500 lbs",
    poTotal: "$980",
    soTotal: "$980",
    heightlight: true,
  },
];
