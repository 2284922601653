import React, { useState } from "react";
import {
  makeStyles,
  Button,
  Box,
  Grid,
  Typography,
  Divider,
  Link,
} from "@material-ui/core";
import moment from "moment";
import Tab from "@material-ui/core/Tab";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AspectRatioIcon from "@material-ui/icons/AspectRatio";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import CloseIcon from "@material-ui/icons/Close";
import "swiper/css/swiper.css";
import CustomTable from "../../UI/Table/table";
import { formatCurrency, numberWithCommas } from "common/utils";
import CustomizedDialog from "../../UI/Dialog";
import ImgProcess from "../../../assets/images/inprocess-hyperlink-msg.png";
import ImgRealtimeProcess from "../../../assets/images/inprocess-realtime-msg.png";
const drawerWidth = "100%";
const drawerMinWidth = 950;

const useStyles = makeStyles((theme) => ({
  OrderStatusClose: {
    "& .cardRight": {
      flexDirection: "column",
      alignItems: "center",
      "& .details": {
        width: "100%",
      },
    },
    "& .MuiGrid-container": {
      flexDirection: "column",
      alignItems: "center",
    },
    '& [class*="stdetails"]': {
      width: "100%",
      textAlign: "center",
    },
    '& [class*="stHeading"]': {
      fontSize: "1rem",
    },
  },

  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
  tabSectionHeading: {
    background: "#F1F3F4",
    color: theme.palette.secondary.dark,
    fontSize: "0.875rem",
    padding: "0.5rem",
  },
  tabPanelStyle: {
    padding: "1rem 0",
    background: "white",
  },
  innertabStyle: {
    "& .MuiTab-root": {
      fontSize: "0.875rem",
    },
  },
  cardStats: {
    padding: "1rem 0 0",
    whiteSpace: "normal",
    wordBreak: "break-all",
  },
  stChart: {
    width: "4rem",
    paddingTop: "0.5rem !important",
    textAlign: "center",
  },
  stdetails: {
    width: "calc(100% - 4rem)",
  },
  stHeading: {
    fontSize: "1.5rem",
    fontWeight: "700",
  },
  stStatus: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
  },
  stUpdated: {
    color: "#999999",
    fontSize: "0.75rem",
  },
  btn: {
    fontSize: "0.75rem",
    fontWeight: "normal",
    color: theme.palette.secondary.dark,
  },

  overrelay: {
    background: "rgba(0, 0, 0, 0.36)",
    position: "fixed",
    width: "100%",
    height: "100vh",
    zIndex: "-1",
  },
  tableDrawer: {
    width: "0",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxShadow: "0 0 1.5rem #ccc",
    position: "fixed",
    height: "100vh",
    overflow: "auto",
    right: "0",
    top: "0",
    zIndex: "9999",
    transition: "all 0.5s",

    "&.open": {
      width: drawerWidth,
    },
  },
  tableDrawerContainer: {
    width: drawerMinWidth,
    background: "#fff",
    marginLeft: "auto",
    height: "100%",
    padding: "2rem 3rem",
    position: "relative",
    "&.fullscreen": {
      width: "100%",
    },
  },
  closeTableDrawer: {
    position: "absolute",
    left: "1rem",
    top: "2.17rem",
  },
  tableDrawerTitle: {
    fontSize: "1rem",
    color: theme.palette.secondary.main,
  },
  tableWrapper: {
    // maxHeight: "calc(100vh - 8rem)",
    // overflow: "auto",
    marginTop: "1rem",
  },
  root: {
    display: "flex",
    background: "#fbfbfb",
    padding: "1rem 0",
    margin: "5px 0",
    borderBottom: "1px solid #E6E6E6",
    "&:last-child": {
      borderBottom: "none",
    },
    "&:first-child": {
      paddingTop: "0",
    },
    "& .cardLeft": {
      width: "2rem",
    },
    "& .details": {
      width: "calc(100% - 12rem)",
    },
    "& .cardRight": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      whiteSpace: "normal",
      wordBreak: "break-all",
      "& .cardStats": {
        width: "12rem",
      },
    },
    "& .green": {
      color: theme.palette.primary.green,
    },
    "& .red": {
      color: "#dd0101",
    },
    "& .yellow": {
      color: theme.palette.primary.yellow,
    },
  },
  borderBottom: {
    borderBottom: "1px solid #E6E6E6",
  },
  heading: {
    color: theme.palette.secondary.dark,
    paddingBottom: "0.5rem",
    fontSize: "0.875rem",
  },
  subheading: {
    color: theme.palette.secondary.light,
    fontSize: "0.875rem",
  },
}));

const extractValue = (row) => {
  return row.cell.value;
};

const yesNoRenderer = (row) => {
  let val = extractValue(row);
  val = val && val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  return (
    <span
      style={{ color: String(val).toUpperCase() === "NO" ? "red" : "green" }}
    >
      {val}
    </span>
  );
};

const statusRenderer = (row) => {
  let val = extractValue(row);
  val = val && val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  const color =
    String(val).toUpperCase() === "SUSPENDED"
      ? "red"
      : String(val).toUpperCase() === "EXPIRED"
      ? "#fe9228"
      : "green";
  return <span style={{ color: color }}>{val}</span>;
};

const riskRenderer = (row) => {
  let val = extractValue(row);
  val = val && val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  return renderRiskValue(val);
};

const renderRiskValue = (val) => {
  val = val && val.charAt(0).toUpperCase() + val.slice(1).toLowerCase();
  const color =
    String(val).toUpperCase() === "H"
      ? "red"
      : String(val).toUpperCase() === "M"
      ? "#fe9228"
      : "green";
  const value =
    String(val).toUpperCase()[0] === "H"
      ? "High"
      : String(val).toUpperCase()[0] === "M"
      ? "Medium"
      : "Low";
  return <span style={{ color: color }}>{value}</span>;
};

const alignmentIndexRenderer = (row) => {
  let val = extractValue(row);
  return (
    <span style={{ color: Number(val) < 0 ? "red" : "green" }}>{val}</span>
  );
};

const columns = [
  {
    Header: "Supplier",
    accessor: "supplierName",
  },
  {
    Header: "Full Address",
    accessor: "fullAddress",
  },
  {
    Header: "Region",
    accessor: "supplierCity",
  },
  {
    Header: "Country",
    accessor: "supplierCountry",
  },
  {
    Header: "QMS",
    accessor: "qms",
    Cell: yesNoRenderer,
    className: "highlight",
  },
  {
    Header: "QMS Certification",
    accessor: "QMSCertification",
    className: "highlight",
  },
  {
    Header: "DDTC Registration",
    accessor: "DDTCRegistration",
    Cell: yesNoRenderer,
  },
  {
    Header: "ITAR Compliant",
    accessor: "ITARCompliant",
    Cell: yesNoRenderer,
  },
  {
    Header: "SAM.gov Registration",
    accessor: "SAMgovRegistration",
    Cell: yesNoRenderer,
    className: "highlight",
  },
  {
    Header: "CAGE Code",
    accessor: "CAGECode",
    className: "highlight",
  },
  {
    Header: "Status",
    accessor: "Status",
    Cell: statusRenderer,
    className: "highlight",
  },
  {
    Header: "Form 889",
    accessor: "Form889",
    Cell: yesNoRenderer,
  },
  {
    Header: "CMMC",
    accessor: "CMMC",
    Cell: yesNoRenderer,
    className: "highlight",
  },
  {
    Header: "Level",
    accessor: "Level",
    className: "highlight",
  },
  {
    Header: "Score",
    accessor: "Score",
    className: "highlight",
  },
  {
    Header: "SPRS",
    accessor: "SPRS",
    Cell: yesNoRenderer,
  },
  {
    Header: "NADCAP Certified",
    accessor: "NADCAPCertified",
    Cell: yesNoRenderer,
  },
  {
    Header: "Approved",
    accessor: "APPROVED",
    Cell: yesNoRenderer,
    className: "highlight",
  },
  {
    Header: "Risk",
    accessor: "RISK",
    Cell: riskRenderer,
    className: "highlight",
  },
  {
    Header: "Alignment Index",
    accessor: "alignmentIndex",
    className: "highlight",
    Cell: alignmentIndexRenderer,
  },
];

const Suppliers = (props) => {
  const { drawerOpen, orders, location } = props;
  const [allSuppliers, setAllSuppliers] = React.useState([]);
  React.useEffect(() => {
    if (!props.data) return;
    const uniqueSuppliers = getUniqueSuppliers(props.data);
    setAllSuppliers(uniqueSuppliers);
  }, [props.data]);

  const getUniqueSuppliers = (allSuppliers) => {
    const uniqueSuppliers = [];
    if (!allSuppliers) return uniqueSuppliers;
    const addedSuppiers = {};
    for (let supplier of allSuppliers) {
      if (!addedSuppiers[supplier.supplierName]) {
        uniqueSuppliers.push(supplier);
        addedSuppiers[supplier.supplierName] = true;
      }
    }

    return uniqueSuppliers;
  };

  const classes = useStyles();
  const [open, setDrawerStatus] = React.useState(() =>
    drawerOpen ? true : false
  );
  const [fullscreen, setfullscreen] = React.useState(false);
  const [swiper, setSwiper] = React.useState({
    open: false,
    rows: [],
    header: "",
  });
  const [dialog, setDialog] = useState(false);
  const [realtimeDialog, setRealtimeDialog] = useState(false);

  if (drawerOpen) {
    setTimeout(() => {
      setDrawerStatus(true);
    }, 100);
  } else {
    setTimeout(() => {
      setDrawerStatus(false);
    }, 100);
  }

  const handleDrawerToggle = (r, h) => {
    if (swiper.open) {
      setSwiper({ open: false, rows: r ? r : [], header: h ? h : "" });
      setfullscreen(false);
    } else {
      setSwiper({ open: true, rows: r ? r : [], header: h ? h : "" });
    }
  };

  const handleDrawerAspectRatio = () => {
    setfullscreen(!fullscreen);
  };

  return (
    <Box className={open ? "" : classes.OrderStatusClose}>
      <CustomizedDialog
        img={ImgProcess}
        open={realtimeDialog}
        callback={setRealtimeDialog}
      />
      <CustomizedDialog
        img={ImgRealtimeProcess}
        open={dialog}
        callback={setDialog}
      />
      <Box className={classes.topSection}>
        <Grid
          container
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <Grid item style={{ width: "50%" }}>
            <Typography variant="h4" className={classes.sectionHeading}>
              Showing data for {location}
            </Typography>
          </Grid>
          <Grid item style={{ width: "50%", textAlign: "right" }}>
            {/* <BOMOptions
              label="Select BOM"
              value={props.selectedBOM}
              handleChange={props.BOMChnage}
            /> */}
          </Grid>
        </Grid>
      </Box>
      <Box component="div" className="tab-chart-container">
        <Link
          href="#"
          className="link"
          onClick={() => handleDrawerToggle(orders)}
        >
          More Details
          <ArrowForwardIosIcon style={{ fontSize: "0.55rem" }} />
        </Link>
        <div className={classes.borderBottom}></div>
        {(allSuppliers || []).slice(0, 100).map((supplier) => {
          return (
            <Box className={classes.root}>
              <Box className="cardRight">
                <Box className="details" style={{ padding: "0 10px" }}>
                  {supplier && (
                    <Box className={classes.heading}>
                      {supplier.supplierName}
                    </Box>
                  )}
                  <Box className={classes.subheading}>
                    City : {supplier.city} <br />
                    Country : {supplier.supplierCountry} <br />
                    Zip: {supplier.zip} <br />
                    Approved : {supplier.APPROVED}
                    <br />
                    Risk: {renderRiskValue(supplier.RISK)}
                    <br />
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
      {swiper.open && (
        <Box
          className={
            swiper.open
              ? "open " + classes.tableDrawer
              : "close " + classes.tableDrawer
          }
        >
          <Box
            className={classes.overrelay}
            onClick={() => handleDrawerToggle()}
          ></Box>
          <Box
            className={
              fullscreen
                ? "fullscreen " + classes.tableDrawerContainer
                : classes.tableDrawerContainer
            }
          >
            <CloseIcon
              fontSize="small"
              className={classes.closeTableDrawer}
              onClick={() => handleDrawerToggle()}
            />
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography variant="h2" className={classes.tableDrawerTitle}>
                  Deep Dive / Suppliers
                </Typography>
              </Grid>
              <Grid item>
                <AspectRatioIcon
                  fontSize="small"
                  className={classes.aspectRatioIcon}
                  onClick={handleDrawerAspectRatio}
                />
              </Grid>
            </Grid>
            <Box className={classes.tableWrapper}>
              <CustomTable
                rows={allSuppliers}
                showPicker={false}
                columns={columns ? columns : []}
                title="Suppliers"
                expandedTableColumns={[]}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Suppliers;
