import {
  aggregateData,
  flattenData,
  getFilteredData,
  flattenProvonomicsData,
} from "./utils";
import supplierCities from "data/supplierCities";
import { time } from "highcharts";
import moment from "moment";
const referenceDate = localStorage.getItem("referenceDate");

const colorMap = {
  spend: "#DD6717",
  orders: "#7FA0EA",
};
const poolStatusMap = {
  Acknowledged: "Acknowledged",
  "In Progress": "In Progress",
  Closed: "Closed",
};

export const generateProvonomicsMapData = (pools, filters) => {
  const { orderStatus, chartType, setFilteredPools, timeFilter, filtered } =
    filters;
  let data = pools;
  if (poolStatusMap[orderStatus]) {
    data = pools.filter(
      (order) => order.pool.IPStatus === poolStatusMap[orderStatus]
    );
  }
  data = getFilteredData(data, timeFilter);
  data = data.map((row) => {
    row.location = supplierCities[row.supplier];
    return row;
  });

  let flattenData = flattenProvonomicsData(data);

  let result = aggregateData(flattenData, "location", "z", "o");
  result = result.map((row) => {
    row.status = orderStatus;
    return row;
  });
  return [
    {
      type: "mapbubble",
      name: orderStatus,
      color: colorMap[chartType],
      data: result,
      cursor: "pointer",
      point: {
        events: {
          click: function (event) {
            let currData = data.find(
              (item) =>
                item.location && item.location.value === event.point.location
            );
            let currentLoc =
              currData && currData.location && currData.location.value;
            if (filtered) {
              setFilteredPools({ filter: false, data: [], location: "" });
            } else {
              setFilteredPools({
                filter: true,
                data: data.filter(
                  (item) =>
                    item.location &&
                    item.location.value === event.point.location
                ),
                location: currentLoc,
              });
            }
          },
        },
      },
    },
  ];
};
