import moment from "moment";
import {
  aggregateData,
  flattenDateDataAndAggrigate,
  sortDataByDate,
  flattenDateDataAndAggrigateBySpent,
  getFilteredData,
} from "./utils";
export const generateSplineData = (data, showSpentData, timeFilter) => {
  let dataClone = JSON.parse(JSON.stringify(data));
  if (timeFilter) dataClone = getFilteredData(dataClone, timeFilter);
  const aggeregatedData = showSpentData
    ? flattenDateDataAndAggrigateBySpent(dataClone)
    : flattenDateDataAndAggrigate(dataClone);
  return sortDataByDate(aggregateData(aggeregatedData, "x", "y", "o"), "x");
};

export const generateSplineDataForProvonomics = (
  data,
  showSpentData,
  timeFilter
) => {
  let dataClone = JSON.parse(JSON.stringify(data));
  if (timeFilter) dataClone = getFilteredData(dataClone, timeFilter);
  const aggeregatedData = showSpentData
    ? flattenDateDataAndAggrigateBySpent(dataClone)
    : flattenDateDataAndAggrigate(dataClone);
  return sortDataByDate(aggregateData(aggeregatedData, "x", "y", "o"), "x");
};
