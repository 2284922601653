import moment from "moment";
import supplierCities from "data/supplierCities";
import supplierAlignmentIndex from "data/supplierAlignmentIndex";
const referenceDate = localStorage.getItem("referenceDate");

export const aggregateData = (data, groupBy, sumTo, sumOf) => {
  const result = [];
  data.reduce(function (res, value) {
    if (!res[value[groupBy]]) {
      res[value[groupBy]] = {
        [groupBy]: value[groupBy],
        [sumTo]: 0,
        ...value,
      };
      result.push(res[value[groupBy]]);
    }
    res[value[groupBy]][sumTo] += value[sumOf];
    return res;
  }, {});
  return result;
};

const getPieImage = (filled) => {
  if (filled === 0)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/0.png)";
  if (filled >= 100)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/100.png)";
  if (filled >= 55 && filled < 100)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/70.png)";
  if (filled > 0 && filled < 45)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/10.png)";
  if (filled > 45 && filled < 55)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/50.png)";
};

const getInspectionImage = (passed, failed) => {
  if (passed > 0 && failed > 0)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/dot-mixed.png)";
  if (passed === 0 && failed > 0)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/dot-red.png)";
  if (passed > 0 && failed === 0)
    return "url(https://pcn-procurement-manager.s3-us-west-2.amazonaws.com/data/dot-green.png)";
};

const getWorstColor = (current = "green", incoming) => {
  if (!incoming) return "green";
  if (incoming === current) return current;
  if (incoming === "red") return incoming;
  if (incoming === "yellow" && current === "green") return incoming;
  return current;
};

const getWorstColorProps = (current = "green", incoming) => {
  const getDate = (item) => {
    const date = moment(item.endDate, "MM/DD/YY").isValid()
      ? moment(item.endDate, "MM/DD/YY").format("YYYY-MM-DD")
      : item.endDate;

    return date;
  };
  if (current.reviewLevel > incoming.reviewLevel)
    return {
      color: current.color,
      hasWarning: current.hasWarning,
      endDate: current.endDate,
    };
  if (current.reviewLevel === incoming.reviewLevel) {
    if (getDate(current) > getDate(incoming))
      return {
        color: current.color,
        hasWarning: current.hasWarning,
        endDate: current.endDate,
      };
    return {
      color: incoming.color,
      hasWarning: incoming.hasWarning,
      endDate: incoming.endDate,
    };
  }
  if (!incoming.color)
    return { color: "green", hasWarning: false, endDate: incoming.endDate };
  if (incoming.color === current.color)
    return {
      color: current.color,
      hasWarning: current.hasWarning,
      endDate: current.endDate,
    };
  if (incoming.color === "red")
    return {
      color: incoming.color,
      hasWarning: incoming.hasWarning,
      endDate: incoming.endDate,
    };
  if (incoming.color === "yellow" && current.color === "green")
    return {
      color: incoming.color,
      hasWarning: incoming.hasWarning,
      endDate: incoming.endDate,
    };
  return {
    color: incoming.color,
    hasWarning: incoming.hasWarning,
    endDate: incoming.endDate,
  };
};

export const aggregatePieData = (data, groupBy, sumTo, sumOf) => {
  let result = [];
  data.reduce(function (res, value) {
    if (!res[value[groupBy]]) {
      res[value[groupBy]] = {
        [groupBy]: value[groupBy],
        [sumTo]: 0,
        filledSum: 0,
        ...value,
      };
      result.push(res[value[groupBy]]);
    }
    res[value[groupBy]][sumTo] += value[sumOf];
    res[value[groupBy]].filledSum += value.filled;
    res[value[groupBy]].color = getWorstColor(
      res[value[groupBy]].color,
      value.color
    );
    return res;
  }, {});
  result = result.map((res) => {
    res.avgFilled = Number.parseInt(res.filledSum / res.z);
    res.marker = {
      symbol: `url(https://pcndev.blob.core.windows.net/public/${
        res.color || "green"
      }pin.png)`,
    };
    return res;
  });
  return result;
};

const attachMapPinColorProps = (groupValue, currentValue) => {
  const { color, hasWarning, endDate } = getWorstColorProps(
    groupValue,
    currentValue
  );
  groupValue.color = color;
  groupValue.hasWarning = hasWarning;
  groupValue.endDate = endDate;
};

export const aggregateInspectionData = (data, groupBy, sumTo, sumOf) => {
  let result = [];
  data.reduce(function (res, value) {
    if (!res[value[groupBy]]) {
      res[value[groupBy]] = {
        [groupBy]: value[groupBy],
        [sumTo]: 0,
        ...value,
        totalPassed: 0,
        totalFailed: 0,
        suppliers: {},
      };
      result.push(res[value[groupBy]]);
    }
    res[value[groupBy]][sumTo] += value[sumOf];
    const { supplier } = value;
    res[value[groupBy]].suppliers[supplier.replace(/[.]$/g, "").toUpperCase()] =
      supplier;
    res[value[groupBy]].totalPassed += value.passed;
    res[value[groupBy]].totalFailed += value.failed;
    attachMapPinColorProps(res[value[groupBy]], value);
    return res;
  }, {});
  result = result.map((res) => {
    res.marker = {
      symbol: `url(https://pcndev.blob.core.windows.net/public/${
        res.color || "green"
      }pin.png)`,
    };
    res.supplier = Object.values(res.suppliers || {}).join(",\n")
    res.dataLabels = {
      enabled: res.hasWarning,
    };
    return res;
  });
  return result;
};

export const getFilteredData = (orders, timeFilter) => {
  if (!orders) {
    return {};
  }
  const refDate = moment(referenceDate).unix();
  const quaterBefore = moment(referenceDate).subtract(3, "months").unix();
  const monthBefore = moment(referenceDate).subtract(1, "months").unix();
  const oneWeekBefore = moment(referenceDate).subtract(1, "weeks").unix();
  const yearBefore = moment(referenceDate).subtract(1, "years").unix();

  const getItemUnixTime = (item) => {
    return item.time && moment(item.time).unix();
  };

  switch (timeFilter) {
    case "all":
      return orders;
    case "month":
      return orders.filter(
        (item) =>
          getItemUnixTime(item) >= monthBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "year":
      return orders.filter(
        (item) =>
          getItemUnixTime(item) >= yearBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "week":
      return orders.filter(
        (item) =>
          getItemUnixTime(item) >= oneWeekBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "quarter":
      return orders.filter(
        (item) =>
          getItemUnixTime(item) >= quaterBefore &&
          getItemUnixTime(item) <= refDate
      );
  }
};

export const getFilteredInspectionData = (inspections, timeFilter) => {
  if (!inspections) {
    return {};
  }

  const refDate = moment(referenceDate).unix();
  const quaterBefore = moment(referenceDate).subtract(3, "months").unix();
  const monthBefore = moment(referenceDate).subtract(1, "months").unix();
  const oneWeekBefore = moment(referenceDate).subtract(1, "weeks").unix();
  const yearBefore = moment(referenceDate).subtract(1, "years").unix();

  const getItemUnixTime = (item) => {
    const date = moment(item.endDate, "MM/DD/YY").isValid()
      ? moment(item.endDate, "MM/DD/YY").format("YYYY-MM-DD")
      : item.endDate;
    return moment(date, "YYYY-MM-DD").unix();
  };

  switch (timeFilter) {
    case "all":
      return inspections;
    case "month":
      return inspections.filter(
        (item) =>
          getItemUnixTime(item) >= monthBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "year":
      return inspections.filter(
        (item) =>
          getItemUnixTime(item) >= yearBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "week":
      return inspections.filter(
        (item) =>
          getItemUnixTime(item) >= oneWeekBefore &&
          getItemUnixTime(item) <= refDate
      );
    case "quarter":
      return inspections.filter(
        (item) =>
          getItemUnixTime(item) >= quaterBefore &&
          getItemUnixTime(item) <= refDate
      );
  }
};

export const flattenData = (data, series) => {
  return data.map((row) => {
    return {
      o: series ? row.spent : 1,
      suffix: series ? "Spent" : "Orders",
      prefix: series ? "$" : "",
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      location: row.location ? row.location.value : "",
      state: row.location,
    };
  });
};

const orderColorMap = {
  "On Time": "green",
  Delayed: "red",
  "In Transit": "yellow",
};

const getOrderColor = (data) => {
  const needByDate = moment(data.needByDate);
  const refDate = moment(referenceDate);
  if (needByDate.add(3, "month") < refDate) {
    return "green";
  }
  const color = orderColorMap[data.deliveryStatus];
  return color;
};

export const flattenOrderStatusData = (data, series) => {
  return data.map((row) => {
    return {
      o: 1,
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      location: row.location ? row.location.value : "",
      state: row.location,
      color: getOrderColor(row),
      // marker: {
      //   symbol: `url(https://pcndev.blob.core.windows.net/public/${getOrderColor(
      //     row
      //   )}pin.png)`,
      // },
      filled: row.filled,
    };
  });
};

export const isPending = (item) => {
  return (
    item[10] !== "fail" &&
    moment(item[14], "YYYY-MM-DD") <= moment(referenceDate) &&
    moment(referenceDate) <= moment(item[12], "YYYY-MM-DD")
  );
};

const getInspectionColor = (row) => {
  // const formattted = formatCurrentInspection(row);
  //if (isPending(formattted)) return "yellow";
  return row.result === "fail" ? "red" : "green";
};

export const flattenInspectionData = (data) => {
  return data.map((row) => {
    row.location = supplierCities[row.company];
    let certified = row.fdaCertified ? "Yes" : "No";
    // let approved = certified === "Yes" || Math.random() > 0.2 ? "Yes" : "No";
    // let documents = Math.random() > 0.2 ? "Yes" : "No";
    return {
      o: 1,
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      supplier: row.company,
      certified,
      reviewLevel: row.reviewLevel,
      endDate: row.endDate,
      //approved,
      // documents,
      color: getInspectionColor(row),
      hasWarning: isPending(formatCurrentInspection(row)),
      location: row.location ? row.location.value : "",
      state: row.location,
      status: row.result,
      passed: row.result === "pass" ? 1 : 0,
      failed: row.result === "pass" ? 0 : 1,
    };
  });
};

const getProvonomicsColor = (supplier) => {
  return supplierAlignmentIndex[supplier] < 0 ? "red" : "green";
};

export const flattenProvonomicsData = (data, series) => {
  return data.map((row) => {
    return {
      o: series ? row.spent : 1,
      suffix: series ? "Spent" : "Pools",
      prefix: series ? "$" : "",
      supplier: row.supplier,
      ai: supplierAlignmentIndex[row.supplier],
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      location: row.location ? row.location.value : "",
      marker: {
        symbol: `url(https://pcndev.blob.core.windows.net/public/${getProvonomicsColor(
          row.supplier
        )}pin.png)`,
      },
      state: row.location,
    };
  });
};

export const flattenOrderData = (data, series) => {
  if (!data) return [];
  return data.map((row) => {
    return {
      o: series ? row.spent : 1,
      suffix: series ? "Spent" : "Orders",
      prefix: series ? "$" : "",
      lat: row.location ? row.location.lat : 0,
      lon: row.location ? row.location.lon : 0,
      location: row.location ? row.location.value : "",
    };
  });
};

export const flattenDateData = (data) => {
  return data.map((row) => {
    return {
      o: 1,
      x: Date.parse(moment(row.time).format("YYYY-MM-DD")),
    };
  });
};

export const flattenDateDataAndAggrigate = (data) => {
  return data.map((row) => {
    return {
      o: 1,
      x: Date.parse(moment(row.time).startOf("day").format("YYYY-MM-DD")),
    };
  });
};

export const flattenDateDataAndAggrigateBySpent = (data) => {
  return data.map((row) => {
    return {
      o: row.spent,
      x: Date.parse(moment(row.time).startOf("day").format("YYYY-MM-DD")),
    };
  });
};

export const sortDataByDate = (data, field) => {
  return data.sort(function (a, b) {
    return a[field] - b[field];
  });
};

export const filterActivitiesByType = (activities, type) => {
  return activities.filter((activity) => activity.type === type);
};

export const filterActivitiesByValue = (activities, value) => {
  return activities.filter((activity) => activity.value.value === value);
};

export const filterActivitiesUpToTime = (activities, timeRange) => {
  let minDate = moment(referenceDate);
  if (timeRange === "month") {
    minDate = moment(referenceDate).subtract(1, "months");
    return activities.filter(
      (activity) =>
        activity.time && moment(activity.time.date).isBefore(minDate)
    );
  }
  if (timeRange === "year") {
    minDate = moment(referenceDate).subtract(1, "years");
    return activities.filter(
      (activity) =>
        activity.time && moment(activity.time.date).isBefore(minDate)
    );
  }
  if (timeRange === "quarter") {
    minDate = moment(referenceDate).subtract(3, "months");
    return activities.filter(
      (activity) =>
        activity.time && moment(activity.time.date).isBefore(minDate)
    );
  }
  if (timeRange === "week") {
    minDate = moment(referenceDate).subtract(7, "days");
    return activities.filter(
      (activity) =>
        activity.time && moment(activity.time.date).isBefore(minDate)
    );
  }
};

export const filterActivitiesByEngagement = (activities, type) => {
  return activities.filter((row) => row.user && row.user.purchase === type);
};

export const getStartDate = (filter) => {
  switch (filter) {
    case "month":
      return moment(referenceDate).subtract(1, "months").format("YYYY-MM-DD");
    case "year":
      return moment(referenceDate).subtract(12, "months").format("YYYY-MM-DD");
    case "quarter":
      return moment(referenceDate).subtract(3, "months").format("YYYY-MM-DD");
    case "week":
      return moment(referenceDate).subtract(7, "days").format("YYYY-MM-DD");
    default:
      return "mm/dd/yyyy";
  }
};

export const filterByBom = (masterOrder, selectedBOM) => {
  if (selectedBOM === "All") return masterOrder;
  return (masterOrder || []).filter(
    (order) => String(order.bom) === String(selectedBOM)
  );
};

export const filterByManager = (masterOrder, selectedManager) => {
  if (selectedManager === "All") return masterOrder;
  return (masterOrder || []).filter(
    (order) => String(order.manager) === String(selectedManager)
  );
};

export const filterByInspection = (inspectionList, selectedInspection) => {
  if (!selectedInspection) {
    return inspectionList;
  }
  return (inspectionList || []).filter(
    (inspection) => String(inspection.auditName) === String(selectedInspection)
  );
};

const getDate = (val) => {
  const format1 = moment(val, "MM/DD/YY");
  const format2 = moment(val, "YYYY-MM-DD");
  return format1.isValid() ? format1 : format2;
};

export const formatCurrentInspection = (inspection) => {
  let inspectionArr = [];
  const validUntil = getDate(inspection.startDate)
    .add(Number.parseInt(inspection.coverageDays || "0"), "days")
    .format("YYYY-MM-DD");
  const expWarning = getDate(inspection.startDate)
    .add(
      Number.parseInt(inspection.coverageDays || "0") -
        Number.parseInt(inspection.notificationDays || "0"),
      "days"
    )
    .format("YYYY-MM-DD");
  inspectionArr.push(inspection.company);
  inspectionArr.push(inspection.formType);
  inspectionArr.push(inspection.associatedClaimsAndRequirements);
  inspectionArr.push(inspection.formType);
  inspectionArr.push(inspection.evidences);
  inspectionArr.push(inspection.reviewLevel);
  inspectionArr.push(inspection.reviewLevel == "3" ? "internal" : "external");
  inspectionArr.push(inspection.startDate);
  inspectionArr.push(inspection.endDate);
  inspectionArr.push(inspection.score);
  inspectionArr.push(inspection.result);
  inspectionArr.push(inspection.coverageDays);
  inspectionArr.push(validUntil); //startDate + coverageDays;
  inspectionArr.push(inspection.notificationDays);
  inspectionArr.push(expWarning); // startDate + coverageDays - notificationDays;
  inspectionArr.push(inspection.fdaCertified ? true : false);
  return inspectionArr;
};
