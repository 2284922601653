import renderCellExpand from "./ExpandRender";
import OpenPoRenderer from "./OpenPoRenderer";

export const BOMColumns = [
  {
    field: "bomLevel",
    headerName: "BOM Level",
    width: 180,
    renderCell: renderCellExpand,
  },
  {
    field: "itemDescription",
    headerName: "Item Description",
    width: 370,
    renderCell: renderCellExpand,
  },
  {
    field: "itemNumber",
    headerName: "Item Number",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "qtyPer",
    headerName: "QTY per",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "makeBuy",
    headerName: "Make/Buy",
    width: 200,
    renderCell: renderCellExpand,
  },
  { field: "type", headerName: "Type", width: 200 },
  {
    field: "manufacturerOEM",
    headerName: "Manufacturer OEM",
    width: 220,
    renderCell: renderCellExpand,
  },
  {
    field: "preferredApprovedSupplier",
    headerName: "Preferred (approved) Supplier",
    width: 300,
    renderCell: renderCellExpand,
  },
  {
    field: "alternateApprovedSuppliers",
    headerName: "Alternate (approved) Suppliers",
    width: 350,
    renderCell: renderCellExpand,
  },
  {
    field: "alternateNonApprovedSuppliers",
    headerName: "Alternate (non-approved) Suppliers",
    width: 350,
    renderCell: renderCellExpand,
  },
  {
    field: "supplyRisk",
    headerName: "Supply RISK",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "price",
    headerName: "Price",
    width: 200,
    renderCell: renderCellExpand,
  },
  {
    field: "openPOs",
    headerName: "Open POs",
    width: 300,
    renderCell: OpenPoRenderer,
  },
];

export const BOMDataMax = [
  {
    id: 1,
    bomLevel: 0,
    itemDescription: "MAX-1",
    itemNumber: "000000-200",
    qtyPer: "",
    makeBuy: "In-House",
    type: "",
    manufacturerOEM: "Phase Four",
    preferredApprovedSupplier: "Phase Four",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "",
    price: "$24,575.00",
    openPOs: ["PCN100079", "PCN100043"],
  },
  {
    id: 2,
    bomLevel: 1,
    itemDescription: "QTP, TOP ASSY, MAXWELL 1",
    itemNumber: "001081-401",
    qtyPer: "",
    makeBuy: "Make",
    type: "Test",
    manufacturerOEM: "Premier Industries",
    preferredApprovedSupplier: "Premier Industries",
    alternateApprovedSuppliers: "Ram Manufacturing, Beswick Engineering",
    alternateNonApprovedSuppliers:
      "Omnetics, Glenair, Thermometrics, St Gobain",
    supplyRisk: "Low",
    price: "$1,192.00",
    openPOs: ["PCN100047", "PCN10008"],
  },
  {
    id: 3,
    bomLevel: 1,
    itemDescription: "ATP, TOP ASSY, MAXWELL 1",
    itemNumber: "001081-402",
    qtyPer: "",
    makeBuy: "Make",
    type: "Test",
    manufacturerOEM: "Premier Industries",
    preferredApprovedSupplier: "Premier Industries",
    alternateApprovedSuppliers: "CnC Tech, Fair-Rite",
    alternateNonApprovedSuppliers:
      "Glenair, Thermometrics, Krytox, Multicore, TE Connectivity, Wiremasters, Allen Aircraft Supply",
    supplyRisk: "Low",
    price: "$1,474.00",
    openPOs: ["PCN100031", "PCN100080", "PCN100027"],
  },
  {
    id: 4,
    bomLevel: 2,
    itemDescription: "TOP ASSY, MAX-1",
    itemNumber: "001081-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "Premier Industries",
    preferredApprovedSupplier: "Premier Industries",
    alternateApprovedSuppliers: "CnC Tech, Fair-Rite",
    alternateNonApprovedSuppliers: "Thermometrics",
    supplyRisk: "Med",
    price: "$842.00",
    openPOs: ["PCN100097", "PCN100053"],
  },
  {
    id: 5,
    bomLevel: 3,
    itemDescription: "SUB ASSY, PROPULSIVE EXOSKELETON, MAX-1",
    itemNumber: "001374-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "Ram Manufacturing",
    preferredApprovedSupplier: "Ram Manufacturing",
    alternateApprovedSuppliers: "Sierra, Micro-Q",
    alternateNonApprovedSuppliers:
      "Glenair, St Gobain, Krytox, Multicore, Wiremasters, Seco Seals",
    supplyRisk: "Med",
    price: "$683.00",
    openPOs: ["PCN100030"],
  },
  {
    id: 6,
    bomLevel: 4,
    itemDescription: "SUB ASSY, FLYBACK EXOSKELETON, MAX-1",
    itemNumber: "001283-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "Ram Manufacturing",
    preferredApprovedSupplier: "Ram Manufacturing",
    alternateApprovedSuppliers:
      "RJ Machine, CnC Tech, Beswick Engineering, Mundo Tech",
    alternateNonApprovedSuppliers:
      "Thermometrics, St Gobain, Multicore, TE Connectivity, Seco Seals",
    supplyRisk: "Med",
    price: "$571.00",
    openPOs: ["PCN10002"],
  },
  {
    id: 7,
    bomLevel: 5,
    itemDescription: "INSERT ASSY, THRUSTER RING, EXOSKELETON, MAX-1",
    itemNumber: "001297-201",
    qtyPer: "",
    makeBuy: "Buy",
    type: "BTP",
    manufacturerOEM: "CnC Tech",
    preferredApprovedSupplier: "RJ Machine",
    alternateApprovedSuppliers: "CnC Tech, Micro-Q",
    alternateNonApprovedSuppliers: "Multicore",
    supplyRisk: "Med",
    price: "$1,330.00",
    openPOs: [],
  },
  {
    id: 8,
    bomLevel: 6,
    itemDescription: "ASSY, TRANSFORMER, FLYBACK, MAX-1",
    itemNumber: "001468-201",
    qtyPer: "",
    makeBuy: "Buy",
    type: "BTP",
    manufacturerOEM: "Integrated Magnetics",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "Fair-Rite, Mundo Tech",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "Low",
    price: "$765.00",
    openPOs: ["PCN100019"],
  },
  {
    id: 9,
    bomLevel: 4,
    itemDescription: "SUB ASSY, THRUSTER INVERTER ASSY, MAX-1",
    itemNumber: "001372-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers:
      "CnC Tech, Fair-Rite, Beswick Engineering, Mundo Tech, Sierra, Micro-Q",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "Low",
    price: "$691.00",
    openPOs: ["PCN100011"],
  },
  {
    id: 10,
    bomLevel: 5,
    itemDescription: "SUB ASSY, MOUNTED INVERTER, MOUNTED",
    itemNumber: "001283-202",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "Parker Chomerics Div., Sierra",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$1,662.00",
    openPOs: ["PCN100033"],
  },
  {
    id: 11,
    bomLevel: 7,
    itemDescription: "ASSY, WCM TRANSFORMER, 12S-6P, 14 AWG",
    itemNumber: "001390-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "Mundo Tech",
    alternateNonApprovedSuppliers:
      "St Gobain, Wiremasters, Seco Seals, Allen Aircraft Supply",
    supplyRisk: "Low",
    price: "$814.00",
    openPOs: ["PCN100090", "PCN100080", "PCN100055"],
  },
  {
    id: 12,
    bomLevel: 7,
    itemDescription: "ASSY, CURRENT SENSE TRANSFORMER, 66, 30 AWG",
    itemNumber: "001392-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "RJ Machine",
    alternateNonApprovedSuppliers: "TE Connectivity",
    supplyRisk: "Med",
    price: "$248.00",
    openPOs: ["PCN100078", "PCN100090", "PCN100037"],
  },
  {
    id: 13,
    bomLevel: 7,
    itemDescription: "ASSY, INDUCTOR (CHOKE), 12 WINDINGS, 14 AWG",
    itemNumber: "001393-201",
    qtyPer: "",
    makeBuy: "Buy",
    type: "COTS",
    manufacturerOEM: "Grainger",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "Low",
    price: "$64.00",
    openPOs: ["PCN100056", "PCN100025", "PCN100076"],
  },
  {
    id: 14,
    bomLevel: 3,
    itemDescription: "SUB ASSY, P4 SCREEN, MAX-1",
    itemNumber: "001310-202",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "Digi-Key Electronics",
    preferredApprovedSupplier: "Digi-Key Electronics",
    alternateApprovedSuppliers: "CnC Tech",
    alternateNonApprovedSuppliers: "TE Connectivity, Allen Aircraft Supply",
    supplyRisk: "Med",
    price: "$777.00",
    openPOs: ["PCN100020", "PCN100083", "PCN100065"],
  },
  {
    id: 15,
    bomLevel: 3,
    itemDescription: "SUB ASSY, TOP SCREEN, MAX-1",
    itemNumber: "001310-203",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "Digi-Key Electronics",
    preferredApprovedSupplier: "Digi-Key Electronics",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$717.00",
    openPOs: ["PCN100078", "PCN100065"],
  },
  {
    id: 16,
    bomLevel: 4,
    itemDescription: "HARNESSED ASSY, TANK, MAX-1",
    itemNumber: "001305-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "Sub-Assy",
    manufacturerOEM: "Digi-Key Electronics",
    preferredApprovedSupplier: "Digi-Key Electronics",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers:
      "Omnetics, Multicore, Wiremasters, Seco Seals, Allen Aircraft Supply",
    supplyRisk: "Med",
    price: "$612.00",
    openPOs: ["PCN100063", "PCN100026", "PCN100056"],
  },
  {
    id: 17,
    bomLevel: 4,
    itemDescription: "SUB ASSY, TANK FEED, PROP CONTROLLER, MAX-1",
    itemNumber: "001170-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "Sub-Assy",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "Micro-Q",
    alternateNonApprovedSuppliers: "St Gobain",
    supplyRisk: "HIGH",
    price: "$606.00",
    openPOs: ["PCN100091", "PCN100088", "PCN100086"],
  },
  {
    id: 18,
    bomLevel: 4,
    itemDescription: "SUB ASSY, INTEGRATED FLUID CONTROL, DO-NO-HARM, MAX-1",
    itemNumber: "001295-202",
    qtyPer: "",
    makeBuy: "Make",
    type: "Sub-Assy",
    manufacturerOEM: "Parker Chomerics Div.",
    preferredApprovedSupplier: "Parker Chomerics Div.",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers:
      "Omnetics, Glenair, Thermometrics, St Gobain, Krytox, Multicore",
    supplyRisk: "Low",
    price: "$588.00",
    openPOs: ["PCN100071"],
  },
  {
    id: 19,
    bomLevel: 5,
    itemDescription: "LOW P TUBING ASSY, PROP CTLR TO MFC",
    itemNumber: "001315-201",
    qtyPer: "",
    makeBuy: "Make",
    type: "Sub-Assy",
    manufacturerOEM: "O'Keefe Controls",
    preferredApprovedSupplier: "O'Keefe Controls",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "CRITICAL",
    price: "$980.00",
    openPOs: ["PCN10003"],
  },
  {
    id: 20,
    bomLevel: 5,
    itemDescription: "HARNESSED ASSY, FLUID BLOCK, DO-NO-HARM, MAX-1",
    itemNumber: "001304-202",
    qtyPer: "",
    makeBuy: "Make",
    type: "Sub-Assy",
    manufacturerOEM: "Parker Chomerics Div.",
    preferredApprovedSupplier: "Parker Chomerics Div.",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "Krytox, Seco Seals",
    supplyRisk: "Low",
    price: "$228.00",
    openPOs: ["PCN100055"],
  },
  {
    id: 21,
    bomLevel: 6,
    itemDescription: "SUB ASSY, FLUID BLOCK, DO-NO-HARM, MAX-1",
    itemNumber: "001132-202",
    qtyPer: "",
    makeBuy: "In-House",
    type: "Sub-Assy",
    manufacturerOEM: "Phase Four",
    preferredApprovedSupplier: "Phase Four",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$671.00",
    openPOs: ["PCN100047"],
  },
];

export const BOMDataGaus = [
  {
    bomLevel: 0,
    itemDescription: "Focal Plane Electronics (FPE)",
    itemNumber: "ZZ-21",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "GAUS ROCKETS",
    preferredApprovedSupplier: "GAUS ROCKETS",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "LOW",
    price: "$25,645.00",
    openPOs: [],
    id: 1,
  },
  {
    bomLevel: 1,
    itemDescription: "DFPIE WCCA Material",
    itemNumber: "YY-400",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "GAUS ROCKETS",
    preferredApprovedSupplier: "GAUS ROCKETS",
    alternateApprovedSuppliers: "Micron, TopQual Engineering",
    alternateNonApprovedSuppliers:
      "Settlement CnC,  CnC Discounters, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$1,820.00",
    openPOs: ["PCN100039", "PCN100029", "PCN100098"],
    id: 2,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 10.0 OHM, 1%, 0.05W",
    itemNumber: "CRCW040210R0FKTD-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "CnC Tech",
    alternateNonApprovedSuppliers: "CnC Discounters, HighTemp CnC",
    supplyRisk: "MED",
    price: "$6,954.00",
    openPOs: ["PCN100027"],
    id: 3,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, CERAMIC, 0.1UF, 25V, 10%,0603",
    itemNumber: "C0603C104K3RALTU-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "KEMET ELECTRONICS CORPORATION",
    preferredApprovedSupplier: "KEMET ELECTRONICS CORPORATION",
    alternateApprovedSuppliers: "Samaria Inc, Beswick Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$550.00",
    openPOs: ["PCN100044"],
    id: 4,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR,CERAMIC,6800PF,5%,50V,0805,NPO",
    itemNumber: "C0805T682J5GALTU-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers:
      "TruParts Inc, High Nevada Tech, Micron, TopQual Engineering",
    alternateNonApprovedSuppliers:
      "QwickProduction Inc,  CnC Discounters , Settlement CnC",
    supplyRisk: "LOW",
    price: "$3,500.00",
    openPOs: ["PCN100045"],
    id: 5,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, TANTALUM, 68UF, 20%, 6.3V",
    itemNumber: "T540B686M006AH6610-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "KEMET ELECTRONICS CORPORATION",
    preferredApprovedSupplier: "KEMET ELECTRONICS CORPORATION",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc",
    supplyRisk: "HIGH",
    price: "$1,635.00",
    openPOs: ["PCN100041", "PCN100080", "PCN10006"],
    id: 6,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, TANTALUM, 15UF, 20%, 35V",
    itemNumber: "T540D156M035AH6610-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "KEMET ELECTRONICS CORPORATION",
    preferredApprovedSupplier: "KEMET ELECTRONICS CORPORATION",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering",
    supplyRisk: "HIGH",
    price: "$80,000.00",
    openPOs: ["PCN100035", "PCN100041", "PCN100019"],
    id: 7,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, TANTALUM, 33UF, 20%, 10V",
    itemNumber: "T540B336M010AH6610-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "KEMET ELECTRONICS CORPORATION",
    preferredApprovedSupplier: "KEMET ELECTRONICS CORPORATION",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$65,000.00",
    openPOs: [],
    id: 8,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR,3.01K OHM,0.1%,0.05W,0402,25PPM",
    itemNumber: "M55342E11B3B01R-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers:
      "TruParts Inc, High Nevada Tech, Micron, TopQual Engineering",
    alternateNonApprovedSuppliers:
      "QwickProduction Inc,  CnC Discounters , Settlement CnC",
    supplyRisk: "LOW",
    price: "$120,000.00",
    openPOs: ["PCN100089", "PCN100031", "PCN10008"],
    id: 9,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR,13.3K OHM,0.1%,0.05W,0402,25PPM",
    itemNumber: "M55342E11B13B3R-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "ARROW ELECTRONICS INC",
    preferredApprovedSupplier: "ARROW ELECTRONICS INC",
    alternateApprovedSuppliers: "Samaria Inc, Beswick Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$115,000.00",
    openPOs: ["PCN100039", "PCN100015", "PCN100086"],
    id: 10,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR,4.53K OHM,0.1%,0.05W,0402,25PPM",
    itemNumber: "M55342E11B4B53R-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "ARROW ELECTRONICS INC",
    preferredApprovedSupplier: "ARROW ELECTRONICS INC",
    alternateApprovedSuppliers: "Samaria Inc, Beswick Engineering",
    alternateNonApprovedSuppliers:
      "Settlement CnC,  CnC Discounters, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$68,000.00",
    openPOs: ["PCN100056"],
    id: 11,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR,4.99K OHM,0.1%,0.063W,0402,25PP",
    itemNumber: "M55342E11B4B99R-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "CnC Tech, Beswick Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "LOW",
    price: "$52,600.00",
    openPOs: ["PCN100013", "PCN10001"],
    id: 12,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR,16.9K OHM,0.1%,0.05W,0402,25PPM",
    itemNumber: "M55342E11B16B9R-CRE-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "STATE OF THE ART INC",
    preferredApprovedSupplier: "STATE OF THE ART INC",
    alternateApprovedSuppliers: "Samaria Inc",
    alternateNonApprovedSuppliers: "CnC Discounters",
    supplyRisk: "HIGH",
    price: "$3,500.00",
    openPOs: ["PCN100094", "PCN100080"],
    id: 13,
  },
  {
    bomLevel: 1,
    itemDescription: "LVPS BLACKBODY MATERIAL",
    itemNumber: "XX-1400",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "GAUS ROCKETS",
    preferredApprovedSupplier: "GAUS ROCKETS",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers:
      "QwickProduction Inc,  CnC Discounters , Settlement CnC",
    supplyRisk: "LOW",
    price: "$560.00",
    openPOs: ["PCN100038", "PCN100077", "PCN100072"],
    id: 14,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 100 OHM, 1%, 0.25W",
    itemNumber: "CRCW1206100RFKTA-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "VISHAY INTERTECHNOLOGY INC",
    preferredApprovedSupplier: "VISHAY INTERTECHNOLOGY INC",
    alternateApprovedSuppliers: "Rj CnC",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$2,000.00",
    openPOs: ["PCN100096", "PCN100048", "PCN100037"],
    id: 15,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 10.7K OHM, 1%, 0.25W",
    itemNumber: "CRCW120610K7FKTA-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "VISHAY INTERTECHNOLOGY INC",
    preferredApprovedSupplier: "VISHAY INTERTECHNOLOGY INC",
    alternateApprovedSuppliers: "Rj CnC",
    alternateNonApprovedSuppliers: "GNA Engineering",
    supplyRisk: "HIGH",
    price: "$1,350.00",
    openPOs: ["PCN100067"],
    id: 16,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 20.0K OHM, 0.1%, 0.10W",
    itemNumber: "TNPW060320K0BECN-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "CnC Tech, Beswick Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "LOW",
    price: "$1,400.00",
    openPOs: [],
    id: 17,
  },
  {
    bomLevel: 2,
    itemDescription: "TRANSISITOR,MOSFET,P-CHANNEL,200V,-12.5A",
    itemNumber: "JANSR2N7546U3-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "INTERNATIONAL RECTIFIER CORPORATION",
    preferredApprovedSupplier: "INTERNATIONAL RECTIFIER CORPORATION",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc",
    supplyRisk: "HIGH",
    price: "$65.00",
    openPOs: ["PCN100057"],
    id: 18,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 10.0 OHM, 1%, 0.25W",
    itemNumber: "CRCW120610R0FKTA-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "Rj CnC",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "MED",
    price: "$30.00",
    openPOs: [],
    id: 19,
  },
  {
    bomLevel: 2,
    itemDescription: "MICROCIRCUIT, OP AMP, QUAD, LOW POWER",
    itemNumber: "5962F1321401VZC-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AVNET INC",
    preferredApprovedSupplier: "AVNET INC",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$620.00",
    openPOs: [],
    id: 20,
  },
  {
    bomLevel: 2,
    itemDescription: "MICROCIRCUIT, PWM, DUAL OUTPUT",
    itemNumber: "5962F0251101QZC-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AVNET INC",
    preferredApprovedSupplier: "AVNET INC",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering",
    supplyRisk: "HIGH",
    price: "$550.00",
    openPOs: ["PCN100051", "PCN100080", "PCN100013"],
    id: 21,
  },
  {
    bomLevel: 2,
    itemDescription: "TRANSISTOR, MOSFET, N-CHANNEL, 200V, 45A",
    itemNumber: "IRHMS67260-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "INTERNATIONAL RECTIFIER CORPORATION",
    preferredApprovedSupplier: "INTERNATIONAL RECTIFIER CORPORATION",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "CnC Discounters",
    supplyRisk: "CRITICAL",
    price: "$350.00",
    openPOs: ["PCN100021", "PCN100056", "PCN100012"],
    id: 22,
  },
  {
    bomLevel: 2,
    itemDescription: "CONNECTOR, 150 CONTACTS, FEMALE, VERSI",
    itemNumber: "VSRAF-05-30-50-00-N-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AIRBORN INTERCONNECT INC",
    preferredApprovedSupplier: "AIRBORN INTERCONNECT INC",
    alternateApprovedSuppliers:
      "TruParts Inc, High Nevada Tech, Micron, TopQual Engineering",
    alternateNonApprovedSuppliers:
      "QwickProduction Inc,  CnC Discounters , Settlement CnC",
    supplyRisk: "LOW",
    price: "$22,000.00",
    openPOs: ["PCN100084", "PCN100046"],
    id: 23,
  },
  {
    bomLevel: 2,
    itemDescription: "INDUCTOR, 4.72UH, 20%, 15A",
    itemNumber: "AE612PNB472MSZ-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "COILCRAFT INCORPORATED",
    preferredApprovedSupplier: "COILCRAFT INCORPORATED",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$10,000.00",
    openPOs: [],
    id: 24,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 0 OHM, 0.15W, 0705",
    itemNumber: "M32159B06M-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "CRITICAL",
    price: "$5,000.00",
    openPOs: ["PCN100046", "PCN100081"],
    id: 25,
  },
  {
    bomLevel: 2,
    itemDescription: "THERMISTOR, NEGATIVE TC, 10K OHM",
    itemNumber: "311P18-08S7R6-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "MEASUREMENT SPECIALTIES INC",
    preferredApprovedSupplier: "MEASUREMENT SPECIALTIES INC",
    alternateApprovedSuppliers:
      "TruParts Inc, High Nevada Tech, Micron, TopQual Engineering",
    alternateNonApprovedSuppliers: "Settlement CnC,  GNA Engineering",
    supplyRisk: "LOW",
    price: "$35,000.00",
    openPOs: ["PCN100031"],
    id: 26,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 2.00K OHM, 0.1%, 0.05W",
    itemNumber: "M55342E11B2B00R-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "CnC Tech",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "MED",
    price: "$2,250.00",
    openPOs: ["PCN100064", "PCN100026"],
    id: 27,
  },
  {
    bomLevel: 2,
    itemDescription: "TRANSISTOR, NPN SWITCHING, SMD PACKAGE",
    itemNumber: "JANTZV2N2222AUB-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "SPIRIT ELECTRONICS LLC",
    preferredApprovedSupplier: "SPIRIT ELECTRONICS LLC",
    alternateApprovedSuppliers: "Rj CnC",
    alternateNonApprovedSuppliers: "Settlement CnC",
    supplyRisk: "HIGH",
    price: "$1,890.00",
    openPOs: ["PCN100033"],
    id: 28,
  },
  {
    bomLevel: 2,
    itemDescription: "TRANSISTOR, PNP SWITCHING, SMD PACKAGE",
    itemNumber: "JAN2N2907AUB-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "SPIRIT ELECTRONICS LLC",
    preferredApprovedSupplier: "SPIRIT ELECTRONICS LLC",
    alternateApprovedSuppliers: "Samaria Inc, Beswick Engineering",
    alternateNonApprovedSuppliers: "Settlement CnC,  GNA Engineering",
    supplyRisk: "LOW",
    price: "$955.00",
    openPOs: [],
    id: 29,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 100K OHM, 0.1%, 0.05W",
    itemNumber: "M55342E11B100BR-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "STATE OF THE ART INC",
    preferredApprovedSupplier: "STATE OF THE ART INC",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers:
      "Settlement CnC,  CnC Discounters, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$360.00",
    openPOs: [],
    id: 30,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 5.62 OHM, 1%, 0.5W",
    itemNumber: "M55342K10B5D62R-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "STATE OF THE ART INC",
    preferredApprovedSupplier: "STATE OF THE ART INC",
    alternateApprovedSuppliers: "Micron, TopQual Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$950.00",
    openPOs: ["PCN10005", "PCN100075"],
    id: 31,
  },
  {
    bomLevel: 2,
    itemDescription: "DIODE, ZENER, 15.0V. 2%, 20MA, SQ MELF",
    itemNumber: "1N6328US-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AVNET INC.",
    preferredApprovedSupplier: "AVNET INC.",
    alternateApprovedSuppliers:
      "TruParts Inc, High Nevada Tech, Micron, TopQual Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "LOW",
    price: "$30,000.00",
    openPOs: [],
    id: 32,
  },
  {
    bomLevel: 2,
    itemDescription: "MICROCIRCUIT,OPTO-COUPLER,SINGLE CHANNEL",
    itemNumber: "66266-304-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "MICROPAC INDUSTRIES INC",
    preferredApprovedSupplier: "MICROPAC INDUSTRIES INC",
    alternateApprovedSuppliers: "",
    alternateNonApprovedSuppliers: "Settlement CnC",
    supplyRisk: "CRITICAL",
    price: "$2,500.00",
    openPOs: ["PCN100017", "PCN100073", "PCN100080"],
    id: 33,
  },
  {
    bomLevel: 2,
    itemDescription: "MICROCIRCUIT, VOLTAGE REGULATOR",
    itemNumber: "5962F0151001VZC-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AVNET INC",
    preferredApprovedSupplier: "AVNET INC",
    alternateApprovedSuppliers: "Rj CnC",
    alternateNonApprovedSuppliers: "Settlement CnC,  GNA Engineering",
    supplyRisk: "MED",
    price: "$450.00",
    openPOs: ["PCN100092", "PCN100091"],
    id: 34,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, CERAMIC, 4.7UF, 10%, 100V",
    itemNumber: "M49470Z01475KBL-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "PRESIDIO COMPONENTS INC",
    preferredApprovedSupplier: "PRESIDIO COMPONENTS INC",
    alternateApprovedSuppliers: "CnC Tech",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$350.00",
    openPOs: ["PCN100045", "PCN100095"],
    id: 35,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 100 OHM, 0.1%, 0.05W",
    itemNumber: "M55342E11B100AR-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "STATE OF THE ART INC",
    preferredApprovedSupplier: "STATE OF THE ART INC",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$819.00",
    openPOs: [],
    id: 36,
  },
  {
    bomLevel: 2,
    itemDescription: "DIODE, ZENER, 15.0V. 2%, 20MA, SQ MELF",
    itemNumber: "1N6328US-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "AVNET INC.",
    preferredApprovedSupplier: "AVNET INC.",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "Settlement CnC",
    supplyRisk: "HIGH",
    price: "$3,520.00",
    openPOs: ["PCN100053"],
    id: 37,
  },
  {
    bomLevel: 2,
    itemDescription: "TRANSISTOR, MOSFET, 150V N-CH",
    itemNumber: "IRHNJ67134-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "INTERNATIONAL RECTIFIER CORPORATION",
    preferredApprovedSupplier: "INTERNATIONAL RECTIFIER CORPORATION",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "LOW",
    price: "$60,000.00",
    openPOs: ["PCN100085", "PCN100081"],
    id: 38,
  },
  {
    bomLevel: 2,
    itemDescription: "CAPACITOR, TANTALUM, 220UF, 20%, 10V",
    itemNumber: "T540D227M010AH6610-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "KEMET ELECTRONICS CORPORATION",
    preferredApprovedSupplier: "KEMET ELECTRONICS CORPORATION",
    alternateApprovedSuppliers: "Samaria Inc",
    alternateNonApprovedSuppliers: "",
    supplyRisk: "HIGH",
    price: "$54.00",
    openPOs: [],
    id: 39,
  },
  {
    bomLevel: 2,
    itemDescription: "RESISTOR, 562 OHM, 1%, 3W",
    itemNumber: "RWR89N5620FM-ZZP-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "TTI INC",
    preferredApprovedSupplier: "TTI INC",
    alternateApprovedSuppliers: "High Nevada Tech",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "MED",
    price: "$980.00",
    openPOs: ["PCN100029", "PCN100020", "PCN100017"],
    id: 40,
  },
  {
    bomLevel: 1,
    itemDescription: "SPECIAL TEST EQUIPMENT MATERIAL",
    itemNumber: "WW-9001",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "GAUS ROCKETS",
    preferredApprovedSupplier: "GAUS ROCKETS",
    alternateApprovedSuppliers:
      "Beswick Engineering, TruParts Inc, High Nevada Tech",
    alternateNonApprovedSuppliers:
      "Settlement CnC,  CnC Discounters, HighTemp CnC",
    supplyRisk: "LOW",
    price: "$3,000.00",
    openPOs: [],
    id: 41,
  },
  {
    bomLevel: 2,
    itemDescription: "CONNECTOR, R-SERIES, 20 POS",
    itemNumber: "RM242-020-571-5900-ZZZ-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "MOUSER ELECTRONICS INC",
    preferredApprovedSupplier: "MOUSER ELECTRONICS INC",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "GNA Engineering,  CnC Discounters",
    supplyRisk: "MED",
    price: "$256.00",
    openPOs: [],
    id: 42,
  },
  {
    bomLevel: 2,
    itemDescription: "CONNECTOR",
    itemNumber: "D38999/26FJ35SN-ZZZ-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "MOUSER ELECTRONICS INC",
    preferredApprovedSupplier: "MOUSER ELECTRONICS INC",
    alternateApprovedSuppliers: "Samaria Inc",
    alternateNonApprovedSuppliers: "Settlement CnC,  GNA Engineering",
    supplyRisk: "MED",
    price: "$8,000.00",
    openPOs: ["PCN100095", "PCN10009"],
    id: 43,
  },
  {
    bomLevel: 2,
    itemDescription: "CONNECTOR, R-SERIES, 20 POS",
    itemNumber: "RM212-020-111-2900-ZZZ-PCN",
    qtyPer: "",
    makeBuy: "Make",
    type: "BTP",
    manufacturerOEM: "MOUSER ELECTRONICS INC",
    preferredApprovedSupplier: "MOUSER ELECTRONICS INC",
    alternateApprovedSuppliers: "Beswick Engineering",
    alternateNonApprovedSuppliers: "QwickProduction Inc, HighTemp CnC",
    supplyRisk: "MED",
    price: "$950.00",
    openPOs: ["PCN100012", "PCN100049"],
    id: 44,
  },
];

export const billOfMaterials = [
  {
    id: 123,
    name: "Gaus-1",
    data: BOMDataGaus,
  },
  {
    id: 124,
    name: "Maxwell-1",
    data: BOMDataMax,
  },
];

export const GAUS_1_MANAGERS = [
  "LEBRON JAMES",
  "KOBE BRYANT",
  "KAREEM ABDUL JABAR",
  "ROGER ALLEN",
  "ERVING JOHNSON",
  "A.C. GREEN",
];

export const MAX_1_MANAGERS = ["Dan", "Ketan", "Dylan"];
