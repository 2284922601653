import React, { useState } from "react";
import {
  makeStyles,
  Paper,
  Card,
  Box,
  Typography,
  Divider,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
  averageViewHeading: {
    fontSize: "1.2rem",
    color: "#222222",
    fontWeight: "800",
  },
  averageViewDescription: {
    fontSize: ".8rem",
    color: "#222222",
    fontWeight: "300",
    marginLeft: theme.spacing(3),
  },
  circle: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
      width: theme.spacing(1),
      height: theme.spacing(1),
    },
  },
}));

const AverageView = ({ title, description, subTitle, color }) => {
  let classes = useStyles();
  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      <Box display="flex" flexDirection="row">
        {color && (
          <div className={classes.circle}>
            <Card style={{ backgroundColor: color }} />
          </div>
        )}
        <Typography variant="h2" className={classes.averageViewHeading}>
          {title}
        </Typography>
      </Box>
      <Typography variant="p" className={classes.averageViewDescription}>
        {description}
      </Typography>
      {subTitle && (
        <Typography variant="p" className={classes.averageViewDescription}>
          {subTitle}
        </Typography>
      )}
    </Box>
  );
};

export default AverageView;
