import { get } from "../common/api";
import { ISNPECTIONS_URL } from "../common/api/endpoints";
import usersData from "data/usersData.json";

export const fetchAllResourcesSilent = async () => {
  const data = await Promise.all([
    fetchInspections(),
    fetchSuppliers(),
    fetchSiteInspection(),
  ]).then((val) => {
    return val;
  });
  return data;
};

export const fetchInspections = async (force) => {
  const group = localStorage.getItem("group");
  const token = localStorage.getItem("token");
  if (!force && dataExist("inspections")) return Promise.resolve(true);
  await get(`${ISNPECTIONS_URL}?company=${group}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("inspections", JSON.stringify(data));
      return true;
    });
  return Promise.resolve(true);
};

export const fetchSuppliers = async () => {
  const user = getLoggedInUserDetails();
  if (!user) return Promise.resolve(false);
  if (dataExist("supplierList")) return Promise.resolve(true);
  await get(user.supplierList)
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("supplierList", JSON.stringify(data));
      return true;
    });
  return Promise.resolve(true);
};

export const fetchProvonomics = async () => {
  const user = getLoggedInUserDetails();
  if (!user) return Promise.resolve(false);
  if (dataExist("pools")) return Promise.resolve(true);
  await get(user.provonomicsData)
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("pools", JSON.stringify(data));
      return true;
    });
};

export const fetchSiteInspection = async () => {
  const user = getLoggedInUserDetails();
  if (!user) return Promise.resolve(false);
  if (dataExist("siteInspection")) return Promise.resolve(true);
  await get(user.siteInspectionData)
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("siteInspection", JSON.stringify(data));
      return true;
    });
  return Promise.resolve(true);
};

const dataExist = (localStorageKey) => {
  return Boolean(localStorage.getItem(localStorageKey));
};

const getLoggedInUserDetails = () => {
  const email = localStorage.getItem("email");
  const user = usersData.users.filter((user) => user.email === email)[0];
  return user;
};

const handleResponse = async (resp) => {
  const data = await resp.json();
  return data;
};
