import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { NonceProvider } from "react-select";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    width: 50,
  },
  text: {
    marginTop: "-5px",
    marginRight: "10px",
  },
  white: {
    backgroundColor: "#eee",
    marginTop: "2px",
  },
  redCircle: {
    backgroundColor: "#FF5353",
    borderRadius: "5px",
    border: "solid 2px #FFF",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
  },
  greenCircle: {
    backgroundColor: "#01D131",
    borderRadius: "5px",
    border: "solid 2px #FFF",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
  },
  green: {
    backgroundColor: "#01D131",
    marginTop: "2px",
  },
  red: {
    backgroundColor: "#FF5353",
    marginTop: "2px",
  },
});

const RangeSlider = (props) => {
  const { alignmentIndex, supplierAI } = props.data;
  const alignmentIndexFloat = Number.parseFloat(alignmentIndex);
  const supplierAIFloat = Number.parseFloat(supplierAI);
  const classes = useStyles();
  const min = Math.min(supplierAIFloat, alignmentIndexFloat);
  const max = Math.max(supplierAIFloat, alignmentIndexFloat);
  const barWidth = ((max - min) / 2) * 50;
  const leftWidth = ((1 + min) / 2) * 50;
  const rightWidth = 50 - leftWidth - barWidth;

  return (
    <div className={classes.root}>
      <Box height={5} width={100} display="flex" flexDirection="row">
        <Box className={classes.text}>{alignmentIndexFloat.toFixed(1)}</Box>
        <Box height={5} width={leftWidth} className={classes.white}></Box>
        {alignmentIndexFloat > supplierAIFloat && (
          <Box
            height={5}
            width={barWidth}
            className={
              alignmentIndexFloat > supplierAIFloat
                ? classes.green
                : classes.red
            }
          ></Box>
        )}
        {alignmentIndex < 0 && (
          <Box height={10} width={10} className={classes.redCircle}></Box>
        )}
        {alignmentIndex >= 0 && (
          <Box height={10} width={10} className={classes.greenCircle}></Box>
        )}
        {alignmentIndexFloat < supplierAIFloat && (
          <Box
            height={5}
            width={barWidth}
            className={
              alignmentIndexFloat > supplierAIFloat
                ? classes.green
                : classes.red
            }
          ></Box>
        )}
        <Box height={5} width={rightWidth} className={classes.white}></Box>
      </Box>
    </div>
  );
};
export default RangeSlider;
