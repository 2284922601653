import { Box } from "@material-ui/core";
import React from "react";
import EmptyCircle from "assets/images/EmptyCircle.svg";
import FilledCircle from "assets/images/FilledCircle.svg";

const CircleChart = (props) => {
  const { total = 9, filled = 4 } = props;
  const datapoints = [];
  [...Array(total)].forEach((element, index) => {
    datapoints.push({ isFilled: index < filled });
  });
  return (
    <Box
      margin="5px"
      height="25px"
      width="30px"
      className="circlechartcontainer"
    >
      {datapoints.map((row, index) => {
        if (row.isFilled)
          return (
            <img
              key={index}
              src={FilledCircle}
              className="circle-chart-block"
              alt=""
            />
          );
        return (
          <img key={index} src={EmptyCircle} className="circle-chart-block" alt="" />
        );
      })}
    </Box>
  );
};
export default CircleChart;
