import {
  aggregateData,
  flattenData,
  flattenOrderData,
  getFilteredData,
} from "./utils";
import supplierCities from "data/supplierCities";
import moment from "moment";

const colorMap = {
  spend: "#DD6717",
  orders: "#7FA0EA",
};
const orderStatusMap = {
  Submitted: (status) => {
    return true;
  },
  Confirmed: (status) => {
    if (status === "confirmedLag" || status === "confirmed") return true;
    return false;
  },
  Unconfirmed: (status) => {
    if (status === "unconfirmedLag" || status === "unconfirmed") return true;
    return false;
  },
  "Lag Warning": (status) => {
    if (status === "confirmedLag" || status === "unconfirmedLag") return true;
    return false;
  },
};

export const generateOrderActivity = (orders, filters) => {
  const { orderStatus, chartType, setFiltered, timeFilter, filtered } = filters;

  let data = orders.filter((order) =>
    orderStatusMap[orderStatus]
      ? orderStatusMap[orderStatus](order.orderStatus)
      : true
  );

  data = data.map((row) => {
    row.location = supplierCities[row.supplier];
    return row;
  });

  data = getFilteredData(data, timeFilter);
  data = flattenOrderData(data);

  const result = aggregateData(data, "location", "z", "o");

  return [
    {
      type: "mapbubble",
      name: orderStatus,
      color: colorMap[chartType],
      data: result,
      showInLegend: false,
      cursor: "pointer",
      point: {
        events: {
          click: function (event) {
            let currentLoc = orders.find(
              (item) =>
                item.location &&
                item.location.value &&
                item.location.value === event.point.location
            ).location.value;

            if (filtered) {
              setFiltered({ filter: false, data: [], location: "" });
            } else {
              let filteredData = orders.filter(
                (item) =>
                  item.location &&
                  item.location.value &&
                  item.location.value === event.point.location &&
                  orderStatusMap[orderStatus](item.orderStatus)
              );
              setFiltered({
                filter: true,
                data: filteredData,
                location: currentLoc,
              });
            }
          },
        },
      },
    },
  ];
};
