import React from "react";
import { makeStyles, Grid, Box, Typography, Divider } from "@material-ui/core";
import moment from "moment";
import CardSwiper from "../../CardsCarousel/cardsCarousel";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { formatCurrency } from "common/utils";
import RangeSlider from "components/UI/RangeSlider";
import EventComponent from "components/UI/EventComponent";
import { filterDataByTime } from "components/MainPageComponent/helpers";
import supplierAlignmentIndex from "data/supplierAlignmentIndex";
import BOMOptions from "../../BOM/BOMOptions";
import { capitaliseFirstLetter } from "../../../common/utils";

const referenceDate = localStorage.getItem("referenceDate");

const percentRenderer = ({ row }, prop) => {
  const val = row.values[prop];
  return `${(val && Number(val).toFixed()) || 0}%`;
};

const columnNames = [
  {
    Header: () => null,
    id: "expander",
    Cell: ({ row }) => (
      <span
        {...row.getToggleRowExpandedProps()}
        style={{ display: "flex", cursor: "pointer" }}
      >
        {row.isExpanded ? (
          <RemoveCircleOutlineIcon
            fontSize="small"
            style={{ color: "#4896E9" }}
          />
        ) : (
          <AddCircleOutlineIcon fontSize="small" style={{ color: "#4896E9" }} />
        )}
      </span>
    ),
  },
  {
    Header: "Order#",
    accessor: "orderNumber",
  },
  {
    Header: "Supplier",
    accessor: "supplier",
  },
  {
    Header: "Alignment Index",
    accessor: "pool[ipAlignmentIndex]",
    Cell: (row) => {
      // console.log("row: ", row);
      const data = {
        alignmentIndex: row.data[row.row.index]?.pool?.ipAlignmentIndex,
        supplierAI: supplierAlignmentIndex[row.data[row.row.index].supplier],
      };
      return <RangeSlider data={data} />;
    },
  },
  {
    Header: "Status",
    accessor: "pool.IPStatus",
  },
  {
    Header: "Site/Order Level",
    columns: [
      {
        Header: "Total Value",
        accessor: "pool.siteLevelValue",
        className: "highlight",
      },
      {
        Header: "Total Award",
        accessor: "pool.siteLevelAward",
        className: "highlight",
      },
      {
        Header: "Total %",
        accessor: "pool.siteLevelPercentage",
        className: "highlight",
        Cell: (row) => percentRenderer(row, "pool.siteLevelPercentage"),
      },
    ],
  },
  {
    Header: "Product Level",
    columns: [
      {
        Header: "Total Value",
        accessor: "pool.productLevelValue",
      },
      {
        Header: "Total Award",
        accessor: "pool.productLevelAward",
      },
      {
        Header: "Total %",
        accessor: "pool.productLevelPercentage",
        Cell: (row) => percentRenderer(row, "pool.productLevelPercentage"),
      },
    ],
  },
  {
    Header: "Pool Level",
    columns: [
      {
        Header: "Total Value",
        accessor: "pool.poolLevelValue",
        className: "highlight",
      },
      {
        Header: "Total Award",
        accessor: "pool.poolLevelAward",
        className: "highlight",
      },
      {
        Header: "Total %",
        accessor: "pool.poolLevelPercentage",
        className: "highlight",
        Cell: (row) => percentRenderer(row, "pool.poolLevelPercentage"),
      },
    ],
  },
];

const expandedTableColumns = [
  {
    Header: "Type",
    accessor: "type",
    Cell: (props) => {
      return capitaliseFirstLetter(props.value);
    },
  },
  {
    Header: "Requirement",
    accessor: "name",
  },
  {
    Header: "Incentive",
    accessor: "incentive",
  },
  {
    Header: "Award",
    accessor: "award",
  },
  {
    Header: "Evidences",
    accessor: "evidence",
    Cell: (row) => {
      let val = row.cell.value;
      if (!val) return null;
      if (!Array.isArray(val)) {
        val = [val];
      }
      const evidencecs = (val || []).map((evi, index) => {
        let fileName = evi.name || "file";
        const extnArr = evi.url && evi.url.split(".");
        fileName = `${fileName}.${extnArr[extnArr.length - 1]}`;
        return (
          <>
            <a
              target="_blank"
              className="link"
              download={fileName}
              href={evi.url}
            >
              {evi.name}
              {index < val.length - 1 ? "," : ""}
            </a>{" "}
          </>
        );
      });
      return evidencecs;
    },
  },
  {
    Header: "Target",
    accessor: "target",
  },
  {
    Header: "Actual",
    accessor: "actual",
  },
  // {
  //   Header: "Site Level Value",
  //   accessor: "siteLevelValue",
  //   Cell: (props) => formatCurrency(props.value),
  // },
  // {
  //   Header: "Site Level Award",
  //   accessor: "siteLevelAward",
  //   Cell: (props) => formatCurrency(props.value),
  // },
  {
    Header: "Event",
    accessor: "event",
    Cell: (props) => {
      return <EventComponent data={props.value} />;
    },
  },
  // {
  //   Header: "Product",
  //   accessor: "productRequirements",
  //   Cell: (row) => (row.cell.value ? "Product" : ""),
  // },
  // {
  //   Header: "Requirement",
  //   accessor: "productRequirements.name",
  // },
  // {
  //   Header: "Target",
  //   accessor: "productRequirementTarget",
  // },
  // {
  //   Header: "Actual",
  //   accessor: "productRequirementActual",
  // },
  // {
  //   Header: "Product Value",
  //   accessor: "productLevelValue",
  //   Cell: (props) => formatCurrency(props.value),
  // },
  // {
  //   Header: "Product Award",
  //   accessor: "productLevelAward",
  //   Cell: (props) => formatCurrency(props.value),
  // },
  // {
  //   Header: "Product Event",
  //   accessor: "productLevelEvent",
  //   Cell: (row) => <EventComponent data={row.cell.value} />,
  // },
];

const useStyles = makeStyles((theme) => ({
  sectionHeading: {
    fontSize: "0.875rem",
    fontWeight: "500",
    color: theme.palette.secondary.dark,
    wordBreak: "break-word",
    whiteSpace: "break-spaces",
  },
}));

const calculateSpent = (data) => {
  let total = 0;
  data = [];
  data.forEach((row) => {
    row.details.forEach((detail) => {
      total += detail.siteLevelValue;
      total += detail.productLevelValue;
    });
  });
  return formatCurrency(total);
};

const ProvenomicsPool = (props) => {
  const { drawerOpen, timeFilter, handleCardClick, orders, location } = props;
  let poolData = JSON.parse(JSON.stringify(orders));
  if (timeFilter !== "all") {
    poolData = filterDataByTime(poolData, timeFilter);
  }

  const calculateAndAppendTotal = (array = []) => {
    if (array.length > 0) {
      array.forEach((order) => {
        order.sortId = moment(order.time).format("YYYY-MM-DD");
        order.details = [...order?.pool?.requirements];
        order.pool.siteLevelPercentage =
          (order?.pool?.siteLevelAward / order?.pool?.siteLevelValue) * 100;
        order.pool.productLevelPercentage =
          (order?.pool?.productLevelAward / order?.pool?.productLevelValue) *
          100;
        order.pool.poolLevelValue =
          order?.pool?.siteLevelValue + order?.pool?.productLevelValue;
        order.pool.poolLevelAward =
          order?.pool?.siteLevelAward + order?.pool?.productLevelAward;
        order.pool.poolLevelPercentage =
          (order?.pool?.poolLevelAward / order?.pool?.poolLevelValue) * 100;
      });
    }
    return array;
  };

  const eventsDataAggregator = (array = []) => {
    const eventData = {
      totalEvents: 0,
      productEvents: 0,
      orderEvents: 0,
      siteEvents: 0,
      supplierEvents: 0,
    };
    array.forEach((el) => {
      eventData.totalEvents += el.totalEvents;
      eventData.productEvents += el.productEvents;
      eventData.orderEvents += el.orderEvents;
      eventData.siteEvents += el.siteEvents;
      eventData.supplierEvents += el.supplierEvents;
    });
    return eventData;
  };

  const aggregatedEvents = eventsDataAggregator(poolData);

  poolData = calculateAndAppendTotal(poolData);

  const classes = useStyles();
  const [slidesPerView, setslidesPerView] = React.useState(() =>
    drawerOpen ? 4 : 0
  );

  if (drawerOpen) {
    setTimeout(() => {
      setslidesPerView(4);
    }, 800);
  } else {
    setTimeout(() => {
      setslidesPerView(1);
    }, 800);
  }

  return (
    <Box className={classes.stepper}>
      <Grid
        container
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <Grid
          item
          style={{
            width: "50%",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Typography variant="h4" className={classes.sectionHeading}>
            Showing data for Buyers from {location}
          </Typography>
        </Grid>
        <Grid item style={{ width: "50%", textAlign: "right" }}>
          <BOMOptions
            label="Select BOM"
            value={props.selectedBOM}
            handleChange={props.BOMChnage}
          />
        </Grid>
      </Grid>
      <Box display="flex" flexDirection="row" alignItems="center">
        {/* {poolData && (
          <AverageView
            title={poolData.length}
            description="Total Pools"
            subTitle={"Engagement Rate"}
            color="#7FA0EA"
          />
        )} */}
        {/* <Box width={300}>
          <SplineChart
            data={generateSplineDataForProvonomics(poolData, false, timeFilter)}
          />
        </Box>
        <span className="ticker-percentage">
          10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
        </span> */}
      </Box>
      <Grid
        item
        style={{
          alignItems: "center",
          display: "flex",
          paddingTop: 16,
        }}
      >
        <Typography variant="h4" className={classes.sectionHeading}>
          {poolData.length} Total POs
        </Typography>
      </Grid>
      <CardSwiper
        disable={false}
        sideBarHeader={"Provenomics Pool"}
        onCardClick={() => {}}
        timeFilter={timeFilter}
        columns={columnNames}
        handleCardClick={(title) => handleCardClick(title, "orders")}
        expandedTableColumns={expandedTableColumns}
        data={[
          {
            title: poolData.length,
            subtitle: "Total POs",
            disable: false,
            alertIcon: false,
            rows: poolData,
            header: `${poolData.length} Total POs`,
          },
          {
            title: poolData.filter(
              (row) => row.pool.IPStatus === "Acknowledged"
            ).length,
            subtitle: "Acknowledged",
            disable: false,
            alertIcon: false,
            rows: poolData.filter(
              (row) => row.pool.IPStatus === "Acknowledged"
            ),
            header: `${
              poolData.filter((row) => row.pool.IPStatus === "Acknowledged")
                .length
            } Acknowledged`,
          },
          {
            title: poolData.filter((row) => row.pool.IPStatus === "In Progress")
              .length,
            subtitle: "In Progress",
            disable: false,
            alertIcon: false,
            rows: poolData.filter((row) => row.pool.IPStatus === "In Progress"),
            header: `${
              poolData.filter((row) => row.pool.IPStatus === "In Progress")
                .length
            } In Progress`,
          },
          {
            title: poolData.filter((row) => row.pool.IPStatus === "Closed")
              .length,
            subtitle: "Closed",
            disable: false,
            alertIcon: false,
            rows: poolData.filter((row) => row.pool.IPStatus === "Closed"),
            header: `${poolData.filter(
              (row) => row.pool.IPStatus === "Closed"
            )} Closed`,
          },
        ]}
        slidesPerView={slidesPerView}
      />
      <br />
      <Divider className="divider" />
      <br />
      {/* <Typography variant="h4" className={classes.sectionHeading}>
        Showing data for all Regions
      </Typography> */}
      <Box display="flex" flexDirection="row" alignItems="center">
        {/* {poolData && (
          <AverageView
            title={calculateSpent(poolData)}
            description="Total Spend"
            subTitle={"Application Rate "}
            color="#DD6717"
          />
        )} */}
        {/* <Box width={300}>
          <SplineChart
            data={generateSplineDataForProvonomics(poolData, true, timeFilter)}
          />
        </Box>
        <span className="ticker-percentage">
          10% <ArrowDropUpIcon style={{ color: "#3FA01C" }} />
        </span> */}
      </Box>
      <Grid
        item
        style={{
          alignItems: "center",
          display: "flex",
          paddingTop: 16,
        }}
      >
        <Typography variant="h4" className={classes.sectionHeading}>
          {aggregatedEvents.totalEvents} Total Events
        </Typography>
      </Grid>
      <CardSwiper
        disable={false}
        timeFilter={timeFilter}
        // handleCardClick={(title) => handleCardClick(title, "spend")}
        handleCardClick={() => {}}
        columns={columnNames}
        data={[
          {
            title: aggregatedEvents.totalEvents,
            subtitle: "Total Events",
            disable: true,
            alertIcon: false,
            rows: [],
            header: `${calculateSpent(
              poolData.filter((row) => row.pool.IPStatus === "Accepted")
            )} Accepted`,
          },
          {
            title: aggregatedEvents.orderEvents,
            subtitle: "Order Events",
            disable: true,
            alertIcon: false,
            rows: [],
            header: `${calculateSpent(
              poolData.filter((row) => row.pool.IPStatus === "Rejected")
            )} Rejected`,
          },
          {
            title: aggregatedEvents.productEvents,
            subtitle: "Product Events",
            disable: true,
            alertIcon: false,
            rows: [],
            header: `${calculateSpent(
              poolData.filter(
                (row) =>
                  row.filled > 100 ||
                  moment(referenceDate).diff(moment(row.soPromiseDate)) > 30
              )
            )} CLOSED`,
          },
          {
            title: aggregatedEvents.supplierEvents,
            subtitle: "Supplier Events",
            disable: true,
            alertIcon: false,
            rows: [],
            header: `${calculateSpent(
              poolData.filter((row) => row.pool.IPStatus === "Rejected")
            )} Rejected`,
          },
          {
            title: aggregatedEvents.siteEvents,
            subtitle: "Site Events",
            disable: true,
            alertIcon: false,
            rows: [],
            header: `${calculateSpent(
              poolData.filter((row) => row.pool.IPStatus === "Rejected")
            )} Rejected`,
          },
        ]}
        slidesPerView={slidesPerView}
      />
    </Box>
  );
};

export default ProvenomicsPool;
