import {
  aggregateInspectionData,
  flattenInspectionData,
  formatCurrentInspection,
  isPending,
  getFilteredInspectionData,
  filterByBom,
} from "./utils";
import supplierCities from "data/supplierCities";
const referenceDate = localStorage.getItem("referenceDate");

const inspectionStatusMap = {
  "Total Certifications": "Total Certifications",
  "Active/Passed": "pass",
  "Failed/Expired": "fail",
  "Pending Expiration": "Pending Expiration",
};

const getColor = (stat) => {
  switch (stat) {
    case "Failed/Expired":
      return "red";
    case "Pending Expiration":
      return "yellow";
    case "Active/Passed":
    case "Total Certifications":
    default:
      return "green";
  }
};

export const generateSiteInspection = (
  siteInspections,
  filters,
  selectedBOM
) => {
  const { status, timeFilter, setFiltered, filtered } = filters;
  let data = getFilteredInspectionData(siteInspections, timeFilter);
  data = filterByBom(data, selectedBOM);
  if (status !== "Total Certifications" && status !== "Pending Expiration") {
    data = data.filter((row) => row.result === inspectionStatusMap[status]);
  }
  if (status === "Pending Expiration") {
    data = data.filter((row) => {
      let formattedRow = formatCurrentInspection(row);
      return isPending(formattedRow);
    });
  }

  data = data.map((row) => {
    row.location = supplierCities[row.company];
    return row;
  });

  const flattenData = flattenInspectionData(data);
  let result = aggregateInspectionData(flattenData, "location", "z");

  return [
    {
      type: "mapbubble",
      name: "",
      data: result,
      showInLegend: false,
      cursor: "pointer",
      point: {
        events: {
          // This is a highcharts bug
          click: function (event) {
            let currData = data.find(
              (item) =>
                item.location &&
                item.location.value &&
                item.location.value === event.point.location
            );
            let currentLoc =
              currData && currData.location && currData.location.value;
            if (filtered) {
              setFiltered({ filter: false, data: [], location: "" });
            } else {
              let filteredData = data.filter(
                (item) =>
                  item.location &&
                  item.location.value &&
                  item.location.value === event.point.location
              );
              setFiltered({
                filter: true,
                data: filteredData,
                location: currentLoc,
              });
            }
          },
        },
      },
    },
  ];
};
