import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { yellow, red } from "@material-ui/core/colors";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles({
  root: {
    width: 30,
  },
});

const EventComponent = (props) => {
  const { data } = props;

  if (!(data === "Error" || data === "Warning")) return <div></div>;
  return (
    <div>
      <ErrorIcon style={{ color: data === "Error" ? red[600] : yellow[700] }} />
    </div>
  );
};
export default EventComponent;
