import * as React from 'react';

export const planetPinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9947" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_925" data-name="Group 925" transform="translate(-476 -411)">
            <g transform="matrix(1, 0, 0, 1, 476, 411)" filter="url(#Path_9947)">
                <path id="Path_9947-2" data-name="Path 9947" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9948" data-name="Path 9948" d="M35.422,71A35.5,35.5,0,0,0,35.6,0a35.5,35.5,0,1,0-.175,71Z" transform="translate(495.77 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="Planet" transform="translate(513 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">Planet</tspan></text>
            <g id="Group_549" data-name="Group 549" transform="translate(325.5 246.5)">
                <path id="Path_382" data-name="Path 382" d="M193.5,193.5h24v24h-24Z" fill="none" />
                <g id="Group_924" data-name="Group 924" transform="translate(194.5 194.5)">
                    <path id="Path_2213" data-name="Path 2213" d="M55.02,369.479h22.5v22.5H55.02Z" transform="translate(-55.02 -369.479)" fill="none" />
                    <path id="Path_2214" data-name="Path 2214" d="M66.393,371.479a9.373,9.373,0,1,0,9.373,9.373A9.373,9.373,0,0,0,66.393,371.479Zm-.937,16.806a7.488,7.488,0,0,1-6.561-7.433,7.656,7.656,0,0,1,.2-1.678l4.49,4.49v.937a1.881,1.881,0,0,0,1.875,1.875Zm6.467-2.381a1.859,1.859,0,0,0-1.781-1.3h-.937V381.79a.936.936,0,0,0-.937-.937H62.644v-1.875h1.875a.937.937,0,0,0,.937-.937v-1.875H67.33a1.881,1.881,0,0,0,1.875-1.875v-.384a7.482,7.482,0,0,1,2.718,12Z" transform="translate(-55.145 -369.604)" fill="#666" />
                </g>
            </g>
        </g>
    </svg>
);

export const peoplePinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9947" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_925" data-name="Group 925" transform="translate(-476 -411)">
            <g transform="matrix(1, 0, 0, 1, 476, 411)" filter="url(#Path_9947)">
                <path id="Path_9947-2" data-name="Path 9947" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9948" data-name="Path 9948" d="M35.422,71A35.5,35.5,0,0,0,35.6,0a35.5,35.5,0,1,0-.175,71Z" transform="translate(495.77 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="People" transform="translate(513 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">People</tspan></text>
            <g id="Group_549" data-name="Group 549" transform="translate(325.5 246.5)">
                <path id="Path_381" data-name="Path 381" d="M205.5,199.4a2.1,2.1,0,1,1-2.1,2.1,2.1,2.1,0,0,1,2.1-2.1h0m0,9c2.97,0,6.1,1.46,6.1,2.1v1.1H199.4v-1.1c0-.64,3.13-2.1,6.1-2.1m0-10.9a4,4,0,1,0,4,4A4,4,0,0,0,205.5,197.5Zm0,9c-2.67,0-8,1.34-8,4v3h16v-3C213.5,207.84,208.17,206.5,205.5,206.5Z" fill="#666" />
                <path id="Path_382" data-name="Path 382" d="M193.5,193.5h24v24h-24Z" fill="none" />
            </g>
        </g>
    </svg>
);


export const PrivacyPinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9979" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_926" data-name="Group 926" transform="translate(-619.998 -411)">
            <g transform="matrix(1, 0, 0, 1, 620, 411)" filter="url(#Path_9979)">
                <path id="Path_9979-2" data-name="Path 9979" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9980" data-name="Path 9980" d="M35.422,71.44A35.5,35.5,0,0,0,70.994,36.451,35.374,35.374,0,0,0,35.6,0C16.2-.051.263,15.11,0,35.474A35.541,35.541,0,0,0,35.422,71.44Z" transform="translate(639.5 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="Privacy" transform="translate(656 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">Privacy</tspan></text>
            <path id="Union_9" data-name="Union 9" d="M18426,20070v-6l9-4,9,4v6c0,5.549-3.836,10.74-9,12C18429.84,20080.742,18426,20075.551,18426,20070Zm2-4.7v4.7a7.432,7.432,0,1,0,14,0v-4.7l-7-3.109Zm1,6.119,1.412-1.406,2.59,2.58,6.588-6.592,1.41,1.422-8,8Z" transform="translate(-17760 -19620)" fill="#666" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
        </g>
    </svg>
);


export const pathPinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9981" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_927" data-name="Group 927" transform="translate(-763.998 -411)">
            <g transform="matrix(1, 0, 0, 1, 764, 411)" filter="url(#Path_9981)">
                <path id="Path_9981-2" data-name="Path 9981" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9982" data-name="Path 9982" d="M35.422,71.44A35.5,35.5,0,0,0,70.994,36.451,35.374,35.374,0,0,0,35.6,0C16.2-.051.263,15.11,0,35.474A35.541,35.541,0,0,0,35.422,71.44Z" transform="translate(783.5 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="Path" transform="translate(807 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">Path</tspan></text>
            <g id="Group_25" data-name="Group 25" transform="translate(579.5 213.5)">
                <path id="Path_904" data-name="Path 904" d="M245.5,231.5l-4,4h3v7a2,2,0,0,1-4,0v-7a4,4,0,0,0-8,0v7h-3l4,4,4-4h-3v-7a2,2,0,0,1,4,0v7a4,4,0,0,0,8,0v-7h3Z" fill="#666" />
                <path id="Path_905" data-name="Path 905" d="M227.5,227.5h24v24h-24Z" fill="none" />
            </g>
        </g>
    </svg>
);


export const productPinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9983" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_928" data-name="Group 928" transform="translate(-907.998 -411)">
            <g transform="matrix(1, 0, 0, 1, 908, 411)" filter="url(#Path_9983)">
                <path id="Path_9983-2" data-name="Path 9983" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9984" data-name="Path 9984" d="M35.422,71.44A35.5,35.5,0,0,0,70.994,36.451,35.374,35.374,0,0,0,35.6,0C16.2-.051.263,15.11,0,35.474A35.541,35.541,0,0,0,35.422,71.44Z" transform="translate(927.5 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="Product" transform="translate(942 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">Product</tspan></text>
            <path id="Union_10" data-name="Union 10" d="M18428,20081a2.006,2.006,0,0,1-2-2v-12a2.008,2.008,0,0,1,2-2h2a5,5,0,0,1,10,0h2a2.008,2.008,0,0,1,2,2v12a2.006,2.006,0,0,1-2,2Zm0-2h14v-12h-14Zm4-14h6a3,3,0,0,0-6,0Zm-2,3h2a3,3,0,1,0,6,0h2a5,5,0,0,1-10,0Z" transform="translate(-17472 -19619)" fill="#666" />
        </g>
    </svg>
);


export const placePinIcon = (
    <svg width="110.162" height="141.391" viewBox="0 0 110.162 141.391">
        <defs>
            <filter id="Path_9985" x="0" y="0" width="110.162" height="141.391" filterUnits="userSpaceOnUse">
                <feOffset dy="6" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="5" result="blur" />
                <feFlood floodOpacity="0.149" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="Group_929" data-name="Group 929" transform="translate(-1051.998 -411)">
            <g transform="matrix(1, 0, 0, 1, 1052, 411)" filter="url(#Path_9985)">
                <path id="Path_9985-2" data-name="Path 9985" d="M40.034,111.391A40.384,40.384,0,0,0,79.58,77.959c1.43-7.978.1-15.671-2.612-23.2a126.869,126.869,0,0,0-16.7-30.846c-5.452-7.608-11.4-14.865-17.186-22.226C41.363-.49,38.983-.555,37.241,1.459,25.856,14.615,15.572,28.547,7.709,44.143a72.705,72.705,0,0,0-7.1,20.1A40.093,40.093,0,0,0,32.6,110.552C35.043,111.014,37.554,111.12,40.034,111.391Z" transform="translate(95.16 120.39) rotate(-180)" />
            </g>
            <path id="Path_9986" data-name="Path 9986" d="M35.422,71.44A35.5,35.5,0,0,0,70.994,36.451,35.374,35.374,0,0,0,35.6,0C16.2-.051.263,15.11,0,35.474A35.541,35.541,0,0,0,35.422,71.44Z" transform="translate(1071.5 425.5)" fill="#fff" stroke="#d0d0d0" strokeWidth="1" />
            <text id="Place" transform="translate(1093 477)" fill="#666" fontSize="12" fontFamily="IBMPlexSans, IBM Plex Sans"><tspan x="0" y="0">Place</tspan></text>
            <g id="Group_555" data-name="Group 555" transform="matrix(0.999, -0.035, 0.035, 0.999, 859.84, 308.641)">
                <g id="Bounding_Boxes" data-name="Bounding Boxes" transform="translate(228.5 137.5)">
                    <path id="Path_3314" data-name="Path 3314" d="M228.5,137.5h27v27h-27Z" transform="translate(-228.5 -137.5)" fill="none" />
                </g>
                <g id="Outline" transform="translate(234.125 139.75)">
                    <g id="Group_333" data-name="Group 333">
                        <path id="Path_3315" data-name="Path 3315" d="M241.375,139.5a7.875,7.875,0,0,0-7.875,7.875c0,5.906,7.875,14.625,7.875,14.625s7.875-8.719,7.875-14.625A7.875,7.875,0,0,0,241.375,139.5Zm-5.625,7.875a5.625,5.625,0,0,1,11.25,0c0,3.24-3.24,8.089-5.625,11.115C239.035,155.486,235.75,150.581,235.75,147.375Z" transform="translate(-233.5 -139.5)" fill="#666" />
                        <circle id="Ellipse_127" data-name="Ellipse 127" cx="2.813" cy="2.813" r="2.813" transform="translate(5.062 5.063)" fill="#666" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export const linkIcon = (<svg id="baseline-link-24px" width="24" height="24" viewBox="0 0 24 24">
    <path id="Path_953" data-name="Path 953" d="M0,0H24V24H0Z" fill="none" />
    <path id="Path_954" data-name="Path 954" d="M3.9,12A3.1,3.1,0,0,1,7,8.9h4V7H7A5,5,0,0,0,7,17h4V15.1H7A3.1,3.1,0,0,1,3.9,12ZM8,13h8V11H8Zm9-6H13V8.9h4a3.1,3.1,0,1,1,0,6.2H13V17h4A5,5,0,0,0,17,7Z" />
</svg>);


export const healthIcon = (<svg width="30.441" height="24.94" viewBox="0 0 30.441 24.94">
    <g id="Group_983" data-name="Group 983" transform="translate(-2532.389 -5728.407)">
        <path id="Path_10047" data-name="Path 10047" d="M2534.553,5741.272a.457.457,0,0,1-.354-.165,7.6,7.6,0,0,1,.761-10.609,8.294,8.294,0,0,1,11.01,0l1.638,1.5,1.637-1.5a8.293,8.293,0,0,1,11.01,0,7.593,7.593,0,0,1,2.336,7.559.461.461,0,0,1-.894-.229,6.685,6.685,0,0,0-2.066-6.651,7.36,7.36,0,0,0-9.764,0l-1.948,1.789a.463.463,0,0,1-.624,0l-1.95-1.789a7.358,7.358,0,0,0-9.764,0,6.688,6.688,0,0,0-.677,9.339.461.461,0,0,1-.353.757" transform="translate(0 0)" fill="#222" />
        <path id="Path_10048" data-name="Path 10048" d="M2547.941,5754.36a.459.459,0,0,1-.312-.122l-10.806-9.916a.461.461,0,0,1,.624-.68l10.494,9.631,12.024-11.036a7.637,7.637,0,0,0,.61-.629.461.461,0,1,1,.7.6,8.1,8.1,0,0,1-.685.706l-12.335,11.321a.46.46,0,0,1-.312.122" transform="translate(-0.333 -1.013)" fill="#222" />
        <path id="Path_10049" data-name="Path 10049" d="M2545.993,5745.867a.455.455,0,0,1-.351-.163l-3.091-3.646h-9.7a.461.461,0,0,1,0-.923h9.915a.458.458,0,0,1,.352.164l2.769,3.267,3.381-7.046a.465.465,0,0,1,.389-.26.45.45,0,0,1,.416.213l2.324,3.662h9.968a.461.461,0,0,1,0,.923h-10.222a.46.46,0,0,1-.389-.215l-2.014-3.174-3.33,6.938a.459.459,0,0,1-.416.261" transform="translate(0 -0.688)" fill="#222" />
    </g>
</svg>)

export const personCardIcon = (<svg width="30.041" height="23.718" viewBox="0 0 30.041 23.718">
    <g id="Group_980" data-name="Group 980" transform="translate(-2136.088 -3773.728)">
        <path id="Path_10032" data-name="Path 10032" d="M2162.481,3798.2h-24.272a2.123,2.123,0,0,1-2.121-2.12v-17.95a.405.405,0,1,1,.81,0v17.95a1.311,1.311,0,0,0,1.31,1.309h24.272a.405.405,0,1,1,0,.811" transform="translate(0 -0.757)" fill="#514051" />
        <path id="Path_10033" data-name="Path 10033" d="M2164.859,3794.2h-22.743a2.028,2.028,0,0,1-2.027-2.027v-16.422a2.029,2.029,0,0,1,2.027-2.027h22.743a2.03,2.03,0,0,1,2.027,2.027v16.422a2.029,2.029,0,0,1-2.027,2.027m-22.743-19.665a1.217,1.217,0,0,0-1.216,1.216v16.422a1.216,1.216,0,0,0,1.216,1.216h22.743a1.216,1.216,0,0,0,1.216-1.216v-16.422a1.217,1.217,0,0,0-1.216-1.216Z" transform="translate(-0.758 0)" fill="#514051" />
        <path id="Path_10034" data-name="Path 10034" d="M2161.859,3774.539H2147.93a.406.406,0,0,1,0-.811h13.929a.406.406,0,0,1,0,.811" transform="translate(-2.165 0)" fill="#514051" />
        <path id="Path_10035" data-name="Path 10035" d="M2154.859,3791.308h-9.537a.405.405,0,0,1-.405-.405v-1.085a1.769,1.769,0,0,1,1.007-1.591l2.147-1.32a3.118,3.118,0,0,1-1.158-2.3l-.081-2.137a2.961,2.961,0,0,1,2.975-2.95h.567a2.959,2.959,0,0,1,2.976,2.934l-.082,2.153a3.113,3.113,0,0,1-1.161,2.3l2.187,1.338a1.748,1.748,0,0,1,.971,1.571v1.084a.406.406,0,0,1-.405.405m-9.132-.811h8.726v-.678a.951.951,0,0,0-.544-.858l-2.774-1.7a.406.406,0,0,1,.031-.709,2.322,2.322,0,0,0,1.292-1.979l.082-2.137a2.146,2.146,0,0,0-2.166-2.108h-.567a2.148,2.148,0,0,0-2.165,2.123l.081,2.122a2.32,2.32,0,0,0,1.289,1.977.406.406,0,0,1,.223.345.4.4,0,0,1-.193.362l-2.732,1.677a.976.976,0,0,0-.582.879Z" transform="translate(-1.671 -1.097)" fill="#514051" />
        <path id="Path_10036" data-name="Path 10036" d="M2166.623,3782.661h-5.008a.405.405,0,0,1,0-.811h5.008a.405.405,0,0,1,0,.811" transform="translate(-4.755 -1.537)" fill="#514051" />
        <path id="Path_10037" data-name="Path 10037" d="M2166.623,3787.1h-5.008a.405.405,0,0,1,0-.811h5.008a.405.405,0,0,1,0,.811" transform="translate(-4.755 -2.378)" fill="#514051" />
        <path id="Path_10038" data-name="Path 10038" d="M2166.623,3791.541h-5.008a.405.405,0,0,1,0-.811h5.008a.405.405,0,0,1,0,.811" transform="translate(-4.755 -3.218)" fill="#514051" />
    </g>
</svg>)

export const safetyIcon = (<svg id="safety-1" width="28" height="32.958" viewBox="0 0 28 32.958">
<path id="Path_3" data-name="Path 3" d="M420.89,367.93h-27a.5.5,0,0,0-.5.5v11.45c0,15.66,13.69,21,13.83,21a.47.47,0,0,0,.17,0,.569.569,0,0,0,.18,0c.14-.06,13.82-5.36,13.82-21V368.43A.5.5,0,0,0,420.89,367.93Zm-.5,11.95c0,14-11.43,19.34-13,20-1.56-.66-13-6-13-20V368.93h26Z" transform="translate(-393.39 -367.93)" fill="#514051"/>
<path id="Path_4" data-name="Path 4" d="M405.44,383.35v2.86a2,2,0,0,0,3.91,0v-2.86a3.64,3.64,0,1,0-3.91,0Zm1.95-5.69a2.63,2.63,0,0,1,1.22,5,.5.5,0,0,0-.26.44v3.15a1,1,0,0,1-1.91,0V383.1a.5.5,0,0,0-.27-.44,2.63,2.63,0,0,1,1.22-5Z" transform="translate(-393.39 -367.93)" fill="#514051"/>
</svg>)

export const emissionsIcon = (<svg width="25.975" height="25.975" viewBox="0 0 25.975 25.975"> 
    <g id="Group_1044" data-name="Group 1044" transform="translate(-16.002 -16)">
        <path id="Path_10053" data-name="Path 10053" d="M61.628,16a2.158,2.158,0,0,0-1.895,1.132,2.595,2.595,0,1,0-1.568,4.89v.9h.866v-.866h1.3v-.866H58.6a1.732,1.732,0,1,1,0-3.463,1.731,1.731,0,0,1,1.732,1.732h.866a2.572,2.572,0,0,0-.754-1.826,1.3,1.3,0,0,1,2.486.528h.866A2.167,2.167,0,0,0,61.628,16ZM59.9,17.72l-.009.012Z" transform="translate(-37.834)" fill="#514051" />
        <path id="Path_10054" data-name="Path 10054" d="M350.494,16a1.712,1.712,0,0,0-1.407.729,2.527,2.527,0,0,0-1.19-.3,2.6,2.6,0,0,0-2.566,2.217,1.731,1.731,0,0,0-1.33,1.68h.866a.866.866,0,1,1,1.732,0h.866a1.729,1.729,0,0,0-1.26-1.658A1.734,1.734,0,0,1,347.9,17.3a1.706,1.706,0,0,1,1.086.4.433.433,0,0,0,.688-.21.858.858,0,0,1,.823-.618.867.867,0,0,1,.866.866h.866A1.733,1.733,0,0,0,350.494,16Z" transform="translate(-310.249)" fill="#514051" />
        <path id="Path_10055" data-name="Path 10055" d="M37.128,132.278a2.667,2.667,0,0,0,.515.05,2.6,2.6,0,0,0,2.565-2.164,1.768,1.768,0,0,0,1.143-.415,1.676,1.676,0,0,0,.625-1.317h-.866a.8.8,0,0,1-.291.634.818.818,0,0,1-.868.175.433.433,0,0,0-.581.423c0,.015.006.051.008.066a1.734,1.734,0,0,1-2.085,1.7,1.783,1.783,0,0,1-1.378-1.79v-.123a1.515,1.515,0,0,0-3.03,0V130.6H31.154v-2.165h-.866V130.6h-.866a.433.433,0,0,0-.433.433V132.6a14,14,0,0,1-.471,3.6l-.861,2.98-.792-2.972a14,14,0,0,1-.473-3.609v-2.436a.433.433,0,0,0-.433-.433H19.9a.433.433,0,0,0-.433.433V132.6a14,14,0,0,1-.473,3.609l-2.975,11.159a.433.433,0,0,0,.418.545H41.544a.433.433,0,0,0,.433-.433v-1.732a.432.432,0,0,0-.127-.306l-2.165-2.165a.432.432,0,0,0-.306-.127H37.53l-2.007-6.941a14.008,14.008,0,0,1-.473-3.61V131.03a.433.433,0,0,0-.433-.433h-.866v-1.082a.649.649,0,0,1,1.3,0v.123a2.65,2.65,0,0,0,2.078,2.64ZM17,147.048l2.83-10.615a14.883,14.883,0,0,0,.38-1.94l.085-.866c.02-.288.029-.577.032-.866l.005-.866v-1.3h5.195v2a14.872,14.872,0,0,0,.5,3.833l1.163,4.361-.021.072.044.013,1.645,6.169Zm11.948-3.03H39.284l2.036,1.925v1.1H29.755Zm5.742-7.576,1.939,6.71H28.716l-.626-2.349,1.263-4.37a14.819,14.819,0,0,0,.312-1.507l.111-.866q.043-.432.06-.866l.019-.866v-.866h4.329V132.6a14.874,14.874,0,0,0,.5,3.841Z" transform="translate(0 -105.939)" fill="#514051" />
        <path id="Path_10056" data-name="Path 10056" d="M184,68.751h.866a1.716,1.716,0,0,1,.346-1.039l-.692-.52A2.576,2.576,0,0,0,184,68.751Z" transform="translate(-158.907 -48.422)" fill="#514051" />
        <path id="Path_10057" data-name="Path 10057" d="M175.3,28.289a1.708,1.708,0,0,1-1.442-.341.433.433,0,0,0-.7.261,1.725,1.725,0,0,1-1.027,1.283.761.761,0,0,1-.819-.19l-.487-.487A1.657,1.657,0,0,0,168,29.987v.94h.866v-.94a.792.792,0,0,1,1.352-.56l.487.487a1.632,1.632,0,0,0,1.769.374,2.589,2.589,0,0,0,1.361-1.34,2.544,2.544,0,0,0,1.646.187,2.6,2.6,0,1,0-2.926-3.59,2.528,2.528,0,0,0-1.091-.246v.866a1.709,1.709,0,0,1,1.069.381.433.433,0,0,0,.695-.261,1.731,1.731,0,1,1,2.072,2Z" transform="translate(-143.773 -7.567)" fill="#514051" />
    </g>
</svg>)

export const surveyIcon = (<svg width="21.706" height="30.111" viewBox="0 0 21.706 30.111">
    <g id="Group_978" data-name="Group 978" transform="translate(-2242.331 -3773.301)">
        <path id="Path_10021" data-name="Path 10021" d="M2256.806,3780.48h-7.244a.5.5,0,0,1-.5-.5v-2.557a4.122,4.122,0,1,1,8.244,0v2.557a.5.5,0,0,1-.5.5m-6.744-1h6.244v-2.057a3.122,3.122,0,1,0-6.244,0Z" fill="#514051" />
        <path id="Path_10022" data-name="Path 10022" d="M2259.065,3798.594H2247.3a.5.5,0,0,1-.5-.5v-1.339a2.182,2.182,0,0,1,1.243-1.962l2.648-1.628a3.839,3.839,0,0,1-1.428-2.836l-.1-2.635a3.653,3.653,0,0,1,3.671-3.639h.7a3.651,3.651,0,0,1,3.671,3.619l-.1,2.655a3.841,3.841,0,0,1-1.432,2.838l2.7,1.652a2.153,2.153,0,0,1,1.2,1.937v1.338a.5.5,0,0,1-.5.5m-11.263-1h10.763v-.838a1.177,1.177,0,0,0-.67-1.058l-3.423-2.091a.5.5,0,0,1,.038-.874,2.864,2.864,0,0,0,1.594-2.443l.1-2.635a2.646,2.646,0,0,0-2.672-2.6h-.7a2.649,2.649,0,0,0-2.672,2.619l.1,2.616a2.864,2.864,0,0,0,1.589,2.44.5.5,0,0,1,.038.873l-3.371,2.068a1.207,1.207,0,0,0-.718,1.084Z" fill="#514051" />
        <path id="Path_10023" data-name="Path 10023" d="M2261.537,3803.412h-16.706a2.5,2.5,0,0,1-2.5-2.5v-21.148a2.5,2.5,0,0,1,2.5-2.5h3.731a.5.5,0,0,1,0,1h-3.731a1.5,1.5,0,0,0-1.5,1.5v21.148a1.5,1.5,0,0,0,1.5,1.5h16.706a1.5,1.5,0,0,0,1.5-1.5v-21.148a1.5,1.5,0,0,0-1.5-1.5h-3.476a.5.5,0,0,1,0-1h3.476a2.5,2.5,0,0,1,2.5,2.5v21.148a2.5,2.5,0,0,1-2.5,2.5" fill="#514051" />
        <path id="Path_10024" data-name="Path 10024" d="M2242.831,3797.577a.5.5,0,0,1-.5-.5V3783.6a.5.5,0,0,1,1,0v13.478a.5.5,0,0,1-.5.5" fill="#514051" />
    </g>
</svg>)

export const waterIcon = (<svg width="22.919" height="26.357" viewBox="0 0 22.919 26.357">
    <g id="_1218_Aqua_Blood_Drop_Water" data-name="1218, Aqua, Blood, Drop, Water" transform="translate(-56 -26)">
        <g id="Group_1045" data-name="Group 1045" transform="translate(56 26)">
            <path id="Path_10058" data-name="Path 10058" d="M71.395,26.289a.573.573,0,0,0-1,0,126.074,126.074,0,0,0-6.1,12.287c-1.093-2.054-2.067-3.684-2.078-3.7a.573.573,0,0,0-.983,0c-.013.022-1.314,2.2-2.6,4.694C56.889,42.979,56,45.354,56,46.627a5.727,5.727,0,0,0,9.994,3.822,7.545,7.545,0,0,0,4.9,1.876c4.47,0,8.022-4.172,8.022-8.563,0-4.217-7.216-16.933-7.524-17.473ZM61.73,51.211a4.589,4.589,0,0,1-4.584-4.584c0-1.982,2.96-7.516,4.584-10.328,1.624,2.812,4.584,8.346,4.584,10.328A4.589,4.589,0,0,1,61.73,51.211Zm9.167-.032a6.423,6.423,0,0,1-4.224-1.658,5.694,5.694,0,0,0,.786-2.894c0-1.228-.827-3.48-2.458-6.7a108.818,108.818,0,0,1,5.9-12.181c.678,1.225,1.951,3.572,3.212,6.135,3.028,6.151,3.663,8.768,3.663,9.879,0,3.951-3.213,7.417-6.876,7.417Z" transform="translate(-56 -26)" fill="#514051" />
            <path id="Path_10059" data-name="Path 10059" d="M334.764,340.219a.573.573,0,0,0-.729.354,7.315,7.315,0,0,1-3.146,3.957.573.573,0,1,0,.58.988,8.46,8.46,0,0,0,3.649-4.571.573.573,0,0,0-.354-.729Z" transform="translate(-314.872 -322.186)" fill="#514051" />
        </g>
    </g>
</svg>)

export const payrollIcon = (<svg width="28.006" height="27.125" viewBox="0 0 28.006 27.125">
    <g id="Group_979" data-name="Group 979" transform="translate(-843.273 -3470.089)">
        <path id="Path_10025" data-name="Path 10025" d="M858.773,3497.214h-15a.5.5,0,0,1-.5-.5v-6.53a.5.5,0,0,1,1,0v6.03h14.5a.5.5,0,0,1,0,1" fill="#514051" />
        <path id="Path_10026" data-name="Path 10026" d="M853.934,3490.683H843.773a.5.5,0,0,1-.5-.5v-6.531a.5.5,0,0,1,1,0v6.031h9.661a.5.5,0,0,1,0,1" fill="#514051" />
        <path id="Path_10027" data-name="Path 10027" d="M855.108,3484.151H843.773a.5.5,0,0,1-.5-.5v-6.53a.5.5,0,0,1,.5-.5h15a.5.5,0,0,1,.5.5v3.448a.5.5,0,0,1-1,0v-2.948h-14v5.53h10.835a.5.5,0,0,1,0,1" fill="#514051" />
        <path id="Path_10028" data-name="Path 10028" d="M858.773,3477.62h-15a.5.5,0,0,1-.5-.5v-6.531a.5.5,0,0,1,.5-.5h15a.5.5,0,0,1,.5.5v6.531a.5.5,0,0,1-.5.5m-14.5-1h14v-5.531h-14Z" fill="#514051" />
        <path id="Path_10029" data-name="Path 10029" d="M862.357,3497.214a8.923,8.923,0,1,1,8.922-8.922,8.933,8.933,0,0,1-8.922,8.922m0-16.845a7.923,7.923,0,1,0,7.922,7.923,7.932,7.932,0,0,0-7.922-7.923" fill="#514051" />
        <path id="Path_10030" data-name="Path 10030" d="M862.507,3492.169a5.394,5.394,0,0,1-2.813-1.053.5.5,0,1,1,.621-.783,4.428,4.428,0,0,0,2.192.836c.593,0,1.7-.325,1.7-1.107,0-.668-.676-.936-1.63-1.242l-.292-.094c-.806-.273-2.482-.837-2.482-2.244a2.3,2.3,0,0,1,2.582-2.25,4.169,4.169,0,0,1,2.521.82.5.5,0,0,1-.638.77,3.219,3.219,0,0,0-1.883-.59c-.99,0-1.582.467-1.582,1.25,0,.609.964,1.012,1.8,1.3l.278.091c.926.3,2.325.743,2.325,2.194s-1.58,2.107-2.7,2.107" fill="#514051" />
        <path id="Path_10031" data-name="Path 10031" d="M862.387,3493.235a.5.5,0,0,1-.5-.5v-8.887a.5.5,0,0,1,1,0v8.887a.5.5,0,0,1-.5.5" fill="#514051" />
    </g>
</svg>)


export const drawerToggle = (<svg width="42" height="42" viewBox="0 0 42 42">
    <defs>
        <filter id="Ellipse_304" x="0" y="0" width="42" height="42" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha" />
            <feGaussianBlur stdDeviation="3" result="blur" />
            <feFlood floodOpacity="0.161" />
            <feComposite operator="in" in2="blur" />
            <feComposite in="SourceGraphic" />
        </filter>
    </defs>
    <g id="Group_1066" data-name="Group 1066" transform="translate(14220.258 20700.258)">
        <g transform="matrix(1, 0, 0, 1, -14220.26, -20700.26)" filter="url(#Ellipse_304)">
            <circle id="Ellipse_304-2" data-name="Ellipse 304" cx="12" cy="12" r="12" transform="translate(9 6)" fill="#526161" />
        </g>
        <g id="Group_914" data-name="Group 914" transform="translate(-14203.573 -20684.373)">
            <line id="Line_22" data-name="Line 22" x2="8.34" fill="none" stroke="#fff" strokeWidth="1" />
            <line id="Line_23" data-name="Line 23" x2="8.34" transform="translate(0 2.251)" fill="none" stroke="#fff" strokeWidth="1" />
            <line id="Line_24" data-name="Line 24" x2="8.34" transform="translate(0 4.878)" fill="none" stroke="#fff" strokeWidth="1" />
        </g>
    </g>
</svg>)

export const lockIcon = (<svg width="16.184" height="16.184" viewBox="0 0 16.184 16.184">
    <path id="Path_6676" data-name="Path 6676" d="M0,0H16.184V16.184H0Z" fill="none" />
    <path id="Path_6677" data-name="Path 6677" d="M13.44,5.72h-.674V4.372a3.372,3.372,0,0,0-6.743,0V5.72H5.349A1.353,1.353,0,0,0,4,7.069v6.743a1.353,1.353,0,0,0,1.349,1.349H13.44a1.353,1.353,0,0,0,1.349-1.349V7.069A1.353,1.353,0,0,0,13.44,5.72ZM9.395,11.789a1.349,1.349,0,1,1,1.349-1.349A1.353,1.353,0,0,1,9.395,11.789Zm2.09-6.069H7.3V4.372a2.09,2.09,0,0,1,4.181,0Z" transform="translate(-1.303 -0.326)" fill="#01d131" />
</svg>)

export const lockOpenIcon = (<svg width="16" height="16" viewBox="0 0 16 16">
    <path id="Path_6772" data-name="Path 6772" d="M16,0H0V16H16Z" fill="none" />
    <path id="Path_6773" data-name="Path 6773" d="M9.333,11.667a1.333,1.333,0,1,1,1.333-1.333A1.337,1.337,0,0,1,9.333,11.667Zm-4-6H6V4.333a3.333,3.333,0,1,1,6.667,0H11.4a2.067,2.067,0,0,0-4.133,0V5.667h6.067A1.337,1.337,0,0,1,14.667,7v6.667A1.337,1.337,0,0,1,13.333,15h-8A1.337,1.337,0,0,1,4,13.667V7A1.337,1.337,0,0,1,5.333,5.667Zm0,8h8V7h-8Z" transform="translate(-1.333 -0.333)" fill="#d80000" />
</svg>)
