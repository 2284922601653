import React, { useState, useEffect } from "react";
import SelectComponent from "../UI/SelectComponent/index";
import { billOfMaterials } from "../BOM/BOMColumns";

const BOMOptions = ({
  label,
  value,
  handleChange,
  className,
  displayAll = true,
}) => {
  const [val, setValue] = useState(
    value || displayAll ? "All" : billOfMaterials[0].id
  );
  const selectionChange = (evt) => {
    if (handleChange) handleChange(evt);
    setValue(evt.target.value);
  };

  useEffect(() => {
    if (value) {
      setValue(value);
    }
  }, [value]);

  const getOptions = () => {
    const options = billOfMaterials.map((item) => {
      return { value: item.id, label: item.name };
    });
    if (displayAll) {
      options.unshift({ label: "All", value: "All" });
    }

    return options;
  };

  return (
    <SelectComponent
      className={className || ""}
      options={getOptions()}
      handleChange={selectionChange}
      selectLabel={label}
      value={val}
    />
  );
};

export default BOMOptions;
