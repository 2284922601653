import moment from "moment";
import momentRandom from "moment-random";
const referenceDate = localStorage.getItem("referenceDate");
const pickRandomDate = (week) => {
  const startDays = week * 7 + 7;
  const endDays = week * 7;
  const startDate = moment(referenceDate).subtract(startDays, "days");
  const endDate = moment(referenceDate).subtract(endDays, "days");
  return momentRandom(endDate, startDate);
};

export const pickRandomDateBetween = (date, startDays, endDays) => {
  const startDate = moment(date).add(startDays, "days");
  const endDate = moment(date).add(endDays, "days");
  return momentRandom(endDate, startDate);
};

const pickRandomAge = (start) => {
  return Math.floor(Math.random() * 20) + Number.parseInt(start);
};

export const pickRandomnNumber = (start, end) => {
  return (
    Math.floor(
      Math.random() * (Number.parseInt(end) - Number.parseInt(start))
    ) + Number.parseInt(start)
  );
};

export const pickRandomFloat = (start, end) => {
  if (start < 0) {
    return (Math.random() - 0.5) * (end - start);
  }
  return Math.random() * (end - start) + start;
};

export const randomizer = (values) => {
  let i,
    pickedValue,
    randomNr = Math.random(),
    threshold = 0;

  for (i = 0; i < values.length; i++) {
    if (values[i].probability === "*") {
      continue;
    }

    threshold += values[i].probability;
    if (threshold > randomNr) {
      pickedValue = values[i];
      break;
    }

    if (!pickedValue) {
      //nothing found based on probability value, so pick element marked with wildcard
      pickedValue = values.filter((value) => value.probability === "*");
    }
  }

  return pickedValue;
};

export const createDateArray = (selectedObj) => {
  return Object.keys(selectedObj).map((key) => {
    return {
      date: pickRandomDate(key),
      probability: selectedObj[key],
    };
  });
};

export const createAgeArray = (selectedObj) => {
  return Object.keys(selectedObj).map((key) => {
    return {
      age: pickRandomAge(key),
      probability: selectedObj[key],
    };
  });
};

export const objectToProbabilityArray = (obj) => {
  return Object.keys(obj).map((key) => {
    return { value: key, probability: obj[key] };
  });
};

export const objectToValueProbabilityArray = (obj) => {
  return Object.keys(obj).map((key) => {
    return { value: key, probability: obj[key] };
  });
};

export const pickRandomValue = (options) => {
  const optionsProbArray = objectToValueProbabilityArray(options);
  const pickedValue = randomizer(optionsProbArray).value;
  return pickedValue;
};
