import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import Select from "react-select";
import options from "data/cities-us.json";
import Button from "@material-ui/core/Button";
import {
  randomizer,
  createDateArray,
  objectToProbabilityArray,
  createAgeArray,
  pickRandomValue,
  pickRandomDateBetween,
  pickRandomnNumber,
  objectToValueProbabilityArray,
} from "./utils";
import csv from "csv";

const downloadJsonFile = () => {
  const element = document.createElement("a");
  const file = new Blob([localStorage.getItem("ordersData")], {
    type: "text/plain;charset=utf-8",
  });
  element.href = URL.createObjectURL(file);
  element.download = "orderData.json";
  document.body.appendChild(element);
  element.click();

  const element1 = document.createElement("a");
  const file1 = new Blob([localStorage.getItem("filters")], {
    type: "text/plain;charset=utf-8",
  });
  element1.href = URL.createObjectURL(file1);
  element1.download = "filters.json";
  document.body.appendChild(element1);
  element1.click();
};

const uploadFile = (event) => {
  let reader = new FileReader();
  let file = event.target.files[0];
  if (file) {
    let data = new FormData();
    reader.readAsText(file);
    data.append("file", file);
  }
  reader.onload = function (event) {
    // The file's text will be printed here
    csv.parse(event.target.result, (err, data) => {
      localStorage.setItem("siteInspection", JSON.stringify(data));
    });
  };
};

const GenerateData = (filters) => {
  const {
    brand,
    product,
    supplier,
    totalOrders,
    totalSpent,
    locations,
    weeksData,
    orderPercentage,
    spent,
    soConfirmedDateRange,
    poRequestedDateRange,
    soPromiseDateRange,
    soDelayPeriod,
    units,
    unitPrice,
    poQtyRange,
    soQtyRange,
    filledProbability,
    siteLevelValue,
    productRequirementTarget,
    productRequirementActual,
    productLevelValue,
    productEvents,
    productRequirements,
    productStatus,
    siteRequirementTarget,
    IPAI,
    IPStatus,
    requirements,
  } = filters;

  const spentPerOrder = totalSpent / totalOrders;
  let ordersData = [];

  for (let i = 0; i < totalOrders; i++) {
    const time = randomizer(createDateArray(weeksData));
    const poQty = pickRandomnNumber(poQtyRange.min, poQtyRange.max);
    const poRequestDate = pickRandomDateBetween(
      time.date,
      poRequestedDateRange.min,
      poRequestedDateRange.max
    );

    ordersData.push({
      orderNumber: Number.parseInt(Math.random() * 10000),
      location: randomizer(locations),
      time: time.date,
      soConfirmedDate: pickRandomDateBetween(
        time.date,
        soConfirmedDateRange.min,
        soConfirmedDateRange.max
      ),
      soPromiseDate:
        Math.random() < soPromiseDateRange.probability
          ? pickRandomDateBetween(
              poRequestDate,
              soPromiseDateRange.min,
              soPromiseDateRange.max
            )
          : poRequestDate,
      poRequestedDate: poRequestDate,
      units,
      unitPrice,
      filled: pickRandomnNumber(filledProbability.min, filledProbability.max),
      soDelayPeriod,
      spent: poQty * unitPrice,
      poQty: poQty,
      soQty:
        Math.random() > soQtyRange.changed
          ? poQty - pickRandomnNumber(soQtyRange.min, soQtyRange.max)
          : poQty,
      orderStatus: randomizer(objectToProbabilityArray(orderPercentage)).value,
      product,
      supplier,
      brand: brand,
      pool: {
        productRequirements: Object.keys(productRequirements.requirements).map(
          (key) => productRequirements.requirements[key]
        ),
        siteRequirements: Object.keys(requirements.requirements).map((key) => {
          return {
            name: requirements.requirements[key].name,
            pin: requirements.requirements[key].pin,
          };
        }),
        ipAlignmentIndex: IPAI,
        IPStatus: pickRandomValue(IPStatus),
        productEvents: productEvents.events,
        productStatus,
        siteRequirementTarget,
        siteLevelValue,
        productRequirementTarget,
        productRequirementActual,
        productLevelValue,
      },
    });
  }
  const oldOrdersData = JSON.parse(localStorage.getItem("ordersData")) || [];
  oldOrdersData.push(...ordersData);
  alert("Data Generated, Please export data");
  localStorage.setItem("filters", JSON.stringify(filters));
  localStorage.setItem("ordersData", JSON.stringify(oldOrdersData));
};

const DataGenerator = (props) => {
  const [locations, setLocations] = useState([]);
  const [orderPercentage, setOrderPercentage] = useState({
    confirmedLag: 0,
    unconfirmedLag: 0,
    confirmed: 0,
    unconfirmed: 0,
  });
  const [spent, setSpent] = useState({
    confirmed: 0,
    submitted: 0,
    unconfirmed: 0,
    warning: 0,
  });
  const [soConfirmedDateRange, setsoConfirmedDateRange] = useState({
    min: 0,
    max: 0,
  });
  const [poRequestedDateRange, setPoConfirmedDateRange] = useState({
    min: 0,
    max: 0,
  });
  const [soPromiseDateRange, setSoPromiseDateRange] = useState({
    probability: 0,
    min: 0,
    max: 0,
  });

  const [poQtyRange, setPoQtyRangeChange] = useState({
    min: 0,
    max: 0,
  });

  const [soQtyRange, setSoQtyRange] = useState({
    changed: 0,
    min: 0,
    max: 0,
  });

  const [soDelayPeriod, setSoDelayPeriod] = React.useState("");
  const [units, setUnits] = useState(0);
  const [unitPrice, setUnitPrice] = useState(0);
  const [Currency, setCurrency] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalSpent, setTotalSpent] = useState(0);
  const [brand, setBrand] = useState("");
  const [product, setProduct] = useState("");
  const [supplier, setSupplier] = useState("");
  const [weeks, setWeeks] = useState(0);
  const [weeksData, setWeeksData] = useState({});
  const [filledProbability, setFilledProbability] = useState({
    min: 0,
    max: 0,
  });
  const [productRequirements, setProductRequirements] = useState({
    total: 0,
    requirements: {},
  });

  const [productEvents, setProductEvents] = useState({
    total: 0,
    events: {},
  });
  const [requirements, setRequirements] = useState({
    total: 0,
    requirements: {},
  });

  const [productStatus, setProductStatus] = useState({
    Pass: 0,
    Fail: 0,
  });

  const [IPAI, setIPAI] = useState({
    min: 0,
    max: 0,
  });
  const [siteRequirementTarget, setsiteRequirementTarget] = useState({
    min: 0,
    max: 0,
  });

  const [siteLevelValue, setsiteLevelValue] = useState({
    min: 0,
    max: 0,
  });
  const [productRequirementTarget, setProductRequirementTarget] = useState({
    min: 0,
    max: 0,
  });
  const [productRequirementActual, setProductRequirementActual] = useState({
    min: 0,
    max: 0,
  });

  const [productLevelValue, setProductLevelValue] = useState({
    min: 0,
    max: 0,
  });
  const [IPStatus, setIPStatus] = useState({
    Accepted: 0,
    Rejected: 0,
  });
  const handleProductEventsChange = (name, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].name = name)
      : (eventsClone.events[index] = { name });
    setProductEvents(eventsClone);
  };
  const handleProductEventsTypeChange = (type, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].type = type)
      : (eventsClone.events[index] = { type });
    setProductEvents(eventsClone);
  };

  const handleProductEventsProbabilityChange = (probability, index) => {
    const eventsClone = JSON.parse(JSON.stringify(productEvents));
    eventsClone.events[index]
      ? (eventsClone.events[index].probability = probability)
      : (eventsClone.events[index] = { probability });
    setProductEvents(eventsClone);
  };
  const handleProductStatusChange = (status, probability) => {
    const productStautsClone = JSON.parse(JSON.stringify(productStatus));
    productStautsClone[status] = probability;
    setProductStatus(productStautsClone);
  };

  const handleIPStatusChange = (status, probability) => {
    const IPStautsClone = JSON.parse(JSON.stringify(IPStatus));
    IPStautsClone[status] = probability;
    setIPStatus(IPStautsClone);
  };

  const handleOrderPercentageChange = (order, probability) => {
    const OrderPercentageClone = JSON.parse(JSON.stringify(orderPercentage));
    OrderPercentageClone[order] = Number.parseInt(probability) / 100;
    setOrderPercentage(OrderPercentageClone);
  };

  const handleSpentChange = (spend, probability) => {
    const SpentClone = JSON.parse(JSON.stringify(spent));
    SpentClone[spend] = Number.parseInt(probability);
    setSpent(SpentClone);
  };

  const handlesoConfirmedDateRangeChange = (range, probability) => {
    const soConfirmedDateRangeClone = JSON.parse(
      JSON.stringify(soConfirmedDateRange)
    );
    soConfirmedDateRangeClone[range] = Number.parseInt(probability);
    setsoConfirmedDateRange(soConfirmedDateRangeClone);
  };

  const handlePoConfirmedDateRangeChange = (range, probability) => {
    const poRequestedDateRangeClone = JSON.parse(
      JSON.stringify(poRequestedDateRange)
    );
    poRequestedDateRangeClone[range] = Number.parseInt(probability);
    setPoConfirmedDateRange(poRequestedDateRangeClone);
  };

  const handlePoPromiseDateRangeChange = (range, probability) => {
    const SoPromiseDateRangeClone = JSON.parse(
      JSON.stringify(soPromiseDateRange)
    );
    SoPromiseDateRangeClone[range] = probability;
    setSoPromiseDateRange(SoPromiseDateRangeClone);
  };

  const handlePoQtyRangeChange = (range, probability) => {
    const poQtyRangeClone = JSON.parse(JSON.stringify(poQtyRange));
    poQtyRangeClone[range] = Number.parseInt(probability);
    setPoQtyRangeChange(poQtyRangeClone);
  };

  const handleFilledRangeChange = (range, probability) => {
    const filledProbabilityClone = JSON.parse(
      JSON.stringify(filledProbability)
    );
    filledProbabilityClone[range] = Number.parseInt(probability);
    setFilledProbability(filledProbabilityClone);
  };

  const handleSoQtyChange = (range, probability) => {
    const soQtyRangeClone = JSON.parse(JSON.stringify(soQtyRange));
    soQtyRangeClone[range] = Number.parseInt(probability);
    setSoQtyRange(soQtyRangeClone);
  };

  const handleWeekInputChange = (week, value) => {
    const weeksDataClone = JSON.parse(JSON.stringify(weeksData));
    weeksDataClone[week] = Number.parseInt(value) / 100;
    setWeeksData(weeksDataClone);
  };

  const handleLocationInputChange = (location, value) => {
    let locationsNew = JSON.parse(JSON.stringify(locations));
    locationsNew = locationsNew.map((x) => {
      if (x.value === location.value) {
        x.probability = Number.parseInt(value) / 100;
      }
      return x;
    });
    setLocations(locationsNew);
  };

  const handleProductRequirementsChange = (name, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(productRequirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].name = name)
      : (requirementsClone.requirements[index] = { name });
    setProductRequirements(requirementsClone);
  };

  const handleIPAIChange = (range, probability) => {
    const IPAIClone = JSON.parse(JSON.stringify(IPAI));
    IPAIClone[range] = Number.parseFloat(probability);
    setIPAI(IPAIClone);
  };

  const handlesiteRequirementTargetChange = (range, probability) => {
    const siteRequirementTargetClone = JSON.parse(
      JSON.stringify(siteRequirementTarget)
    );
    siteRequirementTargetClone[range] = Number.parseInt(probability);
    setsiteRequirementTarget(siteRequirementTargetClone);
  };

  const handlesiteLevelValueChange = (range, probability) => {
    const siteLevelValueClone = JSON.parse(JSON.stringify(siteLevelValue));
    siteLevelValueClone[range] = Number.parseInt(probability);
    setsiteLevelValue(siteLevelValueClone);
  };

  const handleProductRequirementTargetChange = (range, probability) => {
    const productRequirementTargetClone = JSON.parse(
      JSON.stringify(productRequirementTarget)
    );
    productRequirementTargetClone[range] = Number.parseInt(probability);
    setProductRequirementTarget(productRequirementTargetClone);
  };
  const handleProductRequirementActualChange = (range, probability) => {
    const productRequirementActualClone = JSON.parse(
      JSON.stringify(productRequirementActual)
    );
    productRequirementActualClone[range] = Number.parseInt(probability);
    setProductRequirementActual(productRequirementActualClone);
  };
  const handleProductLevelValueChange = (range, probability) => {
    const productLevelValueClone = JSON.parse(
      JSON.stringify(productLevelValue)
    );
    productLevelValueClone[range] = Number.parseInt(probability);
    setProductLevelValue(productLevelValueClone);
  };

  const handleRequirementsChange = (name, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(requirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].name = name)
      : (requirementsClone.requirements[index] = { name });
    setRequirements(requirementsClone);
  };

  const handleRequirementsPinChange = (pin, index) => {
    const requirementsClone = JSON.parse(JSON.stringify(requirements));
    requirementsClone.requirements[index]
      ? (requirementsClone.requirements[index].pin = pin)
      : (requirementsClone.requirements[index] = { pin });
    setRequirements(requirementsClone);
  };

  return (
    <React.Fragment>
      <Container>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Meta</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Brand Name"
              onChange={(event) => {
                setBrand(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Supplier Name"
              onChange={(event) => {
                setSupplier(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Product Name"
              onChange={(event) => {
                setProduct(event.target.value);
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Total Orders"
              onChange={(event) => {
                setTotalOrders(Number.parseInt(event.target.value));
              }}
            />
          </Grid>
          {/* <Grid item md={2}>
            <TextField
              label="Total spent"
              onChange={(event) => {
                setTotalSpent(Number.parseInt(event.target.value));
              }}
            />
          </Grid> */}
        </Grid>
        <br />
        <hr />
        <br />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Orders</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Confirmed/Lag %"
              onChange={(event) =>
                handleOrderPercentageChange("confirmedLag", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Unconfirmed/Lag %"
              onChange={(event) =>
                handleOrderPercentageChange(
                  "unconfirmedLag",
                  event.target.value
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Confirmed/no-Lag %"
              onChange={(event) =>
                handleOrderPercentageChange("confirmed", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Unconfirmed/no-Lag %"
              onChange={(event) =>
                handleOrderPercentageChange("unconfirmed", event.target.value)
              }
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">spent</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Submitted %"
              onChange={(event) =>
                handleSpentChange("submitted", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Confirmed %"
              onChange={(event) =>
                handleSpentChange("confirmed", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Unconfirmed %"
              onChange={(event) =>
                handleSpentChange("unconfirmed", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Lag warning %"
              onChange={(event) =>
                handleSpentChange("warning", event.target.value)
              }
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">SO Confirmed Date Range</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min (days)"
              onChange={(event) =>
                handlesoConfirmedDateRangeChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max (days)"
              onChange={(event) =>
                handlesoConfirmedDateRangeChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">PO Requested Date Range</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min (days)"
              onChange={(event) =>
                handlePoConfirmedDateRangeChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max (days)"
              onChange={(event) =>
                handlePoConfirmedDateRangeChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">SO Promise Date Range</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Changed dates %"
              onChange={(event) =>
                handlePoPromiseDateRangeChange(
                  "probability",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min (days)"
              onChange={(event) =>
                handlePoPromiseDateRangeChange(
                  "min",
                  Number.parseInt(event.target.value)
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max (days)"
              onChange={(event) =>
                handlePoPromiseDateRangeChange(
                  "max",
                  Number.parseInt(event.target.value)
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">
              SO Delay Period (Number of days after which order is marked with
              warning)
            </Typography>
          </Grid>
          <Grid item md={6}>
            <TextField
              label="Days"
              onChange={(event) =>
                setSoDelayPeriod(Number.parseInt(event.target.value))
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Units</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Units"
              onChange={(event) => {
                setUnits(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Filled</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min%"
              onChange={(event) => {
                handleFilledRangeChange(
                  "min",
                  Number.parseInt(event.target.value)
                );
              }}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max%"
              onChange={(event) => {
                handleFilledRangeChange(
                  "max",
                  Number.parseInt(event.target.value)
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Unit Price</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Unit Price"
              onChange={(event) => {
                setUnitPrice(Number.parseFloat(event.target.value));
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">PO Qty Range</Typography>
          </Grid>

          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handlePoQtyRangeChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handlePoQtyRangeChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">SO Qty Change</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Changed orders %"
              onChange={(event) =>
                handleSoQtyChange("changed", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min Change"
              onChange={(event) => handleSoQtyChange("min", event.target.value)}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max Change"
              onChange={(event) => handleSoQtyChange("max", event.target.value)}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Locations</Typography>
          </Grid>
          <Grid item md={10}>
            <Select
              className="mt-20"
              options={options}
              isMulti
              onChange={setLocations}
            />
          </Grid>
        </Grid> */}
        <br />
        {/* <Grid container spacing={1}>
          {locations &&
            locations.map((location) => {
              return (
                <Grid item md={2}>
                  <TextField
                    label={`${location.value}%`}
                    onChange={(event) =>
                      handleLocationInputChange(location, event.target.value)
                    }
                  />
                </Grid>
              );
            })}
        </Grid> */}
        <hr />
        <Typography className="">Provonomics</Typography>
        <hr />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Requirement</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Requirements"
              onChange={(event) => {
                setRequirements((prevState) => ({
                  ...prevState,
                  requirements: {},
                  total: event.target ? Number.parseInt(event.target.value) : 0,
                }));
              }}
            />
          </Grid>
        </Grid>
        {requirements.total > 0 &&
          [...Array(requirements.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Name`}
                      onChange={(event) =>
                        handleRequirementsChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Pin`}
                      onChange={(event) =>
                        handleRequirementsPinChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Requirement</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Requirements"
              onChange={(event) => {
                setProductRequirements((prevState) => ({
                  ...prevState,
                  requirements: {},
                  total: event.target ? Number.parseInt(event.target.value) : 0,
                }));
              }}
            />
          </Grid>
        </Grid>
        {productRequirements.total > 0 &&
          [...Array(productRequirements.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Name`}
                      onChange={(event) =>
                        handleProductRequirementsChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                  {/* <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Probability`}
                      // onChange={(event) => {
                      //   setProductRequirements(
                      //     Number.parseInt(event.target.value)
                      //   );
                      // }}
                    />
                  </Grid> */}
                </Grid>
              </>
            );
          })}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">IP Alignment Index</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) => handleIPAIChange("min", event.target.value)}
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) => handleIPAIChange("max", event.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Incentive Pool Status</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Accepted/Active %"
              onChange={(event) =>
                handleIPStatusChange(
                  "Accepted",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Rejected %"
              onChange={(event) =>
                handleIPStatusChange(
                  "Rejected",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Events</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="No of Events"
              onChange={(event) => {
                setProductEvents((prevState) => ({
                  ...prevState,
                  total: Number.parseInt(event.target.value),
                }));
              }}
            />
          </Grid>
        </Grid>
        {productEvents.total > 0 &&
          [...Array(productEvents.total).keys()].map((attribute) => {
            return (
              <>
                <Grid container spacing={1}>
                  <Grid item md={2}></Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Name`}
                      onChange={(event) =>
                        handleProductEventsChange(event.target.value, attribute)
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Event${attribute + 1} Type`}
                      onChange={(event) =>
                        handleProductEventsTypeChange(
                          event.target.value,
                          attribute
                        )
                      }
                    />
                  </Grid>
                  <Grid item md={2}>
                    <TextField
                      label={`Req${attribute + 1} Probability`}
                      onChange={(event) =>
                        handleProductEventsProbabilityChange(
                          Number.parseInt(event.target.value) / 100,
                          attribute
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </>
            );
          })}
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Test Status</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Pass %"
              onChange={(event) =>
                handleProductStatusChange(
                  "Pass",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Failed %"
              onChange={(event) =>
                handleProductStatusChange(
                  "Fail",
                  Number.parseInt(event.target.value) / 100
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Requirement Target</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handlesiteRequirementTargetChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handlesiteRequirementTargetChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Site Level Value</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min%"
              onChange={(event) =>
                handlesiteLevelValueChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max%"
              onChange={(event) =>
                handlesiteLevelValueChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">
              Product Requirement Target
            </Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductRequirementTargetChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductRequirementTargetChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">
              Product Requirement Actual
            </Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min"
              onChange={(event) =>
                handleProductRequirementActualChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max"
              onChange={(event) =>
                handleProductRequirementActualChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Product Level Value</Typography>
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Min%"
              onChange={(event) =>
                handleProductLevelValueChange("min", event.target.value)
              }
            />
          </Grid>
          <Grid item md={2}>
            <TextField
              label="Max%"
              onChange={(event) =>
                handleProductLevelValueChange("max", event.target.value)
              }
            />
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <Typography className="mt-20">Order Time</Typography>
          </Grid>
          <Grid item md={2}></Grid>
          <Grid item md={10}>
            <TextField
              label="Number of Weeks"
              onChange={(event) =>
                setWeeks(Number.parseInt(event.target.value))
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          {Number.isInteger(weeks) &&
            weeks !== 0 &&
            [...Array(weeks).keys()].map((week) => {
              return (
                <Grid item md={2}>
                  <TextField
                    label={`Week${week}%`}
                    onChange={(event) =>
                      handleWeekInputChange(week, event.target.value)
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
        <br />
        Upload Site Inspection data
        <input type="file" name="siteInspection" onChange={uploadFile} />
        <hr />
        <br />
        <Grid container spacing={1}>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                GenerateData({
                  brand,
                  product,
                  supplier,
                  totalOrders,
                  totalSpent,
                  locations,
                  weeksData,
                  orderPercentage,
                  spent,
                  soConfirmedDateRange,
                  poRequestedDateRange,
                  soPromiseDateRange,
                  soDelayPeriod,
                  units,
                  unitPrice,
                  poQtyRange,
                  soQtyRange,
                  filledProbability,
                  siteLevelValue,
                  productRequirementTarget,
                  productRequirementActual,
                  productLevelValue,
                  productEvents,
                  productRequirements,
                  productStatus,
                  siteRequirementTarget,
                  IPAI,
                  IPStatus,
                  requirements,
                })
              }
            >
              Generate
            </Button>
          </Grid>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              onClick={downloadJsonFile}
              color="primary"
            >
              Export Data
            </Button>
          </Grid>
          <Grid item md={2}>
            <br />
            <Button
              variant="contained"
              onClick={() => {
                var msg = window.confirm(
                  "This will clear all data from your system. Do you want to proceed"
                );
                if (msg) {
                  localStorage.setItem("ordersData", JSON.stringify([]));
                }
              }}
              color="primary"
            >
              Clear Data
            </Button>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default DataGenerator;
