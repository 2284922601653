import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsMap from "highcharts/modules/map";
import { Box, makeStyles, Typography, Link, Grid } from "@material-ui/core";
import proj4 from "proj4";
import mapData from "@highcharts/map-collection/countries/us/custom/us-all-mainland.geo.json";
import { NonceProvider } from "react-select";
highchartsMap(Highcharts);
if (typeof window !== "undefined") {
  window.proj4 = window.proj4 || proj4;
}

const useStyles = makeStyles((theme) => ({
  alertIcon: {
    color: theme.palette.primary.yellow,
    background: "#000",
    borderRadius: "50%",
    fontSize: "1.2rem",
    opacity: "0.9",
    position: "absolute",
    top: "0.3rem",
    right: "0.3rem",
  },
}));

const getOptions = (config, data) => {
  return {
    chart: {
      backgroundColor: "transparent",
      map: "custom/world",
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1000,
          },
          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
              layout: "horizontal",
            },
            yAxis: {
              labels: {
                align: "left",
                x: 0,
                y: -5,
              },
              title: {
                text: null,
              },
            },
            subtitle: {
              text: null,
            },
            credits: {
              enabled: false,
            },
          },
        },
      ],
    },
    plotOptions: {
      mapbubble: {
        minSize: 40,
        maxSize: 40,
        marker: {
          symbol:
            "url(https://pcndev.blob.core.windows.net/public/greenpin.png)",
        },
      },
      series: {
        dataLabels: {
          enabled: config.showWarning,
          format: `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
          width="15" height="15"
          viewBox="0 0 172 172"
          style=" fill:#000000;"><g transform=""><g fill="none" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><path d="M0,172v-172h172v172z" fill="none"></path><path d="M86,150.5c-35.62237,0 -64.5,-28.87763 -64.5,-64.5v0c0,-35.62237 28.87763,-64.5 64.5,-64.5h0c35.62237,0 64.5,28.87763 64.5,64.5v0c0,35.62237 -28.87763,64.5 -64.5,64.5z" fill="#ffca00"></path><path d="" fill="none"></path><path d="" fill="none"></path><path d="" fill="none"></path><g fill="#000000"><path d="M86,6.45c-43.84003,0 -79.55,35.70997 -79.55,79.55c0,43.84003 35.70997,79.55 79.55,79.55c43.84003,0 79.55,-35.70997 79.55,-79.55c0,-43.84003 -35.70997,-79.55 -79.55,-79.55zM86,22.36c35.24163,0 63.64,28.39837 63.64,63.64c0,35.24163 -28.39837,63.64 -63.64,63.64c-35.24163,0 -63.64,-28.39837 -63.64,-63.64c0,-35.24163 28.39837,-63.64 63.64,-63.64zM78.045,46.225v15.91h15.91v-15.91zM78.045,78.045v47.73h15.91v-47.73z"></path></g><path d="" fill="none"></path></g></g></svg>`,
          useHTML: true,
          align: "left",
        },
      },
    },

    title: {
      text: config.title || "",
    },
    credits: {
      enabled: false,
    },
    legends: {
      enabled: false,
    },
    mapNavigation: {
      enabled: config.allowZoom || true,
    },
    tooltip: {
      shared: false,
      backgroundColor: null,
      borderWidth: 0,
      style: {
        padding: 0,
      },
      useHTML: true,
      headerFormat: "",
      pointFormat: config.showSupplier
        ? `<div>
        <div style='font-size: 14px; padding-bottom: 10px;'>
        <b>{point.location}</b>
        </div>
         <div style='padding-bottom: 6px;'>{series.name}</div>
        <div style='font-size: 16px'>
        <b>{point.prefix}{point.z} {point.suffix}</b>
        </div>
        <br/>
         <table border="1" cellspacing="0">
        <tr><th style="padding:5px">Supplier</th><th style="padding:5px">Alignment Index</th><th style="padding:5px">Pools</th><th style="padding:5px">Status</th></tr>
        <tr><td style="padding:5px">{point.supplier}</td><td style="padding:5px">{point.ai}</td><td style="padding:5px">{point.z}</td><td style="padding:5px">{point.status}</td></tr>
        </table>
        </div>`
        : "<div><div style='font-size: 14px; padding-bottom: 10px;'><b>{point.location}</b></div><div style='padding-bottom: 6px;'>{series.name}</div> <div style='font-size: 16px'> <b>{point.prefix}{point.z} {point.suffix}</b> </div></div>",
    },
    series: [
      {
        name: "Basemap",
        dataLabels: {
          enabled: false,
        },
        mapData: mapData,
        borderColor: "#000000",
        nullColor: "#ffffff",
        showInLegend: false,
      },
      ...(data || []),
    ],
  };
};
const BubbleMap = (props) => {
  const { config, data } = props;
  const options = getOptions(config, data);
  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={"mapChart"}
      options={options}
    />
  );
};
export default BubbleMap;
